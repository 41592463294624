export const commonauthpoints = {
  CHECK_USERLOGIN: "/commonauth/checkuserlogin",
  LOGOUT: "/commonauth/logout",
};

export const adminpoints = {
  ACTIVE_VENDOR: "/vendor/toggleActiveStatus/",
  CREATE_VENDOR_API: "/vendor/createVendor",
  VENDOR_IMAGE: "/vendor/handleVendorImage",
  NAVBAR_IMAGE_VENDOR: "/vendor/getVendorImage/",
  EDIT_VENDOR_API: "/vendor/editVendor",
  GET_ALLVENDORS_API: "/vendor/getAllVendors",
  GET_ALLINSTITUTIONS_API: "/institution/getAllInstitutions",
  GET_ALLADMININSTITUTIONS_API: "/institution/getAllAdminInstitutions",
  GET_ALLVENDORSINSTITUTIONS_API: "/institution/getAllVendorInstitutions",
};
export const adminvendortypeendpoints = {
  ACTIVE_INSTITUTION: "/institution/toggleInstitutionActiveStatus/",
  CREATE_INSTITUTION_API: "/institution/createInstitution",
  EDIT_INSTITUTION_API: "/institution/editInstitution",
  CREATE_BATCHYEAR_API: "/batchyear/createBatchYear",
  EDIT_BATCHYEAR_API: "/batchyear/editBatchYear",
};
export const adminvendorendpoints = {
  CREATE_BATCH_API: "/batch/createBatch",
  EDIT_BATCH_API: "/batch/editBatch",
  CREATE_STUDENT_API: "/student/createStudent",
  CREATE_MULTIPLESTUDENTS_API: "/student/createStudentsFromCSV",
  EDIT_STUDENT_API: "/student/editStudent",
  EXTEND_STUDENTS_ACCESS: "/student/extendStudentsAccess",
  REMOVE_STUDENT_ACCESS: "/student/removeStudentAccess",
  GET_STUDENTS_ACCESS: "/student/getStudentsWithAccess",
};
export const vendortypeendpoints = {
  GET_VENDORINSTITUTIONS_API: "/institution/getVendorsInstitutions",
};
export const adminvendorinstitutionendpoints = {
  GET_BATCHYEARS_API: "/batchyear/getBatchYears",
  GET_BATCHES_API: "/batch/getBatches",
  GET_STUDENTS_API: "/student/getStudents",
};
export const subjectendpoints = {
  GET_ALL_SUBJECTS: "/qb/subject/getAllSubjects",
  GET_SUBJECTS_BY_ID: "/qb/subject/getSubjectsByCategoryId/",
  CREATE_SUBJECT: "/qb/subject/createSubject",
  EDIT_SUBJECT: "/qb/subject/editSubject",
};

export const categoriesendpoints = {
  GET_ALLCATEGORIES: "/qb/category/getAllCategories",
  CREATE_CATEGORY: "/qb/category/createCategory",
  EDIT_CATEGORY: "/qb/category/editCategory",
  DELETE_CATEGORY: `/qb/category/deleteCategory`,
};
export const studentendpoints = {
  EDIT_STUDENTBYHIMSELFT_API: "/student/editStudentByHimself",
};
export const subcategoriesendpoints = {
  CREATE_SUBCATEGORY: "/qb/subcategory/createSubCategory",
  EDIT_SUBCATEGORY: "/qb/subcategory/editSubCategory",
  GET_ALL_SUBCATEGORIES: "/qb/subcategory/getAllSubcategories",
  GET_SUBCATEGORY_BYID: `/qb/subcategory/getSubcategoriesByCategory`,
};

export const chaptersendpoints = {
  CREATE_SUBJECT: "/qb/chapter/createChapter",
  EDIT_SUBJECT: "/qb/chapter/editChapter",
  GET_CHAPTERSBY_SUBJECT: "/qb/chapter/getChaptersBySubjectId",
};

export const QBAccessendpoints = {
  GIVE_TOATAL_INSTITUTION: "/qb/subject/giveAccessInstitution",
  GIVE_BATCH_YEAR: "/qb/subject/giveAccessBatchYear",
  GIVE_BATCH: "/qb/subject/giveAccessBatch",
  REMOVE_INSTITUTION: "/qb/subject/removeAccessInstitution",
  REMOVE_BATCH_YEAR: "/qb/subject/removeAccessBatchYear",
  REMOVE_BATCH: "/qb/subject/removeAccessBatch",
};
export const assessmentcategoryendpoints = {
  CREATE_ASSESSMENTCATEGORY: "/assessmentcategory/createAssessmentCategory",
  EDIT_ASSESSMENTCATEGORY: "/assessmentcategory/editAssessmentCategory",
  DELETE_ASSESSMENTCATEGORY: "/assessmentcategory/deleteAssessmentCategory",
  GET_ASSESSMENTCATEGORIES: "/assessmentcategory/getAssessmentCategories",
  GET_ASSESSMENTACCESSCATEGORIES:
    "/assessmentcategory/getAssessmentAccessCategories",

  GET_ALLASSESSMENTCATEGORIES: "/assessmentcategory/getAllAssessmentCategories",
};
export const assignmentcategoryendpoints = {
  CREATE_ASSIGNMENTCATEGORY: "/assignmentcategory/createAssignmentCategory",
  EDIT_ASSIGNMENTCATEGORY: "/assignmentcategory/editAssignmentCategory",
  DELETE_ASSIGNMENTCATEGORY: "/assignmentcategory/deleteAssignmentCategory",
  GET_ASSIGNMENTCATEGORIES: "/assignmentcategory/getAssignmentCategories",
  GET_ALLASSIGNMENTCATEGORIES: "/assignmentcategory/getAllAssignmentCategories",
  GET_ASSIGNMENTACCESSCATEGORIES:
    "/assignmentcategory/getAssignmentAccessCategories",
  CREATE_ASSIGNMENTSUBCATEGORY:
    "/assignmentcategory/createAssignmentSubCategory",
  EDIT_ASSIGNMENTSUBCATEGORY: "/assignmentcategory/editAssignmentSubCategory",
  GET_ASSIGNMENTSUBCATEGORIES: "/assignmentcategory/getAssignmentSubCategories",
  GIVE_ASSIGNMENTCATEGORYACCESSVENDOR:
    "/assignmentcategory/giveAssignmentCategoryAccess",
  REMOVE_ASSIGNMENTCATEGORYACCESSVENDOR:
    "/assignmentcategory/removeAssignmentCategoryAccess",
  GET_ASSIGNMENTCATEGORIESACCESSVENDOR:
    "/assignmentcategory/getAssignmentCategoryAccessDetails",
  GET_STUDENTASSIGNMENTCATEGORIES:
    "/assignmentcategory/getStudentAssigmentCategories",
  GET_STUDENTASSIGNMENTSUBCATEGORIES:
    "/assignmentcategory/getStudentAssignmentSubcategories",
};
export const assignmentendpoints = {
  CREATE_ASSIGNMENT: "/assignment/createAssignment",
  GET_ASSIGNMENTS: "/assignment/getAssignments",
  EDIT_ASSIGNMENT: "/assignment/editAssignment",
  GIVE_ASSIGNMENTACCESS: "/assignment/giveAssignmentAccess",
  GET_ASSIGNMENTS_ACCESSDETAILS: "/assignment/getAssigmmentAccessDetails",
  EDIT_ASSIGNMENT_ACCESS: "/assignment/editAssignmentAccess",
  DELETE_ASSIGNMENT_ACCESS: "/assignment/deleteAssignmentAccess",
  GET_ASSIGNMENT_CATEGORIES: "/assignmentcategory/getAssignmentCategories",
  GET_ASSIGNMENT_REPORT: "/assignment/reports/getAssignmentReport",
  GET_ASSIGNMENT_REPORTS: "/assignment/reports/getAssignmentReports",
  GET_CATEOGORIESASSIGNMENTS: "/assignment/reports/getCategoriesAndAssignments",
  GET_STUDENTSASSEIGNMENT_REPORTS:
    "/assignment/reports/getStudentAssignmentsReports",
};
export const assessmentendpoints = {
  CREATE_ASSESSMENT: "/assessment/createAssessment",
  CHECK_ASSESSMENTPASS: "/assessment/checkAssessmentPassword",
  GET_ASSESSMENTS: "/assessment/getAssessments",
  EDIT_ASSESSMENT: "/assessment/editAssessment",
  GIVE_ASSESSMENTACCESS: "/assessment/giveAssessmentAccess",
  GET_ASSESSMENTS_ACCESSDETAILS: "/assessment/getAssessmentAccessDetails",
  EDIT_ASSESSMENT_ACCESS: "/assessment/editAssessmentAccess",
  EDIT_ASSESSMENTEXAMSCHEDULE_ACCESS: "/assessment/editAssessmentExamSchedule",
  DELETE_ASSESSMENT_ACCESS: "/assessment/deleteAssessmentAccess",
  GET_ASSESSMENT_INSTITUTIONS: "/assessment/getAssessmentInstitutions",
};

export const compilerendpoints = {
  RUN_CODE: "http://localhost:7777/run-code",
  EXECUTE_CODE: "/compiler/checkCodingAnswer",
  SUBMIT_CODE: "http://localhost:7777/submit-code",
  SUBMITASSESSMENT_CODE: "/compiler/submit-code",
  LANGUAGES_API: "/global/dsa/languages",
  SUBMIT_API: "/global/dsa/SubmitCode",
  RUN_API: "/global/dsa/RunCode",
  GENERATE_TESTCASES: "/compiler/generatetestcases",
};

export const studentassessmentendpoints = {
  GET_STUDENTASSESSMENTS: "/assessment/getStudentAssessments",
  GET_ASSESSMENTDETAILS: "/assessment/getAssessmentDetails",
  CREATE_ASSESSMENT_ATTEMPT: "/assessment/createAssessmentAttempt",
  UPDATE_ASSESSMENT_ANSWERS: "/assessment/updateAssessmentAttempt",
  UPDATE_ASSESSMENT_STATUS:
    "/assessment/updateAssessmentAttemptStatusCompleted",
  GET_ASSESSMENT_ANSWERS: "/assessment/getAssessmentAttemptAnswers",
  GET_ASSESSMENTATTEMPT_SOLUTIONS: "/assessment/getAttemptAnswersSolutions",
};
export const studentassignmentendpoints = {
  GET_STUDENTASSIGNMENTS: "/assignment/getStudentAssigments",
  GET_ASSIGNMENTDETAILS: "/assignment/getAssignmentDetails",
  CREATE_ASSIGNMENT_ATTEMPT: "/assignment/createAssignmentAttempt",
  UPDATE_ASSIGNMENT_ANSWERS: "/assignment/updateAssignmentAttempt",
  UPDATE_ASSIGNMENT_STATUS:
    "/assignment/updateAssignmentAttemptStatusCompleted",
  GET_ASSIGNMENT_ANSWERS: "/assignment/getAssignmentAttemptAnswers",
};
export const globalDSAendpoints = {
  createTag: "/global/dsa/createTag",
  getDSACounts: "/global/dsa/getDSACounts",

  getAllTags: "/global/dsa/getAllTags",
  getAllCompanies: "/global/dsa/getAllCompanies",
  CREATE_DSA_GLOBAL: "/global/dsa/create",
  GET_ALL_DSA: "/global/dsa/all",
  GET_ALL_DSA_BY_STUDENT: "/global/dsa/allbystudent",
  HANDLE_IMAGE: "/qb/question/handleimages",
  SUBMIT_CODE_DSA: "/global/dsa/submit",
  ONE_DSA: "/global/dsa/oneDSA/",
  GET_REPORTS: "/global/dsa/reports",
  SINGLE_REPORT: "/global/dsa/reports/single",
};
export const questionsendpoints = {
  CREATE_MULTIPLE: "/qb/question/createQuestions",
  GET_MCQS: "/qb/question/getMCQs",
  GET_FILLINTHEBLANKS: "/qb/question/getFillintheblanks",
  GET_ALLQUESTIONS: "/qb/question/getAllQuestions",
  GET_CODING: "/qb/question/getCodingQuestions",
  GET_MCQ: "/qb/question/getMCQ",
  GET_FILLINTHEBLANK: "/qb/question/getFillInTheBlank",
  GET_CODINGQ: "/qb/question/getCoding",

  GIVE_VISIBLE: "/qb/question/setVisible",
  REMOVE_VISIBLE: "/qb/question/removeVisible",
};

export const learningendpoints = {
  GET_ALL: "/learning/all",
};

export const assessmentreportendpoints = {
  GET_ASSESSMENT_REPORT: "/assessment/getAssessmentReport",
  GET_ALLASSESSMENT_REPORTS: "/assessment/getAllAssessmentReports",
  GET_FILTERASSESSMENT_REPORTS: "/assessment/getAssessmentReportsFilter",
  GET_SUMMARYASSESSMENT_REPORT: "/assessment/getSummaryAssessmentReport",
  GET_CATEOGORIESASSESSMENTS: "/assessment/getCategoriesAndAssessments",
  GET_STUDENTSASSESSMENT_REPORTS: "/assessment/getStudentAssessmentsReports",
};

export const billingendpoints = {
  NO_OF_ACTIVE: "/pd/activeno",
  GET_BILLING: "/pd/billing",
  GRANT_VENDOR: "/pd/vendor/grant",
  REMOVE_VENDOR: "/pd/vendor/remove",
  GRANT_INSTITUTION: "/pd/institution/grant",
  REMOVE_INSTITUTION: "/pd/institution/remove",
  VERIFY_PAY: "/pd/verify",
  VERIFY_PAY_POST: "/pd/verify/post",
};

export const dashboardEndpoints = {
  getDashboardStats: "/dash/getDashboardStats",
  NO_OF_VENDOR: "/dash/vendorsno",
  NO_OF_INST: "/dash/institutionsno",
  NO_OF_ASSIGNMENT: "/dash/noofassignments",
  NO_OF_ASSESSMENT: "/dash/noofassessments",
  NO_OF_GLOBAL: "/dash/noofglobalcodings",
  NO_OF_MCQ: "/dash/noofmcqs",
  NO_OF_FILL_IN_BLANK: "/dash/nooffillintheblanks",
  NO_OF_CODING: "/dash/noofcodings",
  TOTAL: "/dash/noOfStudents",
  NO_BATCH_YEAR: "/dash/noOfBatchYears",
  NO_BATCHES: "/dash/noOfBatches",
  MY_DETAILS: "/dash/fetchMyDetails",
};

export const contestEndpoints = {
  GET_ALLGLOBALDSA_CONTEST: "/contest/getAllDsa",
  CREATE_CONTEST: "/contest/createContest",
  GET_STUDENTALL_CONTESTS: "/contest/getStudentAllContests",
  GET_STUDENTMY_CONTESTS: "/contest/getStudentMyContests",
  GET_CONTEST_PROBLEMS: "/contest/getContestProblems",
  REGISTER_CONTEST: "/contest/registerContest",
  GET_CONTEST_LEADERBOARD: "/contest/contestLeaderboard",
  GET_LEADERBOARD_DETAILS: "/contest/getLeaderboardDetails",
  GET_CONTESTS: "/contest/getContests",
  EDIT_CONTEST: "/contest/editContest",
  DOWNLOAD_CONTEST_REPORTS: "/contest/downloadContestReports",
};
