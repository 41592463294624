import axios from "axios";

import { SERVER_URL } from "../api/env";

const api = axios.create({
  baseURL: SERVER_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});
export const downloadExcelFile = (data) => {
  return axios({
    url: `${SERVER_URL}/assessment/downloadAssessmentReports`,
    method: "POST",
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    data, // Send the details (data) in the POST request body
    responseType: "blob", // Important for handling binary data like Excel files
    withCredentials: true,
  });
};

export default api;
