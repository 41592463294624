import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/Axios";
import Cookies from "js-cookie";
import { commonauthpoints } from "../../services/Apis";
const { CHECK_USERLOGIN, LOGOUT } = commonauthpoints;
export const userLogin = createAsyncThunk("user/login", async (credentials) => {
  const response = await api.post(CHECK_USERLOGIN, credentials);
  console.log(response);
  return response.data;
});

export const userLogout = createAsyncThunk("userlogout", async () => {
  const response = await api.get(LOGOUT);
  return response.data;
});

const UserSlice = createSlice({
  name: "user",
  initialState: {
    vendortype: null,
    role: "",
    token: "",
    isPinned: false,
    expanded: true,
    isAuthenticated: false,
    loading: true,
    error: null,
  },
  reducers: {
    setRole(state, action) {
      state.role = action.payload;
    },
    setAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },
    setVendortype(state, action) {
      state.vendortype = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setExpanded: (state, action) => {
      state.expanded = action.payload;
    },
    setIsPinned: (state, action) => {
      state.isPinned = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        const { isAuthenticated, role, userData, vendortype, token } =
          action.payload;
        state.vendortype = vendortype === undefined ? null : vendortype;
        state.role = role;
        state.isAuthenticated = isAuthenticated;
        state.loading = false;
        state.token = token;
        state.userData = userData;
        Cookies.set("token", token, { expires: 1 });
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.role = "";
        state.error = action.error.message;
      })
      .addCase(userLogout.pending, (state) => {})
      .addCase(userLogout.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.role = "";
        state.userData = null;
      });
  },
});
export const {
  setRole,
  setToken,
  setAuthentication,
  setVendortype,
  setLoading,
  setIsPinned,
  setExpanded,
} = UserSlice.actions;
export default UserSlice.reducer;
