import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { assignmentendpoints } from "../../../services/Apis";
import { SERVER_URL } from "../../../api/env";
import createGlobalApi from "../createGlobalApi";

const {
  CREATE_ASSIGNMENT,
  GET_ASSIGNMENTS,
  EDIT_ASSIGNMENT,
  GIVE_ASSIGNMENTACCESS,
  EDIT_ASSIGNMENT_ACCESS,
  DELETE_ASSIGNMENT_ACCESS,
  GET_ASSIGNMENTS_ACCESSDETAILS,
  GET_ASSIGNMENT_REPORTS,
  GET_ASSIGNMENT_REPORT,
  GET_CATEOGORIESASSIGNMENTS,
  GET_STUDENTSASSEIGNMENT_REPORTS,
} = assignmentendpoints;
export const assignmentApi = createGlobalApi({
  reducerPath: "assignmentApi",
  tagTypes: ["Assignments", "AssignmentAccess"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    createAssignment: builder.mutation({
      query: (formData) => ({
        url: CREATE_ASSIGNMENT,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Assignments"],
    }),
    fetchAssignments: builder.query({
      query: (credentials) => ({
        url: GET_ASSIGNMENTS,
        method: "POST",
        body: credentials,
      }),
      providesTags: ["Assignments"],
    }),
    editAssignment: builder.mutation({
      query: (formData) => ({
        url: EDIT_ASSIGNMENT,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Assignments"],
    }),
    addAssignmentAccess: builder.mutation({
      query: (formData) => ({
        url: GIVE_ASSIGNMENTACCESS,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["AssignmentAccess"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(assignmentApi.util.invalidateTags(["AssignmentAccess"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    editAssignmentAccess: builder.mutation({
      query: (formData) => ({
        url: EDIT_ASSIGNMENT_ACCESS,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["AssignmentAccess"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(assignmentApi.util.invalidateTags(["AssignmentAccess"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getAssignmentAccessDetails: builder.query({
      query: (formData) => ({
        url: GET_ASSIGNMENTS_ACCESSDETAILS,
        method: "POST",
        body: formData,
      }),
      providesTags: ["AssignmentAccess"],
    }),
    deleteAssignmentAccess: builder.mutation({
      query: (formData) => ({
        url: DELETE_ASSIGNMENT_ACCESS,
        method: "DELETE",
        body: formData,
      }),
      invalidatesTags: ["AssignmentAccess"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(assignmentApi.util.invalidateTags(["AssignmentAccess"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    fetchAssignmentReport: builder.query({
      query: (credentials) => ({
        url: GET_ASSIGNMENT_REPORT,
        method: "POST",
        body: credentials,
      }),
    }),
    fetchAssignmentReports: builder.query({
      query: (credentials) => ({
        url: GET_ASSIGNMENT_REPORTS,
        method: "POST",
        body: credentials,
      }),
    }),
    fetchCategoriesAssignments: builder.query({
      query: () => GET_CATEOGORIESASSIGNMENTS,
    }),
    getStudentAssignmentsReport: builder.query({
      query: () => GET_STUDENTSASSEIGNMENT_REPORTS,
    }),
  }),
});

export const {
  useCreateAssignmentMutation,
  useFetchAssignmentsQuery,
  useEditAssignmentMutation,
  useAddAssignmentAccessMutation,
  useEditAssignmentAccessMutation,
  useDeleteAssignmentAccessMutation,
  useGetAssignmentAccessDetailsQuery,
  useFetchAssignmentReportQuery,
  useFetchAssignmentReportsQuery,
  useFetchCategoriesAssignmentsQuery,
  useGetStudentAssignmentsReportQuery,
} = assignmentApi;
