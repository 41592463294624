import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { compilerendpoints } from "../../../services/Apis";
import { SERVER_URL } from "../../../api/env";
import createGlobalApi from "../createGlobalApi";

const { LANGUAGES_API, GENERATE_TESTCASES } = compilerendpoints;

export const codingApi = createGlobalApi({
  reducerPath: "codingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    getLanguages: builder.query({
      query: () => ({
        url: LANGUAGES_API,
        method: "GET",
      }),
    }),
    generateTestCases: builder.mutation({
      query: (data) => ({
        url: GENERATE_TESTCASES,
        method: "POST",
        body: data, // Assuming credentials are query parameters
      }),
    }),
  }),
});

export const { useGetLanguagesQuery, useGenerateTestCasesMutation } = codingApi;
