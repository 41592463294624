import { useGetStudentAssignmentSubcategoriesQuery } from "../../../../redux/rtkquery/assignment/AssignmentCategory";
import { useLocation, useNavigate, useParams } from "react-router";
import { ArrowLeft } from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  FaLightbulb,
  FaRocket,
  FaTrophy,
  FaFire,
  FaThumbsUp,
  FaHandshake,
  FaSmile,
  FaBrain,
  FaMedal,
  FaCodeBranch,
  FaGraduationCap,
  FaUserGraduate,
} from "react-icons/fa";
import { BiCodeAlt } from "react-icons/bi";
import { TbBooks } from "react-icons/tb";

export default function AssignmentSubCategories() {
  const getRandomIcon = () => {
    const icons = [
      <FaLightbulb />,
      <FaRocket />,
      <FaTrophy />,
      <FaFire />,
      <FaThumbsUp />,
      <FaHandshake />,
      <FaSmile />,
      <FaBrain />,
      <FaMedal />,
      <FaCodeBranch />,
      <FaGraduationCap />,
      <FaUserGraduate />,
      <BiCodeAlt />,
      <TbBooks />,
    ];
    return icons[Math.floor(Math.random() * icons.length)];
  };
  const { id, name } = useParams();
  const { state } = useLocation();
  const { data, isLoading } = useGetStudentAssignmentSubcategoriesQuery({
    assignmentcategoryid: id,
    type: state,
  });
  const navigate = useNavigate();
  const handleSubmit = (id, name) => {
    navigate(`/assignments/${id}/${name}`);
  };
  return (
    <div className="container mx-auto px-6 py-12 sm:px-8 lg:px-12">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center p-2 text-sm font-medium text-gray-800 hover:text-purple-600 rounded-lg transition-transform transform hover:underline active:scale-95"
      >
        <ArrowLeft className="w-5 h-5 mr-2" />
        Back
      </button>

      <div className="bg-gradient-to-r from-gray-600 to-gray-400 p-6 rounded-t-xl">
        <h1 className="text-4xl font-bold text-center text-white mb-4">
          {" "}
          {name}
        </h1>
      </div>
      {!isLoading ? (
        <div className="grid  grid-cols-1 md:grid-cols-2 mt-7 lg:grid-cols-3 gap-8">
          {data?.subcategories?.map((subcategory) => (
            <div
              key={subcategory.id}
              className="relative rounded-xl py-3 shadow-lg overflow-hidden bg-white"
            >
              <div className="absolute top-0 right-0 opacity-10 text-gray-500 text-[8rem] pointer-events-none">
                {getRandomIcon()}
              </div>
              <div className="relative z-10 p-6 flex flex-col h-full">
                <h2 className="text-xl font-semibold text-gray-800">
                  {subcategory.name}
                </h2>
                <p className="text-gray-600 font-semibold text-sm mt-2">
                  Assignments: {subcategory.numberOfAssignments}
                </p>
                <button
                  onClick={() => handleSubmit(subcategory.id, subcategory.name)}
                  className="w-full bg-gradient-to-r from-purple-600 to-violet-600 text-white py-2 px-2 rounded-lg font-semibold shadow-md transition duration-200 hover:from-purple-700 hover:to-violet-700 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 mt-4"
                  aria-label={`Open ${subcategory.name}`}
                >
                  Open
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid py-1 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {Array(6)
            .fill("")
            .map((_, index) => (
              <div
                key={index}
                className="rounded-xl shadow-lg overflow-hidden transform transition bg-gray-200"
              >
                <Skeleton height={100} />
                <div className="p-4">
                  <Skeleton height={20} width="80%" />
                  <Skeleton height={20} width="60%" className="mt-2" />
                  <Skeleton
                    height={40}
                    width="100%"
                    className="mt-4 rounded-lg"
                  />
                </div>
              </div>
            ))}
        </div>
      )}
      {/* <div className="grid  grid-cols-1 md:grid-cols-2 mt-7 lg:grid-cols-3 gap-8">
        {data?.subcategories?.map((subcategory) => (
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm relative">
            <div className="absolute top-4 right-4 text-gray-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="currentColor"
                stroke="none"
              >
                <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
              </svg>
            </div>

            <h2 className="text-4xl font-medium text-gray-700 mb-6">
              {" "}
              {subcategory.name}
            </h2>

            <ul className="space-y-2 mb-6">
              <li className="text-gray-500">
                {subcategory.numberOfAssignments} Assessment
              </li>
            </ul>

            <button
              onClick={() => handleSubmit(subcategory.id, subcategory.name)}
              className="border-2 border-gray-900 text-gray-900 px-6 py-2 rounded-md hover:bg-gray-100 transition-colors"
            >
              Start Learning
            </button>
          </div>
        ))}
      </div> */}
    </div>
  );
}
