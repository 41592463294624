// src/services/chaptersApi.js

import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../api/env";

import { learningendpoints } from "../../services/Apis";
import createGlobalApi from "./createGlobalApi";
const { GET_ALL } = learningendpoints;
export const learningApi = createGlobalApi({
  reducerPath: "learningApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    fetchAllLearning: builder.query({
      query: () => GET_ALL,
    }),
  }),
});

export const { useFetchAllLearningQuery } = learningApi;
