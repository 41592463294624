import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "Assignment 1",
  questions: [],
  currentQuestionIndex: 0,
  answers: {},
  submitted: false,
};

const assignmentSlice = createSlice({
  name: "assignmentexam",
  initialState,
  reducers: {
    setAssignmentQuestions: (state, action) => {
      console.log("Questions redux: ", action);
      state.questions = action.payload;
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    saveAnswer: (state, action) => {
      const { questionIndex, answer } = action.payload;
      state.answers[questionIndex] = answer;
    },
    setAnswers: (state, action) => {
      state.answers = action.payload;
    },
    submitExam: (state) => {
      state.submitted = true;
      console.log("submitted", state.answers, typeof state.answers);
    },
  },
});

export const {
  setAssignmentQuestions,
  setCurrentQuestion,
  saveAnswer,
  setAnswers,
  submitExam,
  setTitle,
} = assignmentSlice.actions;

export default assignmentSlice.reducer;
