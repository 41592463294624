import { useState } from "react";
import { useFetchAllVendorsQuery } from "../../redux/rtkquery/VendorsReducer";
import {
  useAddAssignmentCatgeoryAccessVendorMutation,
  useFetchIndividualAssignmentAccessCategoriesQuery,
  useRemoveAssignmentCatgeoryAccessVendorMutation,
} from "../../redux/rtkquery/assignment/AssignmentCategory";
import toast from "react-hot-toast";
import MyButton from "../../components/common/MyButton";

const AssignmentCategoryAccessForm = ({ onClose, type }) => {
  const [category, setCategory] = useState("");
  const [vendor, setVendor] = useState("");
  const { data: response = {}, isFetching: vendorFetching } =
    useFetchAllVendorsQuery(undefined, { suspense: true });
  const vendors = response.vendors || [];

  const { data: categories, isFetching: catFetching } =
    useFetchIndividualAssignmentAccessCategoriesQuery(
      {},
      {
        suspense: true,
      }
    );
  const [giveAccess, { isLoading: givingAccess }] =
    useAddAssignmentCatgeoryAccessVendorMutation();
  const [removeAccess, { isLoading: removingAccess }] =
    useRemoveAssignmentCatgeoryAccessVendorMutation();
  const handleGrantAccess = async () => {
    // Your grant access logic here
    try {
      const response = await giveAccess({
        assignmentCategoryId: category,
        vendorId: vendor,
      });
      if (response?.data?.success) {
        toast.success("Access granted successfully");
        onClose();
      } else if (response.error.data) {
        toast.error(response.error.data.message);
      }
    } catch (e) {}
  };
  const handleRemoveAccess = async () => {
    // Your grant access logic here
    try {
      const response = await removeAccess({
        assignmentCategoryId: category,
        vendorId: vendor,
      });
      if (response?.data?.success) {
        toast.success("Access removed successfully");
        onClose();
      } else if (response.error.data) {
        toast.error(response.error.data.message);
      }
    } catch (e) {}
    // Close the popup after granting access
  };

  return (
    <>
      {
        <div className="relative rounded-lg inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <button
              onClick={onClose}
              className="absolute text-3xl top-2 right-2 text-gray-600 hover:text-gray-800"
            >
              &times;
            </button>

            <h2 className="text-lg font-semibold text-center mb-4">
              {type === "give" ? "Grant Access" : "Remove Access"}
            </h2>

            <div className="mb-4">
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-700"
              >
                Select Category
              </label>
              <select
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">
                  {catFetching ? "Loading..." : "Select a category"}
                </option>
                {categories &&
                  categories?.assignmentcategories?.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-6">
              <label
                htmlFor="vendor"
                className="block text-sm font-medium text-gray-700"
              >
                Select Vendor
              </label>
              <select
                id="vendor"
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">
                  {vendorFetching ? "Loading..." : "Select a vendor"}
                </option>
                {vendors.map((v) => (
                  <option key={v.id} value={v.id}>
                    {v.name}
                  </option>
                ))}
              </select>
            </div>

            <MyButton
              onClick={() => {
                if (type === "give") {
                  handleGrantAccess();
                } else {
                  handleRemoveAccess();
                }
              }}
              disabled={!category || !vendor || removingAccess || givingAccess}
              className={`w-full ${
                type === "give" ? "bg-violet-600" : " bg-red-600"
              }   text-white py-2 px-4 rounded-md ${
                type === "give" ? "hover:bg-violet-700" : "hover:bg-red-700"
              } disabled:bg-gray-300 disabled:cursor-not-allowed`}
            >
              {type === "give" ? "Grant Access" : "Remove Access"}
            </MyButton>
          </div>
        </div>
      }
    </>
  );
};

export default AssignmentCategoryAccessForm;
