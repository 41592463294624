import React, { useEffect, useState } from "react";
import MyButton from "../../components/common/MyButton";
import {
  useFetchAllInstitutionsQuery,
  useFetchVendorInstitutionsQuery,
} from "../../redux/rtkquery/InstitutionsReducer";
import { useSelector } from "react-redux";
import { useDownloadContestReportsQuery } from "../../redux/rtkquery/contest/Contest";

const DownloadContestReport = ({ onClose, constest }) => {
  const role = useSelector((state) => state.userslice.role);
  const vendortype = useSelector((state) => state.userslice.vendortype);
  const [selectedInstitution, setSelectedInstitution] = useState("");
  const [triggerDownload, setTriggerDownload] = useState(false);

  const { data: response1 = {}, isFetching: isFetching1 } =
    useFetchVendorInstitutionsQuery(undefined, {
      skip: role !== "vendor" || vendortype === "traininginstitution",
      suspense: true,
    });

  const { data: response2 = {}, isFetching: isFetching2 } =
    useFetchAllInstitutionsQuery(undefined, {
      skip: role !== "admin",
      suspense: true,
    });

  const isFetching = isFetching1 || isFetching2;

  // useEffect(() => {
  //   if (role === "admin") {
  //     setInstitutions(response2?.allinstitutions || []);
  //   } else if (role === "vendor") {
  //     setInstitutions(response1?.vendorinstitutions || []);
  //   }
  // }, [response1, response2, role]);

  const { data, isFetching: isFetching3 } = useDownloadContestReportsQuery(
    {
      contestid: constest?.id,
      institutionid: selectedInstitution,
    },
    { skip: !triggerDownload || selectedInstitution === "" }
  );

  useEffect(() => {
    if (data && !isFetching3) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `WeeklyContestReport.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      setTriggerDownload(false); // Reset trigger after download
    }
  }, [data, isFetching3]);

  const handleDownload = () => {
    if (vendortype === "traininginstitution") {
      setTriggerDownload(true);
    }
    if (selectedInstitution) {
      setTriggerDownload(true);
    } else {
      if (vendortype !== "traininginstitution") {
        alert("Please select an institution first.");
      }
    }
  };
  const institutions =
    role === "admin"
      ? response2?.allinstitutions || []
      : response1?.vendorinstitutions || [];

  return (
    <div className="bg-white px-6 pb-6 rounded-lg shadow-lg w-full max-w-md relative">
      <h2 className="text-xl font-semibold mb-4 text-center">
        Download Report
      </h2>
      <div className="flex flex-col space-y-2">
        <label htmlFor="institution" className="text-gray-600">
          Select Institution
        </label>
        <select
          id="institution"
          onChange={(e) => {
            setTriggerDownload(false);
            setSelectedInstitution(e.target.value);
          }}
          value={selectedInstitution}
          className="border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
        >
          <option value="" disabled>
            Select an institution
          </option>
          {isFetching && <option disabled>Loading...</option>}
          {!isFetching &&
            institutions?.map((institution) => (
              <option key={institution?.id} value={institution?.id}>
                {institution?.name}
              </option>
            ))}
        </select>
      </div>
      <div className="space-y-4 mt-5">
        <MyButton
          isLoading2={isFetching3}
          onClick={handleDownload}
          className="w-full bg-violet-600 text-white py-2 rounded-md hover:bg-violet-700"
        >
          Download Report
        </MyButton>
      </div>
      <button
        onClick={onClose}
        className="absolute text-4xl top-0 right-0 mt-2 mr-2 text-gray-600"
      >
        &times;
      </button>
    </div>
  );
};

export default DownloadContestReport;
