import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../api/env";

import { subjectendpoints } from "../../services/Apis";
import createGlobalApi from "./createGlobalApi";

const { GET_ALL_SUBJECTS, GET_SUBJECTS_BY_ID, CREATE_SUBJECT, EDIT_SUBJECT } =
  subjectendpoints;

export const subjectsApi = createGlobalApi({
  reducerPath: "subjectsApi",
  tagTypes: ["Subjects"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    getSubjects: builder.query({
      query: () => ({
        url: GET_ALL_SUBJECTS,
        method: "GET",
      }),
      providesTags: ["Subjects"],
    }),
    getSubjectsByCategoryId: builder.query({
      query: (categoryId) => `${GET_SUBJECTS_BY_ID}${categoryId}`,
      providesTags: ["Subjects"],
    }),
    createSubject: builder.mutation({
      query: (subjectData) => ({
        url: CREATE_SUBJECT,
        method: "POST",
        body: subjectData,
      }),
      invalidatesTags: ["Subjects"],
    }),
    editSubject: builder.mutation({
      query: (subjectData) => ({
        url: EDIT_SUBJECT,
        method: "PUT",
        body: subjectData,
      }),
      invalidatesTags: ["Subjects"],
    }),
  }),
});

export const {
  useGetSubjectsQuery,
  useGetSubjectsByCategoryIdQuery,
  useCreateSubjectMutation,
  useEditSubjectMutation,
} = subjectsApi;
