import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../api/env";

import { subcategoriesendpoints } from "../../services/Apis";
import createGlobalApi from "./createGlobalApi";

const {
  CREATE_SUBCATEGORY,
  GET_ALL_SUBCATEGORIES,
  GET_SUBCATEGORY_BYID,
  EDIT_SUBCATEGORY,
} = subcategoriesendpoints;

export const subCategoriesApi = createGlobalApi({
  reducerPath: "subCategoriesApi",
  // tagTypes: ["Subcategories"], // Unified tag
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    fetchAllSubCategories: builder.query({
      query: () => GET_ALL_SUBCATEGORIES,
      transformResponse: (response) => response.allsubcategories,
      // providesTags: ["Subcategories"], // Unified tag
    }),
    fetchSubCategoriesById: builder.query({
      query: (categoryId) => `${GET_SUBCATEGORY_BYID}/${categoryId}`,
      transformResponse: (response) => response.subcategories,
      // providesTags: ["Subcategories"], // Unified tag
    }),
    createSubCategory: builder.mutation({
      query: (newSubCategory) => ({
        url: CREATE_SUBCATEGORY,
        method: "POST",
        body: newSubCategory,
      }),
      // invalidatesTags: ["Subcategories"], // Unified tag
    }),
    editSubCategory: builder.mutation({
      query: (editSubCategory) => ({
        url: EDIT_SUBCATEGORY,
        method: "PUT",
        body: editSubCategory,
      }),
      // invalidatesTags: ["Subcategories"], // Unified tag
    }),
  }),
});

export const {
  useFetchAllSubCategoriesQuery,
  useFetchSubCategoriesByIdQuery,
  useCreateSubCategoryMutation,
  useEditSubCategoryMutation,
} = subCategoriesApi;
