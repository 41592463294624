// src/services/chaptersApi.js

import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../api/env";

import { chaptersendpoints } from "../../services/Apis";
import createGlobalApi from "./createGlobalApi";
const { CREATE_SUBJECT, EDIT_SUBJECT, GET_CHAPTERSBY_SUBJECT } =
  chaptersendpoints;
export const chaptersApi = createGlobalApi({
  reducerPath: "chaptersApi",
  tagTypes: ["Chapters"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    fetchChaptersBySubject: builder.query({
      query: (subjectId) => `${GET_CHAPTERSBY_SUBJECT}/${subjectId}`,
      providesTags: ["Chapters"],
    }),
    createChapter: builder.mutation({
      query: (newChapter) => ({
        url: CREATE_SUBJECT,
        method: "POST",
        body: newChapter,
      }),
      invalidatesTags: ["Chapters"],
    }),
    editChapter: builder.mutation({
      query: (editChapter) => ({
        url: EDIT_SUBJECT,
        method: "PUT",
        body: editChapter,
      }),
      invalidatesTags: ["Chapters"],
    }),
  }),
});

export const {
  useFetchChaptersBySubjectQuery,
  useCreateChapterMutation,
  useEditChapterMutation,
} = chaptersApi;
