import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useGetStudentAssigmentCategoriesQuery } from "../../../../redux/rtkquery/assignment/AssignmentCategory";

import { setActiveItem } from "../../../../redux/slices/SidebarSlice";

// Icon Imports
import {
  LucideRocket,
  LucideTrophy,
  LucideLightbulb,
  LucideTarget,
  LucideBookOpen,
  LucideCodeXml,
} from "lucide-react";

// Constants
const MOTIVATIONAL_LINES = [
  "Your journey to excellence starts here!",
  "Transform challenges into opportunities.",
  "Every assignment is a step towards mastery.",
  "Learn, grow, and conquer your goals.",
  "Unlock your potential, one task at a time.",
  "Consistency is the key to breakthrough.",
  "Your hard work defines your success story.",
];

const ICONS = [
  LucideRocket,
  LucideTrophy,
  LucideLightbulb,
  LucideTarget,
  LucideBookOpen,
  LucideCodeXml,
];

// Utility Functions
const getRandomItem = (array) =>
  array[Math.floor(Math.random() * array.length)];

export default function AssignmentCategories() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: categories, isLoading } =
    useGetStudentAssigmentCategoriesQuery();

  const handleCategorySelect = (category) => {
    navigate(`/assignmentsubcategory/${category.id}/${category.name}`, {
      state: category.accesstype,
    });
    dispatch(setActiveItem("/students/assignmentcategories"));
  };

  const renderCategoryCard = (category) => {
    const RandomIcon = getRandomItem(ICONS);
    const motivationalMessage = getRandomItem(MOTIVATIONAL_LINES);

    return (
      <div
        key={category.id}
        className="bg-white rounded-2xl overflow-hidden shadow-lg border border-gray-100 hover:shadow-xl transition-all duration-300"
      >
        <div className="p-6 relative">
          {/* Background Icon */}
          <div className="absolute top-2 right-2 opacity-10">
            <RandomIcon size={80} strokeWidth={1} className="text-gray-300" />
          </div>

          {/* Category Details */}
          <div className="flex items-start space-x-4 mb-4">
            <div className="bg-gradient-to-br from-blue-100 to-blue-200 p-3 rounded-xl">
              <RandomIcon size={28} className="text-blue-600" />
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-800">
                {category.name}
              </h3>
              <p className="text-sm text-gray-500 mt-1">
                {category.subcategoriescount} Topics Available
              </p>
            </div>
          </div>

          {/* Motivational Message */}
          <div className="mb-4 h-12">
            <p className="text-sm italic text-gray-600">
              "{motivationalMessage}"
            </p>
          </div>

          {/* Action Button */}
          <button
            onClick={() => handleCategorySelect(category)}
            className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-3 rounded-lg font-semibold shadow-md hover:shadow-xl transition-all duration-300"
            whileTap={{ scale: 0.95 }}
          >
            Explore Category
          </button>
        </div>
      </div>
    );
  };

  const renderSkeleton = () => (
    <div className="bg-white rounded-2xl overflow-hidden shadow-lg border border-gray-100">
      <div className="p-6 relative">
        <Skeleton circle={true} height={80} width={80} className="mb-4" />
        <Skeleton height={28} width={150} className="mb-2" />
        <Skeleton height={20} width={100} className="mb-4" />
        <Skeleton height={50} count={2} />
        <Skeleton height={40} width="100%" className="mt-4" />
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="bg-gradient-to-r mb-5 from-gray-600 to-gray-400 p-6 rounded-t-xl">
          <h1 className="text-4xl font-bold text-center text-white mb-4">
            Your Assignments
          </h1>
          <p className="text-center text-white  max-w-2xl mx-auto">
            Success is the sum of small efforts repeated day in and day out.
          </p>
        </div>

        {/* Categories Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {isLoading
            ? Array(6)
                .fill()
                .map((_, index) => <div key={index}>{renderSkeleton()}</div>)
            : categories?.allcategories?.map(renderCategoryCard)}
        </div>
      </div>
    </div>
  );
}
