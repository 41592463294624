import { Pencil } from "lucide-react";
import React from "react";

const EditButton = () => {
  return (
    <button
      className="flex items-center px-4 py-2 text-sm text-yellow-600 bg-yellow-100 rounded-full hover:bg-yellow-200 transition-colors duration-200 "
      type="button"
    >
      <span className="button__text">Edit</span>
      <Pencil size={14} className="button__icon ml-2 text-yellow-600" />
    </button>
  );
};

export default EditButton;
