// services/categoriesApi.js
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../api/env";

import { categoriesendpoints } from "../../services/Apis";
import createGlobalApi from "./createGlobalApi";
const { CREATE_CATEGORY, EDIT_CATEGORY, DELETE_CATEGORY, GET_ALLCATEGORIES } =
  categoriesendpoints;
export const categoriesApi = createGlobalApi({
  reducerPath: "categoriesApi",
  tagTypes: ["Categories"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    fetchCategories: builder.query({
      query: () => GET_ALLCATEGORIES,
      transformResponse: (response) => response.allcategories,
      providesTags: ["Categories"], // Transforming to return only the categories array
    }),
    createCategory: builder.mutation({
      query: (newCategory) => ({
        url: CREATE_CATEGORY,
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["Categories"],
    }),
    editCategory: builder.mutation({
      query: (editCategory) => ({
        url: EDIT_CATEGORY,
        method: "PUT",
        body: editCategory,
      }),
      invalidatesTags: ["Categories"],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: DELETE_CATEGORY,
        method: "DELETE",
      }),
      invalidatesTags: ["Categories"],
    }),
  }),
});

export const {
  useFetchCategoriesQuery,
  useCreateCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi;
