import React from "react";
import { Download } from "lucide-react"; // Import the desired icon

const DownloadButton = () => {
  return (
    <div>
      <button
        className="flex items-center px-4 py-2 text-sm text-yellow-600 bg-yellow-100 rounded-full hover:bg-yellow-200 transition-colors duration-200"
        type="button"
      >
        <Download className="w-4 h-4 mr-2" /> {/* Add the icon */}
        Download
      </button>
    </div>
  );
};

export default DownloadButton;
