import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveItem } from "../../../redux/slices/SidebarSlice";
import {
  useFetchIndividualAssignmentAccessCategoriesQuery,
  useGetAssignmentCatgeoriesAccessVendorQuery,
} from "../../../redux/rtkquery/assignment/AssignmentCategory";
import AssignmentCategoryAccessForm from "../../../forms/assignment/AssignmentCategoryAccessForm";
import Background from "../../common/Background";
import ComponentLoading from "../../common/ComponentLoading";

const AssignmentCategoryAccess = () => {
  const role = useSelector((state) => state.userslice.role);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [type, setType] = useState("");
  const [vendorAccessDetails, setVendorAccessDetails] = useState(null);
  const dispatch = useDispatch();
  const { data: categories, isFetching } =
    useFetchIndividualAssignmentAccessCategoriesQuery(
      {},
      {
        suspense: true,
      }
    );
  const { data, isFetching: isFetching1 } =
    useGetAssignmentCatgeoriesAccessVendorQuery({
      assignmentcategoryid: selectedCategory,
    });
  useEffect(() => {
    setVendorAccessDetails(data?.vendorassignmentaccess);
  }, [data]);
  // Mock data for vendors access details

  const [grantForm, setGrantForm] = useState(false);
  useEffect(() => {
    dispatch(setActiveItem("/admin/assignmentcategoryaccess"));
  }, [dispatch]);
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
  };
  if (isFetching) return <ComponentLoading />;
  return (
    <>
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-center mb-6">
          Select Category
        </h2>
        <div className=" flex gap-2 justify-end">
          {["admin"].includes(role) && (
            <button
              onClick={() => {
                setType("give");
                setGrantForm(true);
              }}
              className="bg-violet-500 text-sm text-white rounded-full px-4 py-2 hover:bg-violet-600 transition-all"
            >
              + Grant Access
            </button>
          )}
          {["admin"].includes(role) && (
            <button
              onClick={() => {
                setType("remove");

                setGrantForm(true);
              }}
              className="bg-violet-500 text-sm text-white rounded-full px-4 py-2 hover:bg-violet-600 transition-all"
            >
              + Remove Access
            </button>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="category"
            className="block text-lg font-medium text-gray-700 mb-2"
          >
            Category
          </label>
          <select
            id="category"
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          >
            <option value="" disabled>
              Select a category
            </option>
            {categories &&
              categories?.assignmentcategories?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
          </select>
        </div>
        {isFetching1 && selectedCategory && <ComponentLoading />}
        {!isFetching1 && selectedCategory && vendorAccessDetails && (
          <div className="mt-6">
            <h3 className="text-xl font-semibold mb-4">
              Vendors Access Details
            </h3>
            <table className="min-w-full table-auto border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2 text-left text-gray-700">Vendor</th>
                  <th className="px-4 py-2 text-left text-gray-700">
                    Assignment Category
                  </th>
                </tr>
              </thead>
              <tbody>
                {vendorAccessDetails.map((vendor, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2 text-gray-600">
                      {vendor.vendor.name}
                    </td>
                    <td className="px-4 py-2 text-gray-600">
                      {vendor.assignmentcategory.name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {grantForm && (
        <Background>
          <AssignmentCategoryAccessForm
            onClose={() => setGrantForm(false)}
            selectedCategory={selectedCategory}
            type={type}
          />
        </Background>
      )}
    </>
  );
};

export default AssignmentCategoryAccess;
