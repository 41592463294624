import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

import MyButton from "../../components/common/MyButton";
import { useEditContestMutation } from "../../redux/rtkquery/contest/Contest";

const EditContest = ({ onClose, editContest }) => {
  console.log("editContest", editContest);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [starttime, setStartTime] = useState("");
  const [endtime, setEndTime] = useState("");
  useEffect(() => {
    if (editContest) {
      setTitle(editContest.title);
      setDescription(editContest.description);
      setStartTime(formatDateTime(editContest.starttime));
      setEndTime(formatDateTime(editContest.endtime));
    }
  }, [editContest]);
  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    return date.toISOString().slice(0, 16); // 'YYYY-MM-DDTHH:mm'
  };
  const [editContestMu] = useEditContestMutation();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const isoStartTime = new Date(starttime).toISOString();
    const isoEndTime = new Date(endtime).toISOString();
    try {
      let response;
      if (editContest) {
        response = await editContestMu({
          id: editContest.id,
          title,
          description,
          starttime: isoStartTime,
          endtime: isoEndTime,
        }).unwrap();
      }
      if (response.success) {
        toast.success("Contest updated successfully");
        onClose();
      } else {
        toast.error("Failed to update Contest");
      }
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-opacity-30 backdrop-blur-sm"
        onClick={onClose}
      />
      <div className="relative transform transition-all duration-300 ease-in-out">
        <form className="flex flex-col w-96 p-8 bg-white  shadow-2xl rounded-xl space-y-6 transform transition-all duration-300 ease-in-out">
          <h1 className="text-3xl font-bold text-center bg-gradient-to-r from-purple-600 to-violet-600 bg-clip-text text-transparent">
            Edit Contest
          </h1>

          <div className="space-y-2">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-200 bg-gray-50  dark:border-gray-600"
              placeholder="Enter category title"
            />
          </div>

          <div className="space-y-2">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 "
            >
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-200 bg-gray-50  dark:border-gray-600"
              rows="4"
              placeholder="Enter category description"
            />
          </div>
          <div className="space-y-2">
            <label
              htmlFor="starttime"
              className="block text-sm font-medium text-gray-700"
            >
              Start Time
            </label>
            <input
              type="datetime-local"
              id="starttime"
              value={starttime}
              onChange={(e) => setStartTime(e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-200 bg-gray-50 dark:border-gray-600"
              placeholder="Enter start time"
            />
          </div>

          <div className="space-y-2">
            <label
              htmlFor="endtime"
              className="block text-sm font-medium text-gray-700"
            >
              End Time
            </label>
            <input
              type="datetime-local"
              id="endtime"
              value={endtime}
              onChange={(e) => setEndTime(e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-200 bg-gray-50 dark:border-gray-600"
              placeholder="Enter end time"
            />
          </div>

          <div className="flex gap-4 pt-4">
            <MyButton
              onClick={async (e) => {
                await handleSubmit(e);
              }}
              className="flex-1 px-6 py-3 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transform transition-all duration-200 hover:scale-105"
            >
              Update
            </MyButton>
            <button
              onClick={onClose}
              type="button"
              className="flex-1 px-6 py-3 text-sm font-medium text-white bg-violet-600 rounded-lg hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transform transition-all duration-200 hover:scale-105"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditContest;
