// src/api/ApiSlice.js
import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../api/env";

import { questionsendpoints } from "../../services/Apis";
import createGlobalApi from "./createGlobalApi";
const {
  REMOVE_VISIBLE,
  GIVE_VISIBLE,
  GET_CODINGQ,
  GET_MCQ,
  GET_FILLINTHEBLANK,
  GET_ALLQUESTIONS,
} = questionsendpoints;
// Create an API slice with RTK Query
export const questionsApi = createGlobalApi({
  reducerPath: "api",
  tagTypes: ["MCQ", "FillInTheBlank", "Coding"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    fetchMcqs: builder.query({
      query: (data) => ({
        url: "/qb/question/getMCQs",
        method: "POST",
        body: data,
      }),
      providesTags: ["MCQ"],
      transformResponse: (response) => response.mcqs,
    }),
    fetchAllQuestions: builder.query({
      query: (data) => ({
        url: GET_ALLQUESTIONS,
        method: "POST",
        body: data,
      }),
      providesTags: ["MCQ"],
    }),
    fetchFillintheblanks: builder.query({
      query: (data) => ({
        url: "/qb/question/getFillintheblanks",
        method: "POST",
        body: data,
      }),
      providesTags: ["FillInTheBlank"],
      transformResponse: (response) => response.fillintheblanks,
    }),
    fetchCodingQuestions: builder.query({
      query: (data) => ({
        url: "/qb/question/getCodingQuestions",
        method: "POST",
        body: data,
      }),
      providesTags: ["Coding"],
      transformResponse: (response) => response.coding,
    }),
    fetchMCQ: builder.query({
      query: (data) => ({
        url: GET_MCQ,
        method: "POST",
        body: data,
      }),
      providesTags: ["MCQ"],
    }),
    fetchFillIntheBlank: builder.query({
      query: (data) => ({
        url: GET_FILLINTHEBLANK,
        method: "POST",
        body: data,
      }),
      providesTags: ["FillInTheBlank"],
    }),
    fetchCoding: builder.query({
      query: (data) => ({
        url: GET_CODINGQ,
        method: "POST",
        body: data,
      }),
      providesTags: ["MCQ"],
    }),

    giveVisibility: builder.mutation({
      query: (data) => ({
        url: `${GIVE_VISIBLE}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["MCQ", "FillInTheBlank", "Coding"],
    }),
    removeVisibility: builder.mutation({
      query: (data) => ({
        url: `${REMOVE_VISIBLE}`,
        method: "delete",
        body: data,
      }),
      invalidatesTags: ["MCQ", "FillInTheBlank", "Coding"],
    }),
    updateCodingQuestion: builder.mutation({
      query: (data) => ({
        url: "/qb/question/updateCoding",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Coding"],
    }),
    createMultipleQuestions: builder.mutation({
      query: (formData) => ({
        url: questionsendpoints.CREATE_MULTIPLE,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["MCQ", "FillInTheBlank"],
    }),
  }),
});

export const {
  useFetchCodingQuestionsQuery,
  useFetchFillintheblanksQuery,
  useFetchMcqsQuery,
  useFetchMCQQuery,
  useFetchFillIntheBlankQuery,
  useFetchCodingQuery,
  useGiveVisibilityMutation,
  useRemoveVisibilityMutation,
  useUpdateCodingQuestionMutation,
  useCreateMultipleQuestionsMutation,
  useFetchAllQuestionsQuery,
} = questionsApi;
