import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contestActiveTab: 0,
  currentPage: 1,
};

const ManageUISlice = createSlice({
  name: "contestui",
  initialState,
  reducers: {
    setContestActiveTab: (state, action) => {
      console.log("ui", action.payload);
      state.contestActiveTab = action.payload;
    },
    setPage: (state, action) => {
      console.log("ui", action.payload);
      state.currentPage = action.payload;
    },
  },
});

export const { setContestActiveTab, setPage } = ManageUISlice.actions;

export default ManageUISlice.reducer;
