"use client";

import { motion } from "framer-motion";
import { useEffect, useState } from "react";

export default function ComponentLoading() {
  const quotes = [
    "Compiling the code... 🖥️",
    "Debugging the bugs... 🐛",
    "Refactoring the logic... 🔄",
    "Loading the variables... 📦",
    "Optimizing the loops... ♾️",
    "Deploying awesomeness... 🚀",
    "Stacking the stacks... 🛠️",
    "Executing with precision... 🎯",
    "Rendering brilliance... ✨",
    "Syncing with the cloud... ☁️",
  ];

  const getRandomQuoteIndex = () => {
    return Math.floor(Math.random() * quotes.length); // Random index between 0 and quotes.length - 1
  };

  const [currentQuote, setCurrentQuote] = useState(
    quotes[getRandomQuoteIndex()]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuote(quotes[getRandomQuoteIndex()]); // Change quote randomly every 3 seconds
    }, 3000);

    return () => clearInterval(interval);
  }, [quotes]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen ">
      <motion.div
        className="w-16 h-16 border-4 border-violet-200 border-t-violet-600 rounded-full"
        animate={{ rotate: 360 }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: "linear",
        }}
      />
      <motion.div
        className="mt-4 text-lg font-semibold text-violet-600"
        animate={{ opacity: [1, 0.5, 1] }}
        transition={{
          duration: 1.5,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        {currentQuote}
      </motion.div>
    </div>
  );
}
