import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { billingendpoints } from "../../../services/Apis";
import { SERVER_URL } from "../../../api/env";
import createGlobalApi from "../createGlobalApi";

const {
  GET_BILLING,
  GRANT_VENDOR,
  REMOVE_VENDOR,
  GRANT_INSTITUTION,
  REMOVE_INSTITUTION,
  VERIFY_PAY,
  VERIFY_PAY_POST,
} = billingendpoints;

export const billingApi = createGlobalApi({
  reducerPath: "billingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    // Fetch billing information
    fetchBilling: builder.query({
      query: () => ({
        url: GET_BILLING,
        method: "GET",
      }),
      providesTags: ["Billing", "Students"],
    }),
    // Grant payment to vendor
    grantVendorPayment: builder.mutation({
      query: (vendorData) => ({
        url: GRANT_VENDOR,
        method: "POST",
        body: vendorData,
      }),
      invalidatesTags: ["Billing"], // Invalidate Billing after this mutation
    }),
    // Remove payment from vendor
    removeVendorPayment: builder.mutation({
      query: (vendorId) => ({
        url: REMOVE_VENDOR,
        method: "POST",
        body: vendorId,
      }),
      invalidatesTags: ["Billing"], // Invalidate Billing after this mutation
    }),
    // Grant payment to institution
    grantInstitutionPayment: builder.mutation({
      query: (institutionData) => ({
        url: GRANT_INSTITUTION,
        method: "POST",
        body: institutionData,
      }),
      invalidatesTags: ["Billing"], // Invalidate Billing after this mutation
    }),
    // Remove payment from institution
    removeInstitutionPayment: builder.mutation({
      query: (institutionId) => ({
        url: REMOVE_INSTITUTION,
        method: "POST",
        body: institutionId,
      }),
      invalidatesTags: ["Billing"], // Invalidate Billing after this mutation
    }),
    // Verify vendor payment
    verifyPayment: builder.mutation({
      query: (data) => ({
        url: VERIFY_PAY,
        method: "POST",
        body: data,
      }),
    }),
    verifyPaymentPost: builder.mutation({
      query: (data) => ({
        url: VERIFY_PAY_POST,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useFetchBillingQuery,
  useGrantVendorPaymentMutation,
  useRemoveVendorPaymentMutation,
  useGrantInstitutionPaymentMutation,
  useRemoveInstitutionPaymentMutation,
  useVerifyPaymentMutation,
  useVerifyPaymentPostMutation,
} = billingApi;
