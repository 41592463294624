import React, { useEffect } from "react";

const Background = ({ children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="fixed -top-8  inset-0 flex items-center justify-center z-50 bg-opacity-50 backdrop-blur-[2px]">
      {children}
    </div>
  );
};

export default Background;
