import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../../api/env";
import { globalDSAendpoints, questionsendpoints } from "../../../services/Apis";
import createGlobalApi from "../createGlobalApi";
const {
  GET_ALL_DSA,
  CREATE_DSA_GLOBAL,
  getDSACounts,
  HANDLE_IMAGE,
  GET_ALL_DSA_BY_STUDENT,
  SUBMIT_CODE_DSA,
  GET_REPORTS,
  SINGLE_REPORT,
  getAllTags,
  getAllCompanies,
  ONE_DSA,
} = globalDSAendpoints;
const { REMOVE_VISIBLE, GIVE_VISIBLE } = questionsendpoints;
export const dsaApi = createGlobalApi({
  reducerPath: "dsaApi",
  tagTypes: ["dsa"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    createGlobalDSA: builder.mutation({
      query: (newDSA) => ({
        url: `${CREATE_DSA_GLOBAL}`,
        method: "POST",
        body: newDSA,
      }),
      invalidatesTags: ["dsa"],
    }),
    submitDSA: builder.mutation({
      query: (newDSA) => ({
        url: `${SUBMIT_CODE_DSA}`,
        method: "POST",
        body: newDSA,
      }),
      invalidatesTags: ["dsa"],
    }),
    handleQuestionImage: builder.mutation({
      query: (newDSA1) => ({
        url: `${HANDLE_IMAGE}`,
        method: "POST",
        body: newDSA1,
      }),
      invalidatesTags: ["dsa"],
    }),
    getDsaQuestion: builder.query({
      query: (data) => ({
        url: `${ONE_DSA}`,
        method: "POST",
        body: data,
      }),
    }),
    getAllGlobalDSA: builder.query({
      query: () => `${GET_ALL_DSA}`,
      providesTags: ["dsa"],
    }),
    getDSACounts: builder.query({
      query: () => `${getDSACounts}`,
      providesTags: ["dsa"],
    }),
    getAllTags: builder.query({
      query: () => `${getAllTags}`,
      providesTags: ["dsa"],
      transformResponse: (response) => {
        if (response.success) {
          return response.tags;
        } else {
          return [];
        }
      },
    }),
    getAllComapanies: builder.query({
      query: () => `${getAllCompanies}`,
      providesTags: ["dsa"],
      transformResponse: (response) => {
        if (response.success) {
          return response.companies;
        } else {
          return [];
        }
      },
    }),
    getDSAReports: builder.query({
      query: ({ page = 1, limit = 5 }) => ({
        url: `${GET_REPORTS}`,
        params: {
          page,
          limit,
        },
      }),
      transformResponse: (response, meta, arg) => ({
        reports: response.reports,
        totalPages: response.totalPages,
        currentPage: arg.page,
      }),
      providesTags: ["dsa"],
    }),
    getDSAReportsByCharge: builder.query({
      query: () => `${GET_REPORTS}`,
      providesTags: ["dsa"],
    }),
    getDSAsingleReports: builder.mutation({
      query: (data) => ({
        url: `${SINGLE_REPORT}`,
        method: "POST",
        body: data,
      }),
    }),
    getAllGlobalDSAByStudent: builder.query({
      query: ({
        page = 1,
        pageSize = 10,
        searchTerm = "",
        difficulty = "",
        status = "",
        tags = [],
        companies = [],
      }) => ({
        url: `${GET_ALL_DSA_BY_STUDENT}`,
        params: {
          page,
          pageSize,
          searchTerm,
          difficulty,
          status,
          tags: tags.join(","),
          companies: companies.join(","),
        },
      }),
      providesTags: ["dsa"],
      transformResponse: (response) => ({
        questions: response.questions,
        totalQuestions: response.totalQuestions,
        totalPages: response.totalPages,
        currentPage: response.currentPage,
      }),
    }),
    giveVisibilityGlobal: builder.mutation({
      query: (data) => ({
        url: `${GIVE_VISIBLE}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["dsa"],
    }),
    removeVisibilityGlobal: builder.mutation({
      query: (data) => ({
        url: `${REMOVE_VISIBLE}`,
        method: "delete",
        body: data,
      }),
      invalidatesTags: ["dsa"],
    }),
    editGlobalDSA: builder.mutation({
      query: (data) => ({
        url: "/global/dsa/edit",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["dsa"],
    }),
  }),
});

export const {
  useCreateGlobalDSAMutation,
  useGetDSAReportsQuery,
  useGetDSAsingleReportsMutation,
  useGiveVisibilityGlobalMutation,
  useRemoveVisibilityGlobalMutation,
  useGetAllGlobalDSAQuery,
  useHandleQuestionImageMutation,
  useGetAllGlobalDSAByStudentQuery,
  useSubmitDSAMutation,
  useEditGlobalDSAMutation,
  useGetAllTagsQuery,
  useGetAllComapaniesQuery,
  useGetDSACountsQuery,
  useGetDsaQuestionQuery,
  useGetDSAReportsByChargeQuery,
} = dsaApi;
