import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SpeedInsights } from "@vercel/speed-insights/react"
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store/store.js";
import NoInternetDialog from "./NoInternetDialog.js";
import { AlertOctagon } from "lucide-react";
import Assesify from "./assests/assesify.png";
import Loading from "./components/common/Loading";
import { BrowserRouter } from "react-router-dom";
import { SERVER_URL } from "./api/env.js";
const root = ReactDOM.createRoot(document.getElementById("root"));

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
        <div className="flex items-center justify-center mb-6">
          <img src={Assesify} alt="Assesify Logo" className="" />
        </div>
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <div className="flex items-center">
            <AlertOctagon className="h-6 w-6 text-red-500 mr-3" />
            <p className="text-red-700 font-medium">Something went wrong</p>
          </div>
        </div>
        <button
          onClick={resetErrorBoundary}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Try again
        </button>
      </div>
    </div>
  );
}
// console.log = function () {};
// console.error = function () {};
// console.warn = function () {};
root.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      window.location.reload();
    }}
  >
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Provider store={store}>
          <SpeedInsights />
          {console.log("ser", SERVER_URL)}
          <App />
        </Provider>
      </Suspense>
    </BrowserRouter>
  </ErrorBoundary>
);

reportWebVitals();
