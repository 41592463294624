"use client";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import logo from "../../../../assests/assesify.png";
import { useFetchStudentAllContestsQuery } from "../../../../redux/rtkquery/contest/Contest";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Pagination from "../../../reports/contest/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../../../../redux/slices/ui/Manageui";
import { Bell, Calendar } from "lucide-react";

const options = {
  day: "2-digit",
  month: "short",
  year: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
  timeZone: "Asia/Kolkata", // IST timezone
};

export default function CodingChallenges() {
  let [contests, setContests] = useState([]);
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(1);
  const currentPage = useSelector((state) => state.manageUISlice.currentPage);
  const { data: contests2, isLoading } = useFetchStudentAllContestsQuery({
    page: currentPage,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const page = parseInt(queryParams.get("page") || "1", 10);

  useEffect(() => {
    dispatch(setPage(page));
  }, [page]);

  useEffect(() => {
    if (contests2) {
      setContests(contests2?.contests);
      setTotalPages(contests2?.totalpages);
      dispatch(setPage(contests2?.currentpage));
    }
  }, [contests2]);

  const navigate = useNavigate();
  const handlePageChange = (newPage) => {
    navigate(`?page=${newPage}`);
  };
  const [showTooltip, setShowTooltip] = useState(-1);
  const handleAddReminder = (contest) => {
    const googleCalendarLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      contest?.title
    )}&dates=${new Date(contest?.starttime)
      .toISOString()
      .replace(/-|:|\.\d+/g, "")}/${new Date(contest?.endtime)
      .toISOString()
      .replace(/-|:|\.\d+/g, "")}&details=${encodeURIComponent(
      contest?.description
    )}&location=${encodeURIComponent(window.location.href)}`;
    window.open(googleCalendarLink, "_blank");
  };
  return (
    <div className="container mx-auto px-4 py-8 sm:px-0 lg:px-8">
      <div className=" max-w-4xl md:min-w-full md: mx-auto space-y-6">
        <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6">
          Take the Contests
        </h1>

        <div
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="space-y-6"
        >
          {isLoading
            ? Array.from({ length: 3 }).map((_, index) => (
                <div
                  key={index}
                  className="flex flex-col sm:flex-row gap-4 mb-6 p-4 bg-white shadow-sm rounded-lg"
                >
                  <Skeleton
                    width={80}
                    height={80}
                    className="self-center sm:self-start"
                  />
                  <div className="flex-grow">
                    <Skeleton height={20} width="60%" />
                    <Skeleton height={15} width="90%" />
                    <Skeleton height={15} width="50%" className="mb-2" />
                    <Skeleton width="30%" height={30} />
                  </div>
                </div>
              ))
            : contests?.map((contest, index) => (
                <div
                  key={contest.id}
                  className="flex flex-col sm:flex-row gap-4 relative"
                >
                  {/* Date Marker - Hidden on mobile for cleaner look */}
                  <div className="hidden sm:block relative flex-shrink-0 w-20">
                    <div className="bg-violet-100 p-3 rounded-lg text-center">
                      <div className="text-xl font-bold">
                        {new Date(contest.starttime).getUTCDate()}
                      </div>
                      <div className="text-sm text-gray-600">
                        {new Date(contest.starttime).toLocaleString("en-US", {
                          month: "short",
                        })}{" "}
                        {new Date(contest.starttime)
                          .getFullYear()
                          .toString()
                          .slice(-2)}
                      </div>
                    </div>
                    {index !== contests.length - 1 && (
                      <div className="absolute top-[4.5rem] h-[calc(100%+1.5rem)] w-0.5 bg-violet-200 left-1/2 transform -translate-x-1/2" />
                    )}
                    <div className="absolute top-[4.5rem] w-3 h-3 rounded-full bg-violet-500 z-10 left-1/2 transform -translate-x-1/2" />
                  </div>

                  {/* Contest Card */}
                  <div
                    style={{
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                    className="flex-grow p-4 sm:p-6 bg-white rounded-lg shadow-lg relative"
                  >
                    {/* Reminder and Calendar Button */}
                    <div className=" ">
                      {/* Rest of the existing contest card code */}
                      <div className="flex flex-col sm:flex-row justify-between items-start mb-4">
                        <h2 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-0">
                          {contest.title}
                        </h2>
                        <div className=" relative flex gap-2">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                            On{" "}
                            <span className="font-bold flex items-center ml-1">
                              <img
                                src={logo}
                                alt="status"
                                className="w-10 sm:w-14 h-auto object-contain rounded-full"
                              />
                            </span>
                          </span>
                          {new Date(contest.starttime) >= new Date() && (
                            <div
                              className=" group"
                              onMouseEnter={() => setShowTooltip(index)}
                              onMouseLeave={() => setShowTooltip(-1)}
                            >
                              <button
                                onClick={() => handleAddReminder(contest)}
                                className="text-gray-500 hover:text-violet-600"
                              >
                                <Bell size={24} />
                              </button>

                              {showTooltip === index && (
                                <div
                                  className="flex bg-violet-100 text-sm px-4 py-2 rounded-lg absolute left-0 -top-12 items-center space-x-2 cursor-pointer hover:text-gray-300 "
                                  // onClick={handleAddToCalendar}
                                >
                                  <Calendar size={14} />
                                  <span className="whitespace-nowrap">
                                    Add to Calendar
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-600 mb-4 text-sm sm:text-base">
                      {contest.description}
                    </p>

                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
                      <div className="space-y-1">
                        <div className="text-xs sm:text-sm">
                          <span className="font-semibold">Start:</span>{" "}
                          {new Intl.DateTimeFormat("en-US", options).format(
                            new Date(contest.starttime)
                          )}{" "}
                          IST
                        </div>
                        <div className="text-xs sm:text-sm">
                          <span className="font-semibold">End:</span>{" "}
                          {new Intl.DateTimeFormat("en-US", options).format(
                            new Date(contest.endtime)
                          )}{" "}
                          IST
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
                        <button
                          onClick={() => navigate(`/contests/${contest.id}`)}
                          className="w-full sm:w-auto px-4 py-2 hidden sm:block rounded-md text-sm font-medium bg-violet-500 text-white hover:bg-violet-600"
                        >
                          Open
                        </button>
                        {contest?.status !== "upon" && (
                          <button
                            onClick={() =>
                              navigate(
                                `/contests/${contest.id}/contestLeaderboard/?page=1`
                              )
                            }
                            className={`w-full sm:w-auto px-4 py-2 rounded-md text-sm font-medium ${
                              contest?.status === "upon"
                                ? "bg-violet-500 text-white hover:bg-violet-600"
                                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                            }`}
                          >
                            Results
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>

        <div className="flex justify-end mt-6">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
