import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducer/Reducer";
import { studentsApi } from "../rtkquery/FetchStudents2";
import { vendorsApi } from "../rtkquery/VendorsReducer";
import { institutionApi } from "../rtkquery/InstitutionsReducer";
import { batchYearsApi } from "../rtkquery/BatchYearsReducer";
import { batchApi } from "../rtkquery/BatchReducer";
import { assessmentCategoryApi } from "../rtkquery/assessment/AssessmentCategory";
import { subjectsApi } from "../rtkquery/Subject";
import { categoriesApi } from "../rtkquery/Categories";
import { subCategoriesApi } from "../rtkquery/SubCategories";
import { chaptersApi } from "../rtkquery/Chapters";
import { questionsApi } from "../rtkquery/Questions";
import { QBAccessApi } from "../rtkquery/QBAccess";
import { assessmentApi } from "../rtkquery/assessment/Assessment";
import { assignmentCategoryApi } from "../rtkquery/assignment/AssignmentCategory";
import { assignmentApi } from "../rtkquery/assignment/Assignment";
import { studentAssessmentsApi } from "../rtkquery/assessment/StudentAssessment";
import { studentAssignmentsApi } from "../rtkquery/assignment/StudentAssignments";
import { dsaApi } from "../rtkquery/GlobalDSA/globaldsa";
import { learningApi } from "../rtkquery/Learning";
import { assessmentReportsApi } from "../rtkquery/assessment/AssessmentReports";
import { billingApi } from "../rtkquery/billing/billing";
import { dashboardApi } from "../rtkquery/Dashboard";
import { compilerApi } from "../rtkquery/compiler/Compiler";
import { codingApi } from "../rtkquery/compiler/Coding";
import { contestApi } from "../rtkquery/contest/Contest";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(studentsApi.middleware)
      .concat(vendorsApi.middleware)
      .concat(institutionApi.middleware)
      .concat(batchYearsApi.middleware)
      .concat(batchApi.middleware)
      .concat(assessmentCategoryApi.middleware)
      .concat(subjectsApi.middleware)
      .concat(questionsApi.middleware)
      .concat(categoriesApi.middleware)
      .concat(subCategoriesApi.middleware)
      .concat(categoriesApi.middleware)
      .concat(chaptersApi.middleware)
      .concat(QBAccessApi.middleware)
      .concat(assessmentApi.middleware)
      .concat(assignmentCategoryApi.middleware)
      .concat(assignmentApi.middleware)
      .concat(studentAssessmentsApi.middleware)
      .concat(dsaApi.middleware)
      .concat(studentAssignmentsApi.middleware)
      .concat(learningApi.middleware)
      .concat(assessmentReportsApi.middleware)
      .concat(billingApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(compilerApi.middleware)
      .concat(codingApi.middleware)
      .concat(contestApi.middleware),
});

export default store;
