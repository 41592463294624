import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useGetSubjectsByCategoryIdQuery } from "../../redux/rtkquery/Subject";
import { useFetchCategoriesQuery } from "../../redux/rtkquery/Categories";
import { useFetchSubCategoriesByIdQuery } from "../../redux/rtkquery/SubCategories";

const useCategories = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [subcategories, setSubCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedsubjectId, setselectedSubjectId] = useState("");
  const dispatch = useDispatch();
  const { data: categories, isLoading: categoriesLoading } =
    useFetchCategoriesQuery();

  const {
    data: subcategoriesinfo,
    isLoading: subcategoriesLoading,
    refetch,
  } = useFetchSubCategoriesByIdQuery(selectedCategoryId, {
    skip: !selectedCategoryId,
  });
  const role = useSelector((state) => state.userslice.role);

  const { data: subjectsinfo } = useGetSubjectsByCategoryIdQuery(
    role === "institution" ? undefined : selectedCategoryId, // Pass undefined if role is institution
    {
      skip: role !== "institution" && selectedCategoryId === "", // Skip if role is not institution and no selected category
    }
  );
  useEffect(() => {
    setSubjects(subjectsinfo?.subjects);
  }, [subjectsinfo]);
  useEffect(() => {
    if (selectedCategoryId) {
      refetch();
      // setSubCategories(subcategoriesinfo);
    } else {
      if (role !== "institution") {
        setSubjects([]);
        setSubCategories([]);
      }
    }
  }, [subcategoriesinfo, selectedCategoryId]);

  const handleCategoryChange = (e) => {
    setSelectedCategoryId(e.target.value);
    setselectedSubjectId("");
    setSubjects([]);
  };

  const handleFetchSubCategories = () => {
    if (selectedCategoryId) {
      refetch();
      setSubCategories(subcategoriesinfo);
    } else {
      setSubCategories([]);
    }
  };

  const handleFetchSubjects = () => {
    if (selectedCategoryId) {
      setSubjects(subjectsinfo?.subjects);
      console.log("subject", subjectsinfo);
    } else {
      setSubjects([]);
    }
  };

  return {
    categories,
    subcategories,
    selectedCategoryId,
    handleCategoryChange,
    handleFetchSubCategories,
    setSubCategories,
    handleFetchSubjects,
    subcategoriesLoading,
    subjects,
    selectedsubjectId,
    setselectedSubjectId,
    setSelectedCategoryId,
    categoriesLoading,
  };
};

export default useCategories;
