// services/assessmentsApi.js
import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { assessmentreportendpoints } from "../../../services/Apis";
import { SERVER_URL } from "../../../api/env";
import createGlobalApi from "../createGlobalApi";

const {
  GET_ASSESSMENT_REPORT,
  GET_ALLASSESSMENT_REPORTS,
  GET_FILTERASSESSMENT_REPORTS,
  GET_SUMMARYASSESSMENT_REPORT,
  GET_STUDENTSASSESSMENT_REPORTS,
} = assessmentreportendpoints;

export const assessmentReportsApi = createGlobalApi({
  reducerPath: "assessmentReportsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    getAssessmentReport: builder.query({
      query: (credentials) => ({
        url: GET_ASSESSMENT_REPORT,
        method: "POST",
        body: credentials,
      }),
    }),
    getAllAssessmentReports: builder.query({
      query: (credentials) => ({
        url: GET_ALLASSESSMENT_REPORTS,
        method: "POST",
        body: credentials,
      }),
    }),
    getFilterAssessmentReport: builder.query({
      query: (credentials) => ({
        url: GET_FILTERASSESSMENT_REPORTS,
        method: "POST",
        body: credentials,
      }),
    }),
    getSummaryAssessmentReports: builder.query({
      query: (credentials) => ({
        url: GET_SUMMARYASSESSMENT_REPORT,
        method: "POST",
        body: credentials,
      }),
    }),
    getStudentAssessmentsReport: builder.query({
      query: () => GET_STUDENTSASSESSMENT_REPORTS,
    }),
  }),
});

export const {
  useGetAllAssessmentReportsQuery,
  useGetAssessmentReportQuery,
  useGetFilterAssessmentReportQuery,
  useGetSummaryAssessmentReportsQuery,
  useGetStudentAssessmentsReportQuery,
} = assessmentReportsApi;
