import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { assignmentcategoryendpoints } from "../../../services/Apis";
import { SERVER_URL } from "../../../api/env";
import createGlobalApi from "../createGlobalApi";

const {
  CREATE_ASSIGNMENTCATEGORY,
  EDIT_ASSIGNMENTCATEGORY,
  GET_ALLASSIGNMENTCATEGORIES,
  GET_ASSIGNMENTCATEGORIES,
  GET_ASSIGNMENTACCESSCATEGORIES,
  CREATE_ASSIGNMENTSUBCATEGORY,
  EDIT_ASSIGNMENTSUBCATEGORY,
  GET_ASSIGNMENTSUBCATEGORIES,
  GIVE_ASSIGNMENTCATEGORYACCESSVENDOR,
  GET_ASSIGNMENTCATEGORIESACCESSVENDOR,
  REMOVE_ASSIGNMENTCATEGORYACCESSVENDOR,
  GET_STUDENTASSIGNMENTCATEGORIES,
  GET_STUDENTASSIGNMENTSUBCATEGORIES,
} = assignmentcategoryendpoints;

export const assignmentCategoryApi = createGlobalApi({
  reducerPath: "assigmentCategoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    fetchIndividualAssignmentCategories: builder.query({
      query: (cre) => ({
        url: GET_ASSIGNMENTCATEGORIES,
        method: "POST",
        body: cre,
      }),
    }),
    fetchIndividualAssignmentAccessCategories: builder.query({
      query: () => GET_ASSIGNMENTACCESSCATEGORIES,
    }),
    fetchAllAssignmentCategories: builder.query({
      query: (credentials) => ({
        url: GET_ALLASSIGNMENTCATEGORIES,
        method: "POST",
        params: credentials, // Assuming credentials are query parameters
      }),
    }),
    createAssignmentCategory: builder.mutation({
      query: (credentials) => ({
        url: CREATE_ASSIGNMENTCATEGORY,
        method: "POST",
        body: credentials,
      }),
    }),
    editAssignmentCategory: builder.mutation({
      query: (credentials) => ({
        url: EDIT_ASSIGNMENTCATEGORY,
        method: "PUT",
        body: credentials,
      }),
    }),
    createAssignmentSubCategory: builder.mutation({
      query: (credentials) => ({
        url: CREATE_ASSIGNMENTSUBCATEGORY,
        method: "POST",
        body: credentials,
      }),
    }),
    editAssignmentSubCategory: builder.mutation({
      query: (credentials) => ({
        url: EDIT_ASSIGNMENTSUBCATEGORY,
        method: "PUT",
        body: credentials,
      }),
    }),
    fetchAssignmentSubCategories: builder.query({
      query: (credentials) => ({
        url: GET_ASSIGNMENTSUBCATEGORIES,
        method: "POST",
        body: credentials, // Assuming credentials are query parameters
      }),
    }),
    addAssignmentCatgeoryAccessVendor: builder.mutation({
      query: (formData) => ({
        url: GIVE_ASSIGNMENTCATEGORYACCESSVENDOR,
        method: "POST",
        body: formData,
      }),
    }),
    removeAssignmentCatgeoryAccessVendor: builder.mutation({
      query: (formData) => ({
        url: REMOVE_ASSIGNMENTCATEGORYACCESSVENDOR,
        method: "POST",
        body: formData,
      }),
    }),
    getAssignmentCatgeoriesAccessVendor: builder.query({
      query: (formData) => ({
        url: GET_ASSIGNMENTCATEGORIESACCESSVENDOR,
        method: "POST",
        body: formData,
      }),
    }),
    getStudentAssignmentSubcategories: builder.query({
      query: (formData) => ({
        url: GET_STUDENTASSIGNMENTSUBCATEGORIES,
        method: "POST",
        body: formData,
      }),
    }),
    getStudentAssigmentCategories: builder.query({
      query: () => GET_STUDENTASSIGNMENTCATEGORIES,
    }),
  }),
});

export const {
  useFetchAllAssignmentCategoriesQuery,
  useFetchIndividualAssignmentCategoriesQuery,
  useCreateAssignmentCategoryMutation,
  useEditAssignmentCategoryMutation,
  useFetchIndividualAssignmentAccessCategoriesQuery,
  useCreateAssignmentSubCategoryMutation,
  useEditAssignmentSubCategoryMutation,
  useFetchAssignmentSubCategoriesQuery,
  useAddAssignmentCatgeoryAccessVendorMutation,
  useRemoveAssignmentCatgeoryAccessVendorMutation,
  useGetAssignmentCatgeoriesAccessVendorQuery,
  useGetStudentAssigmentCategoriesQuery,
  useGetStudentAssignmentSubcategoriesQuery,
} = assignmentCategoryApi;
