import React, { useEffect, useState } from "react";
import CreateSubcategory from "../../../forms/QB/CreateSubCategory";
import useCategories from "../../../hooks/QB/useCategories";
import { useDispatch, useSelector } from "react-redux";
import { setActiveItem } from "../../../redux/slices/SidebarSlice";
import Background from "../../common/Background";
import EditButton from "../../common/EditButton";
import MyTable from "../../common/MyTable";
import CreateAssignmentSubcategory from "../../../forms/assignment/CreateAssignmentSubCategory";
import {
  useFetchAssignmentSubCategoriesQuery,
  useFetchIndividualAssignmentCategoriesQuery,
} from "../../../redux/rtkquery/assignment/AssignmentCategory";
import View from "../../common/View";
import { useNavigate } from "react-router";
import ComponentLoading from "../../common/ComponentLoading";

const AssignmentSubCategory = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const dispatch = useDispatch();
  const [selectedCategoryId, setSelectedCategory] = useState("");

  const { data, isFetching: isFetching1 } =
    useFetchAssignmentSubCategoriesQuery({
      assignmentcategoryid: selectedCategoryId,
    });
  const { data: categories, isFetching } =
    useFetchIndividualAssignmentCategoriesQuery(undefined, {
      suspense: true,
    });
  const [isEdit, SetIsEdit] = useState(false);

  const handleOpenForm = () => {
    setShowCreateForm(true);
  };

  const handleCloseForm = (updatedCategory) => {
    setShowCreateForm(false);
    SetIsEdit(false);
  };

  const handleEdit = (subcategory) => {
    SetIsEdit(true);
    setCurrentCategory(subcategory);
    setShowCreateForm(true);
  };
  const navigate = useNavigate();
  const handleView = (id) => {
    navigate(`/${role}/assignment/${id}`);
  };

  const role = useSelector((state) => state.userslice.role);
  dispatch(setActiveItem(`/${role}/assignmentsubcategory`));
  if (isFetching) return <ComponentLoading />;
  return (
    <>
      <main className="flex-1 p-8">
        <div className="flex flex-col items-center">
          <div className="w-full ">
            <div className="flex justify-between items-center mb-8 text-white tracking-tight bg-gradient-to-r from-gray-600 to-gray-400 p-6 rounded-md">
              <h1 className="text-3xl font-extrabold ">
                Assignment Sub Categories
              </h1>
              <button
                onClick={handleOpenForm}
                className="flex items-center px-4 py-2 text-sm text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200 transition-colors duration-200"
              >
                + Create Assignment Sub Category
              </button>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 mb-6">
              <div className="relative w-full md:w-1/3">
                <select
                  value={selectedCategoryId}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-violet-500"
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {categories &&
                    categories.assignmentcategories?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category?.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {showCreateForm && (
              <Background>
                <CreateAssignmentSubcategory
                  initialData={currentCategory}
                  onClose={handleCloseForm}
                  isEdit={isEdit}
                />
              </Background>
            )}
            {isFetching1 && <ComponentLoading />}
            {!isFetching1 && (
              <div className="overflow-x-auto mt-6">
                {!isFetching1 && (
                  <MyTable>
                    <thead>
                      <tr>
                        <th>Name</th>
                        {["admin", "vendor"].includes(role) && (
                          <th>Created By</th>
                        )}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.subcategories?.length > 0 ? (
                        data?.subcategories.map((subcategory) => (
                          <tr key={subcategory.id}>
                            <td>{subcategory.name}</td>
                            {["admin", "vendor"].includes(role) && (
                              <td>
                                {subcategory?.admin?.name ||
                                  subcategory?.vendor?.name}
                              </td>
                            )}
                            <td className="flex gap-x-3">
                              {data?.canEdit ? (
                                <div
                                  onClick={() => {
                                    SetIsEdit(true);
                                    handleEdit(subcategory);
                                  }}
                                >
                                  <EditButton />
                                </div>
                              ) : (
                                "No Access"
                              )}
                              <div onClick={() => handleView(subcategory.id)}>
                                <View />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="2"
                            className="py-3 px-6 text-center text-gray-500 text-sm"
                          >
                            No subcategories
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </MyTable>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default AssignmentSubCategory;
