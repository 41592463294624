// src/redux/assignmentSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assignmentData: null,
};

const assignmentSlice = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    setAssignmentData(state, action) {
      state.assignmentData = action.payload;
    },
    clearAssignmentData(state) {
      state.assignmentData = null;
    },
  },
});

export const { setAssignmentData, clearAssignmentData } = assignmentSlice.actions;
export default assignmentSlice.reducer;
