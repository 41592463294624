import React, { useState } from 'react';
import { Copy, UserPlus } from 'lucide-react';

export function RoomControls({ roomId }) {
  const [copied, setCopied] = useState(false);

  const copyRoomId = async () => {
    await navigator.clipboard.writeText(roomId);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return React.createElement(
    'div',
    { className: 'flex items-center gap-4 mb-4' },
    React.createElement(
      'div',
      { className: 'flex items-center gap-2 bg-gray-100 px-4 py-2 rounded-lg' },
      React.createElement(UserPlus, { size: 20, className: 'text-gray-600' }),
      React.createElement('span', { className: 'font-mono' }, roomId)
    ),
    React.createElement(
      'button',
      {
        onClick: copyRoomId,
        className:
          'flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors',
      },
      React.createElement(Copy, { size: 20 }),
      copied ? 'Copied!' : 'Copy Room ID'
    )
  );
}
