import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  assessmentendpoints,
  assessmentreportendpoints,
} from "../../../services/Apis";
import { SERVER_URL } from "../../../api/env";
import createGlobalApi from "../createGlobalApi";
const {
  CREATE_ASSESSMENT,
  GET_ASSESSMENTS,
  EDIT_ASSESSMENT,
  GIVE_ASSESSMENTACCESS,
  GET_ASSESSMENTS_ACCESSDETAILS,
  EDIT_ASSESSMENT_ACCESS,
  DELETE_ASSESSMENT_ACCESS,
  GET_ASSESSMENT_INSTITUTIONS,
  EDIT_ASSESSMENTEXAMSCHEDULE_ACCESS,
  CHECK_ASSESSMENTPASS,
} = assessmentendpoints;
const { GET_CATEOGORIESASSESSMENTS } = assessmentreportendpoints;

// Define the API slice
export const assessmentApi = createGlobalApi({
  reducerPath: "assessmentApi",
  tagTypes: ["Assessments", "AssessmentAccess"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    fetchAssessments: builder.query({
      query: (credentials) => ({
        url: GET_ASSESSMENTS,
        method: "POST",
        body: credentials,
      }),
      providesTags: ["Assessments"],
    }),
    createAssessment: builder.mutation({
      query: (formData) => ({
        url: CREATE_ASSESSMENT,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Assessments"],
    }),
    checkAssessmentPassword: builder.mutation({
      query: (formData) => ({
        url: CHECK_ASSESSMENTPASS,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Assessments"],
    }),
    editAssessment: builder.mutation({
      query: (formData) => ({
        url: EDIT_ASSESSMENT,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Assessments"],
    }),
    addAssessmentAccess: builder.mutation({
      query: (formData) => ({
        url: GIVE_ASSESSMENTACCESS,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["AssessmentAccess"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(assessmentApi.util.invalidateTags(["AssessmentAccess"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    editAssessmentAccess: builder.mutation({
      query: (formData) => ({
        url: EDIT_ASSESSMENT_ACCESS,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["AssessmentAccess"],
    }),
    editAssessmentExamSchedule: builder.mutation({
      query: (formData) => ({
        url: EDIT_ASSESSMENTEXAMSCHEDULE_ACCESS,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["AssessmentAccess"],
    }),
    getAssessmentAccessDetails: builder.query({
      query: (formData) => ({
        url: GET_ASSESSMENTS_ACCESSDETAILS,
        method: "POST",
        body: formData,
      }),
      providesTags: ["AssessmentAccess"],
    }),
    deleteAssessmentAccess: builder.mutation({
      query: (formData) => ({
        url: DELETE_ASSESSMENT_ACCESS,
        method: "DELETE",
        body: formData,
      }),
      invalidatesTags: ["AssessmentAccess"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(assessmentApi.util.invalidateTags(["AssessmentAccess"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getAssessmentInstitutions: builder.query({
      query: (formData) => ({
        url: GET_ASSESSMENT_INSTITUTIONS,
        method: "POST",
        body: formData,
      }),
      providesTags: ["AssessmentAccess"],
    }),
    fetchCategoriesAssessments: builder.query({
      query: () => GET_CATEOGORIESASSESSMENTS,
    }),
  }),
});

export const {
  useCreateAssessmentMutation,
  useFetchAssessmentsQuery,
  useEditAssessmentMutation,
  useAddAssessmentAccessMutation,
  useGetAssessmentAccessDetailsQuery,
  useEditAssessmentAccessMutation,
  useEditAssessmentExamScheduleMutation,
  useDeleteAssessmentAccessMutation,
  useGetAssessmentInstitutionsQuery,
  useFetchCategoriesAssessmentsQuery,
  useCheckAssessmentPasswordMutation,
} = assessmentApi;
