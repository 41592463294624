import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveItem } from "../../../redux/slices/SidebarSlice";

import MyTable from "../../common/MyTable";
import EditButton from "../../common/EditButton";
import ComponentLoading from "../../common/ComponentLoading";
import { useGetContestsQuery } from "../../../redux/rtkquery/contest/Contest";
import EditContest from "../../../forms/contest/EditContest";
import Background from "../../common/Background";
import View from "../../common/View";
import CreateContest from "../../../forms/contest/CreateContest";
import DownloadContestReport from "../../../forms/contest/DownloadContestReport";
import Download from "../../common/Download";

const AdminVendorContests = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [downloadReport, setDownloadReport] = useState(false);
  const [currentContest, setCurrentContest] = useState(null);
  console.log("currentContest ", currentContest);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.userslice.role);
  const { data: contests, isFetching: isLoading } = useGetContestsQuery(
    {},
    { suspense: true }
  );
  console.log("data: ", contests);
  useEffect(() => {
    dispatch(setActiveItem(`/${role}/contests`));
  }, [dispatch, role]);

  const handleOpenForm = () => {
    //  setCurrentContest(null);
    setShowCreateForm(true);
  };

  const handleCloseForm = () => {
    setShowEditForm(false);
  };

  const handleEdit = (category) => {
    setCurrentContest(category);
    setShowEditForm(true);
  };

  if (isLoading) return <ComponentLoading />;

  return (
    <div className="flex-1 bg-gray-100 min-h-screen">
      <main className="container mx-auto px-4 py-8">
        <header className="mb-8">
          <div className="flex justify-between items-center text-white bg-gray-600 shadow-lg rounded-lg p-6">
            <h1 className="text-3xl font-bold text-white">Contests</h1>
            {role === "admin" && (
              <button
                onClick={handleOpenForm}
                className="flex items-center px-4 py-2 text-sm text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200 transition-colors duration-200"
              >
                + Create Contest
              </button>
            )}
          </div>
        </header>

        {showEditForm && (
          <Background>
            <EditContest
              onClose={handleCloseForm}
              editContest={currentContest}
            />
          </Background>
        )}
        {showCreateForm && role === "admin" && (
          <Background>
            <CreateContest onClose={() => setShowCreateForm(false)} />
          </Background>
        )}
        {downloadReport && (
          <Background>
            <DownloadContestReport
              onClose={() => setDownloadReport(false)}
              constest={currentContest}
            />
          </Background>
        )}

        <section className="bg-white shadow-lg rounded-lg overflow-hidden">
          <MyTable>
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  starttime
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  endtime
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  totalmarks
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  questions
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {contests?.contests && contests.contests.length > 0 ? (
                contests.contests.map((contest) => (
                  <tr key={contest.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      {contest.title}
                    </td>
                    <td className="px-6 py-4">
                      {contest.description.slice(0, 20)}...
                    </td>
                    <td className="px-6 py-4">
                      {new Intl.DateTimeFormat("en-US", {
                        dateStyle: "medium",
                        timeStyle: "short",
                      }).format(new Date(contest.starttime))}
                    </td>
                    <td className="px-6 py-4">
                      {new Intl.DateTimeFormat("en-US", {
                        dateStyle: "medium",
                        timeStyle: "short",
                      }).format(new Date(contest.endtime))}
                    </td>

                    <td className="px-6 py-4">{contest.totalmarks}</td>
                    <td className="px-6 py-4">{contest.totalnoofquestions}</td>

                    <td className=" flex gap-2 py-4 whitespace-nowrap">
                      <div
                        onClick={() => {
                          window.open(
                            `/contests/${contest?.id}/contestLeaderboard/?page=1`,
                            "_blank"
                          );
                        }}
                      >
                        <View text={"View Reports"} />
                      </div>
                      <div
                        onClick={() => {
                          setCurrentContest(contest);
                          setDownloadReport(true);
                        }}
                      >
                        <Download onClose={() => setDownloadReport(false)} />
                      </div>
                      {role === "admin" && (
                        <div onClick={() => handleEdit(contest)}>
                          <EditButton />
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    className="px-6 py-4 text-center text-gray-500"
                  >
                    No categories found
                  </td>
                </tr>
              )}
            </tbody>
          </MyTable>
        </section>
      </main>
    </div>
  );
};

export default AdminVendorContests;
