import React from "react";
import "./Loading.css";
import gif from "../../assests/gif1.gif";
const Loading = () => {
  return (
    <div className="flex h-screen w-full justify-center items-center">
      <img src={gif} alt="." className="w-[280px] h-[150px]" />
    </div>
  );
};

export default Loading;
