// src/utils/createGlobalApi.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * Creates a global API with default cache settings.
 *
 * @param {object} config - API configuration
 * @returns {object} The created API
 */
const createGlobalApi = (config) => {
  const defaultConfig = {
    baseQuery: fetchBaseQuery({
      baseUrl: config.baseUrl || "",
      credentials: "include",
      mode: "cors",
    }),
    keepUnusedDataFor: 3600,
    endpoints: (builder) => ({}),
  };

  return createApi({
    ...defaultConfig,
    ...config, // Override defaults with specific API config
  });
};

export default createGlobalApi;
