import React, { useEffect, useRef, useState } from "react";
import {
  Shield,
  Lightbulb,
  Wifi,
  BellOff,
  Camera,
  Chrome,
  MessageSquare,
} from "lucide-react";
import VideoPlayer from "../../../common/VideoPlayer";
import { useCreateAssessmentAttemptMutation } from "../../../../redux/rtkquery/assessment/StudentAssessment";
import { useLocation, useNavigate, useParams } from "react-router";
import toast from "react-hot-toast";
import MyButton from "../../../common/MyButton";
import Skelo from "../../../../assests/assesify.png";

export const ExamSetupWizard = () => {
  const [step, setStep] = useState(1);
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [faceDetected, setFaceDetected] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const handleEnableCamera = () => {
    setCameraEnabled(true);
  };
  const state = useLocation();
  const proctoring = state?.state?.proctoring;
  const marks = state?.state?.marks;
  const duration = state?.state?.duration;
  const { id } = useParams();
  const handleFaceCapture = (captured) => {
    setFaceDetected(captured);
  };
  const navigate = useNavigate();
  const [createAssessmentAttempt] = useCreateAssessmentAttemptMutation();

  const handleStart = async () => {
    try {
      if (termsAccepted) {
        const response = await createAssessmentAttempt({
          assessmentid: id,
        }).unwrap();

        if (response.success) {
          navigate(`/assessment/${response?.attemptid}/${id}`, {
            state: { start: true, assessmentid: id },
          });
        }
      } else {
        toast.error("Please accept terms and conditions");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const renderStep1 = () => (
    <div className="flex max-h-screen">
      {/* Camera Section */}
      <div className=" flex-1 flex items-center justify-center">
        {!cameraEnabled ? (
          <button
            onClick={handleEnableCamera}
            className="bg-violet-600 text-white px-6 py-3 rounded-lg hover:bg-violet-700 transition text-lg"
          >
            Enable Camera and Capture Face
          </button>
        ) : (
          <div className="space-y-6">
            <VideoPlayer type="capture" onFaceCapture={handleFaceCapture} />
            {!faceDetected && (
              <div className=" border border-yellow-300 text-yellow-800 rounded-lg p-3">
                <p className="text-lg font-medium">
                  Ensure only your face is visible in the camera. Once ready,
                  click "Capture Face."
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      {/* Content Section */}
      <div className="bg-white flex-1 flex flex-col items-center justify-center ">
        <div className="mb-8">
          <img src={Skelo} alt="Skelo" className="h-8" />
        </div>
        <h2 className="text-xl font-bold text-center mb-6">
          Camera and Microphone Setup
        </h2>
        <div className="space-y-6 bg-gray-50 p-6 rounded-lg w-full max-w-md">
          <h3 className="text-lg font-semibold text-gray-700">Instructions:</h3>
          <ul className="list-disc pl-6 text-gray-800 space-y-4">
            <li>
              You must allow access to your camera and microphone to start this
              test.
            </li>
            <li>
              After granting camera access, you are required to capture a clear
              selfie photo.
            </li>
            <li>
              Use the latest version of Google Chrome for the best experience.
            </li>
            <li>
              If you face camera issues, try using Incognito mode in Google
              Chrome.
            </li>
            <li>
              Ensure your face is well-lit and clearly visible within the camera
              frame.
            </li>
            <li>
              Avoid wearing accessories like hats or sunglasses that may
              obstruct your face.
            </li>
          </ul>
          <div className="mt-6 text-center">
            <button
              onClick={() => setStep(step + 1)}
              disabled={!faceDetected}
              className="bg-violet-600 text-white w-full py-3 rounded-lg hover:bg-violet-700 transition text-lg disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Proceed to Next Step
            </button>
          </div>
        </div>
      </div>
          
    </div>
  );

  const renderStep2 = () => {
    return (
      <div className="bg-white w-[90%] p-8 rounded-lg shadow-lg mx-auto  h-full overflow-y-auto">
        <h2 className="text-2xl font-semibold mb-6 text-center">
          Exam Instructions
        </h2>
        <div className="flex flex-col space-y-6">
          <p className="text-lg text-gray-700">
            Please read the following instructions carefully before starting the
            exam:
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-gray-800">
            <div className="flex items-start">
              <span className="font-bold text-lg mr-2">•</span>
              <p className="text-base">
                You have {duration} minutes to complete the exam.
              </p>
            </div>
            <div className="flex items-start">
              <span className="font-bold text-lg mr-2">•</span>
              <p className="text-base">
                Total marks: {marks}. Each question is worth a specific number
                of marks.
              </p>
            </div>

            <div className="flex items-start">
              <span className="font-bold text-lg mr-2">•</span>
              <p className="text-base">
                No electronic devices, including phones, are allowed during the
                exam.
              </p>
            </div>
            <div className="flex items-start">
              <span className="font-bold text-lg mr-2">•</span>
              <p className="text-base">
                If you are caught cheating, you will be disqualified from the
                exam.
              </p>
            </div>
            <div className="flex items-start">
              <span className="font-bold text-lg mr-2">•</span>
              <p className="text-base">
                Tab switching is not allowed. Your browser activity will be
                monitored.
              </p>
            </div>
            <div className="flex items-start">
              <span className="font-bold text-lg mr-2">•</span>
              <p className="text-base">
                Face detection technology will ensure only you are taking the
                exam.
              </p>
            </div>
            <div className="flex items-start">
              <span className="font-bold text-lg mr-2">•</span>
              <p className="text-base">
                The exam will terminate if abnormal gaze behavior exceeds its
                instances (e.g., looking away or excessive blinking).
              </p>
            </div>
            <div className="flex items-start">
              <span className="font-bold text-lg mr-2">•</span>
              <p className="text-base">
                If your head position changes excessively, the exam will be
                terminated.
              </p>
            </div>
            <div className="flex items-start">
              <span className="font-bold text-lg mr-2">•</span>
              <p className="text-base">
                Ensure you have a stable internet connection to avoid
                disruptions.
              </p>
            </div>
            <div className="flex items-start">
              <span className="font-bold text-lg mr-2">•</span>
              <p className="text-base">
                If the system detects more than one face , the exam will
                terminate, indicating potential cheating.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 flex justify-between text-center">
          <button
            onClick={() => setStep(step + 1)}
            // disabled={!faceDetected}
            className="bg-violet-600 text-white px-6 py-2 rounded-lg hover:bg-violet-700 transition text-lg disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Proceed to Next Step »
          </button>
        </div>
      </div>
    );
  };

  const renderStep3 = () => (
    <div className="space-y-6">
      <div className="flex items-center space-x-2">
        <Shield className="text-blue-600" />
        <h2 className="text-xl font-semibold">Things to note</h2>
      </div>

      <div className="space-y-3">
        <div className="flex items-center space-x-2 text-green-600">
          <Lightbulb size={20} />
          <p>Sit in a quite well-lit room with no one else present</p>
        </div>
        <div className="flex items-center space-x-2 text-green-600">
          <Wifi size={20} />
          <p>Have a reliable internet connection</p>
        </div>
        <div className="flex items-center space-x-2 text-green-600">
          <BellOff size={20} />
          <p>
            Disable all the desktop/mobile notifications throughout the test
          </p>
        </div>
        <div className="flex items-center space-x-2 text-red-600">
          <Camera size={20} />
          <p>Do not leave the camera preview</p>
        </div>
        <div className="flex items-center space-x-2 text-red-600">
          <Chrome size={20} />
          <p>Do not leave your web browser</p>
        </div>
        <div className="flex items-center space-x-2 text-red-600">
          <MessageSquare size={20} />
          <p>Do not speak unless instructed</p>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="terms"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
            className="rounded border-gray-300"
          />
          <label htmlFor="terms" className="text-sm">
            I have read and understood the instructions. I declare that I am not
            in possession of any prohibited material with me. I agree that in
            case of not adhering to the instructions, I shall be liable to be
            debarred from this Test and/or to disciplinary action, which may
            include ban from future Tests / Examinations.
          </label>
        </div>

        <MyButton
          onClick={async () => {
            await handleStart();
          }}
          disabled={!termsAccepted}
          className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Proceed
        </MyButton>
      </div>
      <div className="mt-8 flex justify-between text-center">
        <button
          onClick={() => setStep(step - 1)}
          disabled={!faceDetected}
          className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition text-lg disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Back
        </button>
      </div>
    </div>
  );
  useEffect(() => {
    if (!proctoring?.isfaceproctoring) {
      setStep(2);
    }
  }, [proctoring]);
  return (
    <div className=" w-full overflow-y-auto h-screen mx-auto ">
      <div className=" mt-9 ml-9">
        <p className=" text-gray-600">
          Step [ {proctoring?.isfaceproctoring ? step : step - 1} /{" "}
          {proctoring?.isfaceproctoring ? 3 : 2} ]
        </p>
      </div>

      <div>
        <div className="p-6">
          {step === 1 && proctoring?.isfaceproctoring && renderStep1()}
          {step === 2 && renderStep2()}
          {step === 3 && renderStep3()}
        </div>
      </div>
    </div>
  );
};
