import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation, matchPath } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "./redux/slices/UserSlice";
import { useLayoutEffect } from "react";
import { Toaster } from "react-hot-toast";
import Loading from "./components/common/Loading";

import ProtectedRoute from "./rolebasedroutes/ProtectedRoute";
import ProtectedRoute2 from "./rolebasedroutes/ProtectedRoute2";
import AssignmentSubCategory from "./components/dashboards/adminvendor/AssignmentSubCategory";
import AssignmentCategoryAccess from "./components/dashboards/admin/AssignmentCatgeoryAccess";
import NewAssignment from "./components/dashboards/student/Assignment/AssignmentCategories";
import AssignmentSubCategories from "./components/dashboards/student/Assignment/AssignmentSubCategories";
import { ExamSetupWizard } from "./components/dashboards/student/assessments/ExamProctoring";
import CreateContest from "./forms/contest/CreateContest";

import WeeklyContests from "./components/dashboards/student/contest/WeeklyContests";
import ContestDetails from "./components/dashboards/student/contest/ContestDetails";
import ContestRanking from "./components/reports/contest/ContestRanking";
import AdminVendorContests from "./components/dashboards/adminvendor/Contests";
import LiveRoom from "./components/LiveRoom";
//using lazy
const StudentLogin = lazy(() => import("./components/login/StudentLogin"));
const AdminDashboard = lazy(() =>
  import("./components/dashboards/AdminDashboard")
);
const StudentDashboard = lazy(() =>
  import("./components/dashboards/StudentDashboard")
);
const VendorDashboard = lazy(() =>
  import("./components/dashboards/VendorDashboard")
);
const NotFoundPage = lazy(() => import("./NotFoundPage"));
const Landing = lazy(() => import("./Landing/Landing"));
const InstitutionDashboard = lazy(() =>
  import("./components/dashboards/InstitutionDashboard")
);
const StudentCode = lazy(() =>
  import("./components/dashboards/student/Code/StudentCode")
);
const CreateVendor = lazy(() =>
  import("./components/dashboards/admin/CreateVendor")
);
const AllLogin = lazy(() => import("./components/login/AllLogin"));
const AdminVendorInstitution = lazy(() =>
  import("./components/dashboards/adminvendor/AdminVendorInstitution")
);
const AdminVendorInstitutionBatchYears = lazy(() =>
  import(
    "./components/dashboards/adminvendorinstitution/AdminVendorInstitutionBatchYears"
  )
);
const AdminVendorInstitutionBatches = lazy(() =>
  import(
    "./components/dashboards/adminvendorinstitution/AdminVendorInstitutionBatches"
  )
);
const AdminVendorInstitutionUsers = lazy(() =>
  import(
    "./components/dashboards/adminvendorinstitution/AdminVendorInstitutionUsers"
  )
);
const SearchUser = lazy(() =>
  import("./components/dashboards/adminvendorinstitution/SearchUser")
);
const AdminVendorCategory = lazy(() =>
  import("./components/dashboards/adminvendor/AdminVendorCategory")
);
const AdminVendorSubCategory = lazy(() =>
  import("./components/dashboards/adminvendor/AdminVendorSubCategory")
);
const AdminVendorInstitutionSubject = lazy(() =>
  import(
    "./components/dashboards/adminvendorinstitution/AdminVendorInstitutionSubject"
  )
);
const AdminVendorInstitutionChapter = lazy(() =>
  import(
    "./components/dashboards/adminvendorinstitution/AdminVendorInstitutionChapter"
  )
);
const MCQ = lazy(() =>
  import("./components/dashboards/adminvendorinstitution/MCQ")
);
const FillInTheBlanks = lazy(() =>
  import("./components/dashboards/adminvendorinstitution/FillInTheBlanks")
);
const Coding = lazy(() =>
  import("./components/dashboards/adminvendorinstitution/Coding")
);
const AccessQB = lazy(() =>
  import("./components/dashboards/adminvendor/AcessQB")
);
const AssessmentCategory = lazy(() =>
  import("./components/dashboards/adminvendor/AssessmentCategory")
);
const CreateAssesment = lazy(() =>
  import("./forms/assessment/CreateAssesment")
);
const AssessmentComponent = lazy(() =>
  import("./components/dashboards/adminvendor/Assessment")
);
const AssessmentAccess = lazy(() =>
  import("./components/dashboards/adminvendor/AssessmentAccess")
);
const AssignmentCategory = lazy(() =>
  import("./components/dashboards/adminvendor/AssignmentCategory")
);
const CreateAssignmentForm = lazy(() =>
  import("./forms/assignment/CreateAssignment")
);
const AssignmentComponent = lazy(() =>
  import("./components/dashboards/adminvendor/Assignment")
);
const AssignmentAccess = lazy(() =>
  import("./components/dashboards/adminvendor/AssignmentAccess")
);
const Exam = lazy(() =>
  import("./components/dashboards/student/assessments/Exam")
);
const AssessmentList = lazy(() =>
  import("./components/dashboards/student/assessments/AssessmentList")
);

const ExamComponent = lazy(() =>
  import("./components/dashboards/student/assessments/ExamComponent")
);
const Compiler = lazy(() =>
  import("./components/dashboards/student/Code/Compiler")
);
const ProblemSolving = lazy(() =>
  import("./components/dashboards/student/DSA/ProblemSolving")
);
const GlobalDSA = lazy(() =>
  import("./components/dashboards/adminvendor/GlobalDSA")
);
const SideNav = lazy(() => import("./sidebar/SideNav"));
const AssignmentList = lazy(() =>
  import("./components/dashboards/student/Assignment/AssignmentList")
);
const AssignmentExam = lazy(() =>
  import("./components/dashboards/student/Assignment/AssignmentExam")
);

const AssessmentReport = lazy(() =>
  import("./components/reports/assessment/AssessmentReport")
);
const StudentAssessmentReport = lazy(() =>
  import("./components/reports/assessment/StudentAssessmentReport")
);
const AssessmentSummaryReport = lazy(() =>
  import("./components/reports/assessment/AssessmentSummaryReport")
);
const StudentDSAReports = lazy(() =>
  import("./components/dashboards/student/DSA/StudentDSAReports")
);
const DSAReport = lazy(() =>
  import("./components/dashboards/adminvendorinstitution/DSAReport")
);
const DSAReportSingle = lazy(() =>
  import("./components/dashboards/adminvendorinstitution/DSAReportSingle")
);
const AssignmentReports = lazy(() =>
  import("./components/reports/assignment/AssignmentReports")
);
const AssessmentView = lazy(() =>
  import("./components/dashboards/adminvendor/AssessmentView")
);
const AssignmnetSingleReport = lazy(() =>
  import("./components/reports/assignment/AssignmentSingleReport")
);
const Billing = lazy(() =>
  import("./components/dashboards/adminvendorinstitution/Billing")
);
const AssessmentAttemptAnswers = lazy(() =>
  import("./components/dashboards/student/assessments/AssessmentAttemptAnswers")
);
const PasswordResetPage = lazy(() =>
  import("./components/login/PasswordResetPage")
);
const StudentAssessment = lazy(() =>
  import("./components/reports/assessment/StudentAssessment")
);
const StudentAssignmentReports = lazy(() =>
  import("./components/reports/assignment/StudentAssignment")
);

const App = () => {
  const editorConfig = {
    // Add any editor configuration you want
    onError(error) {
      throw error;
    },
  };
  const dispatch = useDispatch();
  const role = useSelector((state) => state.userslice.role);
  const vendortype = useSelector((state) => state.userslice.vendortype);
  useLayoutEffect(() => {
    async function fetchuser() {
      dispatch(userLogin());
    }
    fetchuser();
  }, [dispatch]);
  const { isAuthenticated, loading } = useSelector((state) => {
    return state.userslice;
  });

  const authRoutes = (
    <>
      <Route path="/" element={<Landing />} />
      <Route path="/liveroom" element={<LiveRoom />} />
      <Route
        path="/login"
        element={
          <ProtectedRoute2
            component={StudentLogin}
            component2={StudentDashboard}
            allowedRoles={["student"]}
            redirectPath1="/dashboard"
            redirectPath2="/login"
          />
        }
      />
      <Route
        path="/reset-password/:token/:email"
        element={<PasswordResetPage />}
      />
      {/* Admin/Vendor/Institution Login Routes */}
      <Route
        path="/admin"
        element={
          <div className="NoScroll w-screen h-screen">
            <ProtectedRoute2
              component={AllLogin}
              component2={AdminDashboard}
              allowedRoles={["admin"]}
              redirectPath1="/admin/dashboard"
              redirectPath2="/admin"
            />
          </div>
        }
      />
      <Route
        path="/vendor"
        element={
          <div className="NoScroll w-screen h-screen">
            <ProtectedRoute2
              component={AllLogin}
              allowedRoles={["vendor"]}
              redirectPath1="/vendor/dashboard"
              redirectPath2="/vendor"
            />
          </div>
        }
      />
      <Route
        path="/institution"
        element={
          <div className="NoScroll w-screen h-screen">
            <ProtectedRoute2
              component={AllLogin}
              allowedRoles={["institution"]}
              redirectPath1="/institution/dashboard"
              redirectPath2="/institution"
            />
          </div>
        }
      />
    </>
  );

  // Student routes
  const studentRoutes = (
    <>
      {/* <Route path="/leetcode" element={<Leetcode />} />
      <Route path="/code" element={<StudentCode1 />} /> */}
      <Route
        path="/assessment/:id/:assessmentid"
        element={
          <div className="w-screen h-screen overflow-hidden">
            <ExamComponent>
              <ProtectedRoute
                allowedRoles={["student"]}
                component={Exam}
                redirectPath={`/login`}
              />{" "}
            </ExamComponent>
          </div>
        }
      />
      <Route
        path="/assessment/:id/instructions"
        element={
          <ProtectedRoute
            allowedRoles={["student"]}
            component={ExamSetupWizard}
            redirectPath={`/login`}
          />
        }
      />

      <Route
        path="/student/assessments"
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <ProtectedRoute
              allowedRoles={["student"]}
              component={AssessmentList}
              redirectPath={`/login`}
            />
          </div>
        }
      />
      <Route
        path="/students/assignmentcategories"
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <ProtectedRoute
                allowedRoles={["student"]}
                component={NewAssignment}
                redirectPath={`/login`}
              />
            </div>
          </>
        }
      />
      <Route
        path="/assignment/:id"
        element={
          <ProtectedRoute
            allowedRoles={["student"]}
            component={AssignmentExam}
            redirectPath={`/login`}
          />
        }
      />
      <Route
        path="/assignmentsubcategory/:id/:name"
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <ProtectedRoute
                allowedRoles={["student"]}
                component={AssignmentSubCategories}
                redirectPath={`/login`}
              />
            </div>
          </>
        }
      />
      <Route
        path="/assignments/:id/:name"
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <ProtectedRoute
                allowedRoles={["student"]}
                component={AssignmentList}
                redirectPath={`/login`}
              />
            </div>
          </>
        }
      />
      <Route
        path="/dashboard"
        element={
          <>
            <div className="overflow-y-scroll NoScroll h-screen  w-full  overflow-x-auto">
              <ProtectedRoute
                allowedRoles={["student"]}
                component={StudentDashboard}
                redirectPath={`/login`}
              />
            </div>
          </>
        }
      />
      <Route
        path="/students/problems"
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <ProtectedRoute
              allowedRoles={["student"]}
              component={ProblemSolving}
              redirectPath={`/login`}
            />
          </div>
        }
      />
      <Route
        path="students/code"
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <ProtectedRoute
              allowedRoles={["student"]}
              component={Compiler}
              redirectPath={`/login`}
            />
          </div>
        }
      />
      <Route
        path="/students/problem/:id"
        element={
          <ProtectedRoute
            allowedRoles={["student"]}
            component={StudentCode}
            redirectPath={`/login`}
          />
        }
      />
      <Route
        path="/students/assessmentreports"
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <ProtectedRoute
                allowedRoles={["student"]}
                component={StudentAssessment}
                redirectPath={`/login`}
              />
            </div>
          </>
        }
      />
      <Route
        path="/students/assignmentreports"
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <ProtectedRoute
              allowedRoles={["student"]}
              component={StudentAssignmentReports}
              redirectPath={`/login`}
            />
          </div>
        }
      />
      <Route
        path="/contests"
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <ProtectedRoute
              allowedRoles={["student"]}
              component={WeeklyContests}
              redirectPath={`/login`}
            />
          </div>
        }
      />
      <Route
        path="/contests/:id"
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <ProtectedRoute
              allowedRoles={["student"]}
              component={ContestDetails}
              redirectPath={`/login`}
            />
          </div>
        }
      />

      <Route
        path="/contests/:contestid/questions/:id"
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <ProtectedRoute
              allowedRoles={["student"]}
              component={StudentCode}
              redirectPath={`/login`}
            />
          </div>
        }
      />
    </>
  );

  // Admin dashboard routes
  const adminDashboardRoutes = (
    <>
      <Route
        path="/admin/dashboard"
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <ProtectedRoute
              allowedRoles={["admin"]}
              component={AdminDashboard}
              redirectPath={`/admin`}
            />
          </div>
        }
      />

      <Route
        path="/admin/vendor"
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <ProtectedRoute
                component={CreateVendor}
                allowedRoles={["admin"]}
                redirectPath="/admin"
              />
            </div>
          </>
        }
      />
      <Route
        path="/admin/assignmentcategoryaccess"
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <ProtectedRoute
                component={AssignmentCategoryAccess}
                allowedRoles={["admin"]}
                redirectPath="/admin"
              />
            </div>
          </>
        }
      />

      <Route
        path="/createcontest"
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <ProtectedRoute
                component={CreateContest}
                allowedRoles={["admin"]}
                redirectPath="/admin"
              />
            </div>
          </>
        }
      />
    </>
  );

  // Vendor dashboard routes
  const vendorDashboardRoutes = (
    <Route
      path="/vendor/dashboard"
      element={
        <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
          <ProtectedRoute
            allowedRoles={["vendor"]}
            component={VendorDashboard}
            redirectPath={`/vendor`}
          />
        </div>
      }
    />
  );

  // Institution dashboard routes
  const institutionDashboardRoutes = (
    <Route
      path="/institution/dashboard"
      element={
        <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
          <ProtectedRoute
            allowedRoles={["institution"]}
            component={InstitutionDashboard}
            redirectPath={`/institution/`}
          />
        </div>
      }
    />
  );

  // Shared routes between admin, vendor, and institution
  const sharedAdminVendorInstitutionRoutes = [
    "admin",
    "vendor",
    "institution",
  ].includes(role) && (
    <>
      <Route
        path={`${role}/reports/assessment`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AssessmentReport />
          </div>
        }
      />
      <Route
        path={`${role}/dsa`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <GlobalDSA />
          </div>
        }
      />
      <Route
        path={`/${role}/reports/dsa`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <DSAReport />
          </div>
        }
      />
      <Route
        path={`/reports/dsa/:id`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <DSAReportSingle />
          </div>
        }
      />
      <Route
        path={`/${role}/batchyears`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AdminVendorInstitutionBatchYears />
          </div>
        }
      />
      <Route
        path={`/${role}/batches`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AdminVendorInstitutionBatches />
          </div>
        }
      />
      <Route
        path={`${role}/users`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <AdminVendorInstitutionUsers />
            </div>
          </>
        }
      />
      <Route
        path={`${role}/searchusers`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <SearchUser />
            </div>
          </>
        }
      />
      <Route
        path={`/${role}/subject`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AdminVendorInstitutionSubject />
          </div>
        }
      />
      <Route
        path={`/${role}/chapters`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AdminVendorInstitutionChapter />
          </div>
        }
      />
      <Route
        path={`${role}/mcq`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <MCQ />
          </div>
        }
      />
      <Route
        path={`${role}/fillintheblanks`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <FillInTheBlanks />
            </div>
          </>
        }
      />
      <Route
        path={`${role}/coding`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <Coding />
            </div>
          </>
        }
      />
      <Route
        path={`${role}/assignmentreports`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <AssignmentReports />
            </div>
          </>
        }
      />
      <Route
        path={`/${role}/reports/summaryassessmentreport/:id`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <AssessmentSummaryReport />
            </div>
          </>
        }
      />
      <Route
        path={`${role}/billing`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <Billing />
          </div>
        }
      />
    </>
  );

  // Shared routes between admin and vendor
  const sharedAdminVendorRoutes = ["admin", "vendor"].includes(role) && (
    <>
      <Route
        path={`/${role}/institution`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <ProtectedRoute
                allowedRoles={["vendor", "admin"]}
                component={AdminVendorInstitution}
                redirectPath={`/${role}`}
              />
            </div>
          </>
        }
      />

      <Route
        path={`${role}/category`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AdminVendorCategory />
          </div>
        }
      />
      <Route
        path={`/${role}/subcategory`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AdminVendorSubCategory />
          </div>
        }
      />
      <Route
        path={`/${role}/qb/access`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AccessQB />
          </div>
        }
      />
      <Route
        path={`${role}/createassessment`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <CreateAssesment />
          </div>
        }
      />
      <Route
        path={`${role}/assessment`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <AssessmentComponent />
            </div>
          </>
        }
      />
      <Route
        path={`${role}/assessmentcategory`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AssessmentCategory />
          </div>
        }
      />
      <Route path={`${role}/assessmentview/:id`} element={<AssessmentView />} />
      <Route
        path={`${role}/assessmentaccess`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AssessmentAccess />
          </div>
        }
      />
      <Route
        path={`${role}/assignmentcategory`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AssignmentCategory />
          </div>
        }
      />
      <Route
        path={`/${role}/assignmentsubcategory`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AssignmentSubCategory />
          </div>
        }
      />
      <Route
        path={`${role}/createassignment`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <CreateAssignmentForm />
            </div>
          </>
        }
      />
      <Route
        path={`${role}/assignment/:id`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AssignmentComponent />
          </div>
        }
      />
      <Route path={`${role}/assignmentview/:id`} element={<AssignmentExam />} />
      <Route
        path={`${role}/assignmentaccess`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <AssignmentAccess />
            </div>
          </>
        }
      />
      <Route
        path={`${role}/contests`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <AdminVendorContests />
            </div>
          </>
        }
      />
    </>
  );

  // Common routes for all roles
  const commonRoleRoutes = [
    "admin",
    "vendor",
    "institution",
    "student",
  ].includes(role) && (
    <>
      <Route
        path={`${
          role !== "student" ? role : "/"
        }/reports/assessment/:id/:studentid?`}
        element={
          <>
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <StudentAssessmentReport />
            </div>
          </>
        }
      />

      {isAuthenticated && (
        <Route
          path={`/reports/dsa`}
          element={
            <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
              <StudentDSAReports />
            </div>
          }
        />
      )}
      {isAuthenticated && (
        <Route
          path={`/assignment/report/:id/:studentid?`}
          element={
            <>
              <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
                <AssignmnetSingleReport />
              </div>
            </>
          }
        />
      )}

      <Route
        path={`/${role}/assessmentattempt/solutions/:id/`}
        element={
          <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
            <AssessmentAttemptAnswers />
          </div>
        }
      />
    </>
  );
  const location = useLocation();

  const excludedRoutes = [
    "/login",
    "/contests/:contestid/questions/:id",
    "/assignment/:id",
    "/",
    "/students/problem/:id",
    "/institution",
    "/vendor",
    "/admin",
    "/assignment/report/:id/:studentid",
    "/assignment/report/:id",
    `/${role}/assessmentattempt/solutions/:id/`,
    "/assessment/:id/:assessmentid",
    "/assessmentproctoring/:id",
    "/reset-password/:token/:email",
    `${role}/assignmentview/:id`,
    `${role}/assessmentview/:id`,
    `${role !== "student" ? role : "/"}/reports/assessment/:id/:studentid?`,
    "/assessment/:id/instructions",
  ];

  const isSideNavVisible = !excludedRoutes.some((pattern) =>
    matchPath(pattern, location.pathname)
  );
  // Additional routes
  const additionalRoutes = (
    <>
      <Route path="*" element={<NotFoundPage />} />
    </>
  );

  return loading ? (
    <Loading />
  ) : (
    <>
      <div>
        <Toaster
          toastOptions={{
            duration: 3000,
          }}
          position="top-right"
        />
      </div>

      <div className="flex w-full">
        {isAuthenticated && isSideNavVisible && <SideNav />}
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route
              path="/contests/:contestid/contestLeaderboard/"
              element={
                <div className="overflow-y-scroll h-screen  w-full  overflow-x-auto">
                  <ContestRanking />
                </div>
              }
            />
            {authRoutes}
            {studentRoutes}
            {adminDashboardRoutes}
            {vendorDashboardRoutes}
            {institutionDashboardRoutes}
            {sharedAdminVendorInstitutionRoutes}
            {sharedAdminVendorRoutes}
            {commonRoleRoutes}
            {additionalRoutes}
          </Routes>
        </Suspense>
      </div>
    </>
  );
};

export default App;
