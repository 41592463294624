import React, { useEffect, useState } from 'react';
import { useSocket } from '../hooks/useSocket';

export function Editor({ roomId, isHost }) {
  const [code, setCode] = useState('');
  const socket = useSocket(roomId);

  useEffect(() => {
    if (!socket) return;

    const handleCodeUpdate = (newCode) => {
      if (!isHost) {
        setCode(newCode);
      }
    };

    socket.on('code-update', handleCodeUpdate);

    return () => {
      socket.off('code-update', handleCodeUpdate);
    };
  }, [socket, isHost]);

  const handleCodeChange = (e) => {
    const newCode = e.target.value;
    setCode(newCode);

    if (isHost && socket) {
      socket.emit('code-update', { roomId, code: newCode });
    }
  };

  return React.createElement(
    'div',
    { className: 'w-full h-full' },
    React.createElement('textarea', {
      value: code,
      onChange: handleCodeChange,
      disabled: !isHost,
      className:
        'w-full h-[500px] bg-gray-900 text-gray-100 p-4 font-mono text-sm resize-none rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none',
      placeholder: isHost
        ? 'Start typing your code here...'
        : 'Waiting for host to type...',
    })
  );
}
