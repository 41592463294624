import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../api/env";

import {
  adminvendorinstitutionendpoints,
  adminvendortypeendpoints,
} from "../../services/Apis";
import createGlobalApi from "./createGlobalApi";
const { CREATE_BATCHYEAR_API, EDIT_BATCHYEAR_API } = adminvendortypeendpoints;
const { GET_BATCHYEARS_API } = adminvendorinstitutionendpoints;
// Define the API service
export const batchYearsApi = createGlobalApi({
  reducerPath: "batchYearsApi",
  tagTypes: ["BatchYears"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }), // Adjust the base URL as needed
  endpoints: (builder) => ({
    getBatchYears: builder.query({
      query: (credentials) => ({
        url: GET_BATCHYEARS_API,
        method: "POST",
        body: credentials,
      }),
      providesTags: ["BatchYears"],
    }),
    createBatchYear: builder.mutation({
      query: (batchYearData) => ({
        url: CREATE_BATCHYEAR_API,
        method: "POST",
        body: batchYearData,
      }),
      invalidatesTags: ["BatchYears"], // Invalidate relevant tags
    }),
    editBatchYear: builder.mutation({
      query: (batchYearData) => ({
        url: EDIT_BATCHYEAR_API,
        method: "PUT",
        body: batchYearData,
      }),
      invalidatesTags: ["BatchYears"], // Invalidate relevant tags
    }),
  }),
});

// Export hooks for the API endpoints
export const {
  useGetBatchYearsQuery,
  useCreateBatchYearMutation,
  useEditBatchYearMutation,
} = batchYearsApi;
