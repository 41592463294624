import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

export function useSocket(roomId) {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io('http://localhost:8060');
    
    newSocket.on('connect', () => {
      console.log('Connected to server');
      newSocket.emit('join-room', { roomId });
    });

    newSocket.on('connect_error', (error) => {
      console.error('Connection error:', error);
    });

    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [roomId]);

  return socket;
}
