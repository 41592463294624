import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/Axios";
const initialState = {
  userData: "",
  loading: false,
  success: false,
  error: "",
};

export const AuthSignin = createAsyncThunk(
  "all/signin",
  async (credentials, role) => {
    credentials.subdomain = window.location.hostname.split(".")[0];
    const response = await api.post(`/verify/loginUser`, credentials);
    return response.data;
  }
);
export const AuthStudentSignin = createAsyncThunk(
  "student/signin",
  async (credentials, role) => {
    credentials.subdomain = window.location.hostname.split(".")[0];
    const response = await api.post(`/student/login`, credentials);
    return response.data;
  }
);

const AuthSlice = createSlice({
  name: "adminauth",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(AuthSignin.pending, (state) => {
        state.loading = true;
      })
      .addCase(AuthSignin.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload.userData;
        state.success = action.payload.success;
      })
      .addCase(AuthSignin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.log(state);
      })

      .addCase(AuthStudentSignin.pending, (state) => {
        state.loading = true;
      })
      .addCase(AuthStudentSignin.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload.userData;
        state.success = action.payload.success;
      })
      .addCase(AuthStudentSignin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.log(state);
      });
  },
});

export default AuthSlice.reducer;
