"use client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFetchContestProblemsQuery,
  useRegisterContestMutation,
} from "../../../../redux/rtkquery/contest/Contest";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  ArrowLeft,
  Clock,
  Star,
  Award,
  ChevronRight,
  AlertCircle,
  Check,
} from "lucide-react";
import MyButton from "../../../common/MyButton";
import ContestRules from "./ContestRules";
import toast from "react-hot-toast";
export default function ContestDetails() {
  const router = useNavigate();
  const params = useParams();
  const id = params.id;
  const { data, isFetching, refetch } = useFetchContestProblemsQuery(id);
  const [problems, setProblems] = useState([]);
  const [title, setTitle] = useState("");
  const [starttime, setStarttime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(null);
  const [endtime, setEndtime] = useState(null);
  const [isContestLive, setIsContestLive] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  console.log("data", data);
  useEffect(() => {
    if (data) {
      setTitle(data?.title);
      setProblems(data?.problems);
      setStarttime(data?.starttime);
      setEndtime(data?.endtime);
      setIsRegistered(data?.isRegistered);
    }
  }, [data]);
  const navigate = useNavigate();
  useEffect(() => {
    if (isContestLive && new Date(endtime) > new Date()) {
      const contestEndTime = new Date(endtime);
      setEndtime(contestEndTime);
      setIsContestLive(new Date() < contestEndTime);

      const intervalId = setInterval(() => {
        const now = new Date();
        const difference = contestEndTime - now;
        setTimeLeft(difference);

        if (difference <= 0) {
          toast.success("Contest has ended.");
          navigate(-1);
          clearInterval(intervalId);
        }
      }, 1000);

      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [isContestLive]);

  useEffect(() => {
    let intervalId;

    if (starttime) {
      const checkContestStatus = async () => {
        const now = new Date();
        const startTimeDate = new Date(starttime);

        if (now >= startTimeDate) {
          setIsContestLive(true);

          clearInterval(intervalId); // Clear the interval after checking once
        } else {
          setIsContestLive(false);
          setTimeLeft(startTimeDate.getTime() - now.getTime());
        }
      };

      // Initial check
      checkContestStatus();

      // Set up interval to check every second if the contest has not started
      intervalId = setInterval(checkContestStatus, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [starttime]);

  const [registerContest, { isLoading: isRegistering }] =
    useRegisterContestMutation();
  const handleRegister = async () => {
    try {
      const response = await registerContest({ contestid: id }).unwrap();
      setIsRegistered(true);
      if (new Date(starttime) <= new Date()) {
        await refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };
  if (isFetching) {
    return (
      <div className="min-h-screen h-screen overflow-y-auto w-full bg-gray-100 p-6">
        <div className="max-w-7xl mx-auto">
          {/* Back Button Skeleton */}
          <Skeleton width={200} height={40} className="mb-6" />

          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
            <div className="lg:col-span-3 space-y-6">
              <div className="bg-white rounded-lg p-6 shadow-lg">
                {/* Contest Title and Time Skeleton */}
                <div className="mb-6">
                  <Skeleton width={300} height={36} className="mb-2" />
                  <Skeleton width={250} height={24} />
                </div>

                {/* Rules Skeleton */}
                <section>
                  <Skeleton width={200} height={24} className="mb-4" />
                  <div className="bg-gray-100 rounded p-4">
                    {[...Array(3)].map((_, index) => (
                      <div key={index} className="mb-2">
                        <Skeleton width="100%" height={20} />
                      </div>
                    ))}
                  </div>
                </section>

                {/* Questions Skeleton */}
                <section className="mt-6">
                  <Skeleton width={200} height={24} className="mb-4" />
                  <div className="bg-gray-100 rounded-lg divide-y divide-gray-300">
                    {[...Array(3)].map((_, index) => (
                      <div key={index} className="p-4">
                        <Skeleton width="100%" height={20} />
                        <Skeleton width="80%" height={16} className="mt-2" />
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            </div>

            {/* Global Ranking Skeleton */}
            <div className="lg:col-span-1">
              <div className="bg-white rounded-lg p-6 shadow-lg">
                <Skeleton width={200} height={24} className="mb-4" />
                {[...Array(5)].map((_, index) => (
                  <div key={index} className="mb-2 flex items-center">
                    <Skeleton circle width={40} height={40} className="mr-4" />
                    <Skeleton width={100} height={20} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex gap-6">
      {/* Contest Section */}

      <div className="flex-[3] space-y-6">
        <button
          onClick={() => router(-1)}
          className=" ml-8  mt-6 flex items-center py-2 px-4 border border-gray-300 rounded-md hover:bg-gray-200 transition-colors text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          <ArrowLeft className="mr-2 h-5 w-5" />
          Back to Contests
        </button>

        <div className="bg-white rounded-lg py-2 px-6 shadow-lg">
          <div className="mb-6">
            <h1 className="text-3xl font-bold text-gray-900 flex items-center">
              {title?.slice(0, 1)?.toUpperCase() + title?.slice(1)}
            </h1>
            <p className="text-lg text-gray-700 mt-2 flex items-center">
              <Clock className="mr-2 h-5 w-5 text-gray-600" />
              {!isContestLive
                ? `Contest starts in: ${formatCountdown(timeLeft, "start")}`
                : isContestLive && new Date() >= new Date(endtime)
                ? "Contest is over!"
                : `Time left: ${formatCountdown(timeLeft, "end")}`}
            </p>
          </div>
          <MyButton
            isLoading2={isRegistering}
            disabled={isRegistered || !(new Date(endtime) > new Date())}
            onClick={handleRegister}
            className="bg-violet-600 mb-2 text-white py-1 px-3 rounded-md hover:bg-violet-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            {isRegistered
              ? "Registered ✓"
              : new Date(endtime) > new Date()
              ? "Register for Contest"
              : "Not registered"}
          </MyButton>
          <div className="space-y-6">
            <ContestRules />
            <section>
              <h2 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
                <Star className="mr-2 h-5 w-5 text-gray-600" />
                Questions
              </h2>
              <div className="bg-white rounded-lg overflow-hidden divide-y divide-gray-200">
                {isContestLive &&
                (isRegistered || new Date(endtime) < new Date()) ? (
                  problems?.map((question, index) => (
                    <QuestionItem
                      key={question.id}
                      question={question}
                      index={index}
                      isContestOver={
                        isContestLive && new Date() >= new Date(endtime)
                      }
                    />
                  ))
                ) : isContestLive && !isRegistered ? (
                  <div className="text-center py-10 px-6 bg-gray-50">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-3">
                      Contest is live!
                    </h2>
                    <p className="text-lg text-gray-600">
                      Register to see the questions!
                    </p>
                  </div>
                ) : (
                  <div className="text-center py-10 px-6 bg-gray-50">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-3">
                      Contest starts in:
                    </h2>
                    <p className="text-4xl font-mono text-gray-900">
                      {formatCountdown(timeLeft, "start")}
                    </p>
                    <p className="mt-4 text-lg text-gray-500">
                      Get ready, your coding challenge is about to begin!
                    </p>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
      {/* Rankings Section */}
      <div className="flex-[1]">
        <RankingTable timeLeft={timeLeft} participants={data?.participants} />
      </div>
    </div>
  );
}

function QuestionItem({ question, index, isContestOver }) {
  const router = useNavigate();
  const params = useParams();
  const id = params.id;
  return (
    <div className="p-3  hover:bg-gray-100 transition-colors">
      <div className="flex items-center justify-between">
        <span className=" text-lg font-medium text-gray-700">
          {index + 1}. {question.title}
        </span>
        <div className="flex gap-2  items-center space-x-2">
          {question.status === "solved" ? (
            <div className="flex items-center space-x-1 bg-green-100 px-2 py-1 rounded-full">
              <Check className="h-4 w-4 text-green-600" />
              <span className="text-sm font-medium text-green-600">Solved</span>
            </div>
          ) : (
            <div className="flex items-center space-x-1 bg-red-100 px-2 py-1 rounded-full">
              <AlertCircle className="h-4 w-4 text-red-600" />
              <span className="text-sm font-medium text-red-600">
                Not Solved
              </span>
            </div>
          )}
          <span
            className={`px-2 py-0.5 rounded-2xl  font-semibold ${
              question.difficulty === "easy"
                ? "bg-green-100 text-green-700"
                : question.difficulty === "medium"
                ? "bg-yellow-100 text-yellow-700"
                : "bg-red-100 text-red-700"
            }`}
          >
            {question.difficulty}
          </span>
        </div>
      </div>

      <p className="text-sm text-gray-500 mt-1 flex items-center">
        <Award className="mr-1 h-3 w-3" />
        Score: {question.marks}
      </p>
      {console.log("global ", question)}
      <button
        onClick={() => {
          if (!isContestOver) {
            router(`/contests/${id}/questions/${question.globalcodingid}`);
          } else {
            router(`/students/problem/${question.globalcodingid}`);
          }
        }}
        className="mt-2 text-sm text-violet-600 hover:text-violet-800 font-medium flex items-center transition-colors"
      >
        {question.status === "solved" ? "Review" : "Solve"}

        <ChevronRight className="ml-0.5 h-3 w-3" />
      </button>
    </div>
  );
}

function RankingTable({ participants }) {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  return (
    <div className="w-full">
      <h2 className="text-2xl font-semibold mb-4">Ranking</h2>
      <div className=" shadow-lg rounded-lg">
        <table className="w-full  border  overflow-hidden">
          <thead className="bg-gray-50 text-sm">
            <tr>
              <th className="px-3 py-2 text-left">Rank</th>
              <th className="px-3 py-2 text-left">Name</th>
              <th className="px-3 py-2 text-right">Score</th>
              <th className="px-3 py-2 text-right">Finish Time</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {participants && participants.length > 0 ? (
              participants.map((par, index) => (
                <tr
                  key={index}
                  className={par?.rank % 2 === 0 ? "bg-gray-50" : ""}
                >
                  <td className="px-3 py-2">{par?.rank}</td>
                  <td className="px-3 py-2 flex items-center">
                    <span className="text-blue-500 hover:underline cursor-pointer">
                      {par?.students?.firstname + " " + par?.students?.lastname}
                    </span>
                  </td>
                  <td className="px-3 py-2 text-right">{par?.score}</td>
                  <td className="px-3 py-2 text-right font-mono">
                    {par?.finishtime
                      ? new Date(par?.finishtime * 1000)
                          .toISOString()
                          .substr(11, 8)
                      : "0:00:00"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-4">
                  Results will be published 30 minutes after the contest.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex pb-2 justify-center mt-2">
          <button
            onClick={() => {
              navigate(`/contests/${id}/contestLeaderboard/?page=1`);
            }}
            className="text-blue-500 hover:text-blue-600 font-semibold"
          >
            More...
          </button>
        </div>
      </div>
    </div>
  );
}

export function formatCountdown(timeLeft, type) {
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return type === "start"
    ? `${days}d ${hours}h ${minutes}m ${seconds}s`
    : `${hours}h ${minutes}m ${seconds}s`;
}
