import React, { useState, useEffect, ReactNode } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import "../../index.css";
const MyTable = ({ children, nosearch, itemsPerPage = 10 }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paginatedChildren, setPaginatedChildren] = useState([]);
  const [goToPage, setGoToPage] = useState("");

  useEffect(() => {
    const tableBody = React.Children.toArray(children).find(
      (child) => React.isValidElement(child) && child.type === "tbody"
    );

    if (React.isValidElement(tableBody)) {
      const rows = React.Children.toArray(tableBody.props.children);
      const searchFilteredRows = rows.filter((row) => {
        if (!React.isValidElement(row)) return false;

        const rowText = React.Children.toArray(row.props.children)
          .map((cell) =>
            React.isValidElement(cell) ? cell.props.children : cell
          )
          .join(" ")
          .toLowerCase();

        return rowText.includes(searchTerm.toLowerCase());
      });

      setFilteredRows(searchFilteredRows);
      setTotalPages(Math.ceil(searchFilteredRows.length / itemsPerPage));

      // Use searchFilteredRows for pagination instead of original rows
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedRows = searchFilteredRows.slice(startIndex, endIndex);

      setPaginatedChildren(
        React.Children.map(children, (child) => {
          if (React.isValidElement(child) && child.type === "tbody") {
            return React.cloneElement(child, {}, paginatedRows);
          }
          return child;
        })
      );
    }
  }, [children, currentPage, itemsPerPage, searchTerm]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleGoToPage = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(goToPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setGoToPage("");
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-violet-100 focus:outline-offset-0"
        >
          1
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push(
          <span
            key="ellipsis-start"
            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700"
          >
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
            i === currentPage
              ? "z-10 bg-violet-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
              : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-violet-100 focus:outline-offset-0"
          }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <span
            key="ellipsis-end"
            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700"
          >
            ...
          </span>
        );
      }
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-violet-100 focus:outline-offset-0"
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="relative MyTableContainer overflow-x-auto  whitespace-nowrap">
      {!nosearch && (
        <div className="px-4 py-2 shadow-xl">
          <input
            type="text"
            placeholder="Search across all columns..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md"
          />
          {searchTerm && (
            <p className="text-sm text-gray-600 mt-1">
              {filteredRows.length} result{filteredRows.length !== 1 ? "s" : ""}{" "}
              found
            </p>
          )}
        </div>
      )}
      <table className="w-full divide-y overflow-x-auto divide-gray-200">
        {paginatedChildren}
      </table>
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-violet-100"
          >
            Previous
          </button>
          <button
            onClick={() =>
              handlePageChange(Math.min(totalPages, currentPage + 1))
            }
            disabled={currentPage === totalPages}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-violet-100"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Page <span className="font-medium">{currentPage}</span> of{" "}
              <span className="font-medium">{totalPages}</span>
            </p>
          </div>
          <div className="flex items-center">
            <form onSubmit={handleGoToPage} className="mr-4">
              <label htmlFor="go-to-page" className="sr-only">
                Go to page
              </label>
              <input
                type="number"
                id="go-to-page"
                className="block pl-1 w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                placeholder="Go to page"
                min="1"
                max={totalPages}
                value={goToPage}
                onChange={(e) => setGoToPage(e.target.value)}
              />
            </form>
            <nav
              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <button
                onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-violet-100 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeft className="h-5 w-5" aria-hidden="true" />
              </button>
              {renderPageNumbers()}
              <button
                onClick={() =>
                  handlePageChange(Math.min(totalPages, currentPage + 1))
                }
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-violet-100 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Next</span>
                <ChevronRight className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTable;
