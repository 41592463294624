// const development = "localhost";
// const deployment = "server.skelo.in";

// export const SERVER_URL = `http://${getSubDomain(
//   window.location.hostname
// )}${development}:8060`;

// export function getSubDomain(location) {
//   const locationParts = location.split(".");
//   let sliceTill = -2;
//   const isMainWebsite = locationParts.slice(-1)[0] === development;
//   if (isMainWebsite) {
//     sliceTill = -1;
//   }
//   const checkdot = locationParts.slice(0, sliceTill).join(".") + ".";
//   if (checkdot === ".") {
//     return "";
//   }
//   return checkdot;
// }

const development = "localhost";
const deployment = "server.skelo.in";

export const SERVER_URL = `https://${deployment}`;

export function getSubDomain(location) {
  const locationParts = location.split(".");
  let sliceTill = -2;
  const isMainWebsite = locationParts.slice(-1)[0] === deployment;
  if (isMainWebsite) {
    sliceTill = -1;
  }
  const checkdot = locationParts.slice(0, sliceTill).join(".") + ".";
  if (checkdot === ".") {
    return "";
  }
  return checkdot;
}