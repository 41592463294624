import { combineReducers } from "@reduxjs/toolkit";
import sidebarSlice from "../slices/SidebarSlice";

import UserReducer from "../slices/UserSlice";
import AuthSlice from "../slices/AuthSlice";

//rtk
import { studentsApi } from "../rtkquery/FetchStudents2";
import { vendorsApi } from "../rtkquery/VendorsReducer";
import { institutionApi } from "../rtkquery/InstitutionsReducer";
import { batchYearsApi } from "../rtkquery/BatchYearsReducer";
//qb

import ManageUISlice from "../slices/ui/Manageui";
import { batchApi } from "../rtkquery/BatchReducer";
import { assessmentCategoryApi } from "../rtkquery/assessment/AssessmentCategory";
import { subjectsApi } from "../rtkquery/Subject";
import { categoriesApi } from "../rtkquery/Categories";
import { subCategoriesApi } from "../rtkquery/SubCategories";
import { chaptersApi } from "../rtkquery/Chapters";
import { questionsApi } from "../rtkquery/Questions";
import { QBAccessApi } from "../rtkquery/QBAccess";
import { assessmentApi } from "../rtkquery/assessment/Assessment";
import { assignmentCategoryApi } from "../rtkquery/assignment/AssignmentCategory";
import { assignmentApi } from "../rtkquery/assignment/Assignment";
import { studentAssessmentsApi } from "../rtkquery/assessment/StudentAssessment";
import { studentAssignmentsApi } from "../rtkquery/assignment/StudentAssignments";
import { billingApi } from "../rtkquery/billing/billing";
import { dashboardApi } from "../rtkquery/Dashboard";
// assignment
import FetchAssignmentCategory from "../slices/assignment/FetchAssignments";

//dsa global
import { dsaApi } from "../rtkquery/GlobalDSA/globaldsa";
//student slices
import examSlice from "../slices/student/ExamSlice";
import assignmentSlice from "../slices/student/AssignmentSlice";
import StudentAssignment from "../slices/assignment/StudentAssignment";
import { learningApi } from "../rtkquery/Learning";
// reports
import { assessmentReportsApi } from "../rtkquery/assessment/AssessmentReports";

// compiler api
import { compilerApi } from "../rtkquery/compiler/Compiler";
import { codingApi } from "../rtkquery/compiler/Coding";

// contest api
import { contestApi } from "../rtkquery/contest/Contest";

const rootReducer = combineReducers({
  sidebar: sidebarSlice,

  userauth: AuthSlice,
  userslice: UserReducer,

  manageUISlice: ManageUISlice,
  //rtk

  [studentsApi.reducerPath]: studentsApi.reducer,
  [vendorsApi.reducerPath]: vendorsApi.reducer,
  [institutionApi.reducerPath]: institutionApi.reducer,
  [batchYearsApi.reducerPath]: batchYearsApi.reducer,
  [batchApi.reducerPath]: batchApi.reducer,
  [assessmentCategoryApi.reducerPath]: assessmentCategoryApi.reducer,
  [subjectsApi.reducerPath]: subjectsApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [subCategoriesApi.reducerPath]: subCategoriesApi.reducer,
  [chaptersApi.reducerPath]: chaptersApi.reducer,
  [questionsApi.reducerPath]: questionsApi.reducer,
  [QBAccessApi.reducerPath]: QBAccessApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [assessmentApi.reducerPath]: assessmentApi.reducer,
  [assignmentCategoryApi.reducerPath]: assignmentCategoryApi.reducer,
  [assignmentApi.reducerPath]: assignmentApi.reducer,
  [billingApi.reducerPath]: billingApi.reducer,
  //student rtk
  [studentAssessmentsApi.reducerPath]: studentAssessmentsApi.reducer,
  [studentAssignmentsApi.reducerPath]: studentAssignmentsApi.reducer,
  //dsa global
  [dsaApi.reducerPath]: dsaApi.reducer,
  [learningApi.reducerPath]: learningApi.reducer,
  // reports
  [assessmentReportsApi.reducerPath]: assessmentReportsApi.reducer,
  //compiler
  [compilerApi.reducerPath]: compilerApi.reducer,
  [codingApi.reducerPath]: codingApi.reducer,
  //contest
  [contestApi.reducerPath]: contestApi.reducer,
  //qb

  // assignment
  fetchassignmentcategory: FetchAssignmentCategory,

  //student
  exam: examSlice,
  assignmentexam: assignmentSlice,
  assignment: StudentAssignment,
});

export default rootReducer;
