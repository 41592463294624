import { Eye } from "lucide-react";
import React from "react";

const View = ({ text = "View" }) => {
  return (
    <button
      className="flex items-center px-4 py-2 text-sm text-violet-600 bg-violet-100 rounded-full hover:bg-violet-200 transition-colors duration-200 ml-7"
      type="button"
    >
      <span className="button__text">{text}</span>
      <Eye size={14} className="button__icon ml-2 text-violet-600" />
    </button>
  );
};

export default View;
