import React, { useEffect, useRef, useState } from "react";
import * as tfjs from "@tensorflow/tfjs";
import * as cocoSsd from "@tensorflow-models/coco-ssd";

const VideoPlayer = ({
  info,
  setInfo,
  onFaceCapture,
  faceCountCap,
  type,
  status = "progres",
}) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [detectedObjects, setDetectedObjects] = useState([]);
  const [isCaptured, setIsCaptured] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [noFaceDuration, setNoFaceDuration] = useState(0);
  const NO_FACE_LIMIT = 100 * 10000;
  // console.log("noFaceDuration", noFaceDuration);
  useEffect(() => {
    if (info) {
      const faceCount = detectedObjects.filter(
        (obj) => obj.class === "person"
      ).length;

      if (faceCount > 1) {
        setNoFaceDuration((prev) => prev + 100);
      } else {
        setNoFaceDuration(0);
      }

      if (noFaceDuration >= NO_FACE_LIMIT) {
        setInfo((prev) => ({
          ...prev,
          faceCount: info.faceCount + 1,
        }));
        // console.log("info", info);

        setNoFaceDuration(0);
      }
    }
  }, [detectedObjects, noFaceDuration]);

  const [deviceDetected, setDeviceDetected] = useState(false);
  const [deviceDuration, setDeviceDuration] = useState(0);
  const DEVICE_LIMIT = 100 * 10000;
  // console.log("nodeviceDuration", deviceDuration);

  useEffect(() => {
    if (info) {
      if (deviceDetected) {
        setDeviceDuration((prev) => prev + 100);
      } else {
        setDeviceDuration(0);
      }

      if (deviceDuration >= DEVICE_LIMIT) {
        setInfo((prev) => ({
          ...prev,
          electronicCount: info.electronicCount + 1,
        }));

        setDeviceDuration(0);
      }
    }
  }, [deviceDetected, deviceDuration]);

  // Start camera immediately
  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 200 },
            height: { ideal: 150 },
            facingMode: "user",
          },
        });
        if (videoRef?.current) {
          videoRef.current.srcObject = stream;
        } else {
          startCamera();
        }
      } catch (err) {
        startCamera();
      }
    };
    if (!isCaptured) {
      startCamera();
    }
  }, [isCaptured]);

  const handleCapture = () => {
    const faceCount = detectedObjects.filter(
      (obj) => obj.class === "person"
    ).length;

    if (faceCount === 1) {
      // Create a temporary canvas to capture the frame
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = videoRef.current.videoWidth;
      tempCanvas.height = videoRef.current.videoHeight;
      const tempCtx = tempCanvas.getContext("2d");

      // Draw the current video frame
      tempCtx.drawImage(
        videoRef.current,
        0,
        0,
        tempCanvas.width,
        tempCanvas.height
      );

      // Draw the detection boxes
      const mainCanvas = canvasRef.current;
      tempCtx.drawImage(mainCanvas, 0, 0);

      // Convert to data URL
      const imageDataUrl = tempCanvas.toDataURL("image/jpeg");
      setCapturedImage(imageDataUrl);

      // Stop the video stream
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());

      onFaceCapture(true);
      setIsCaptured(true);
    }
  };
  // Load model and start detection separately
  useEffect(() => {
    if (isCaptured) return; // Don't run detection if image is captured
    const loadModelsAndDetect = async () => {
      try {
        await tfjs.ready();
        const objectDetector = await cocoSsd.load();
        setIsModelLoaded(true);
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        const detectFacesAndObjects = async () => {
          try {
            if (isModelLoaded && videoRef?.current?.videoWidth > 0) {
              const objectResults = await objectDetector.detect(
                videoRef?.current
              );
              const detected = objectResults.some(
                (obj) =>
                  obj.class === "cell phone" ||
                  obj.class === "laptop" ||
                  obj.class === "remote"
              );
              setDeviceDetected(detected);

              setDetectedObjects(objectResults);
              context.clearRect(0, 0, canvas?.width, canvas?.height);
              objectResults.forEach((obj) => {
                const { bbox, class: className } = obj;
                const [x, y, width, height] = bbox;
                // context.beginPath();
                // context.rect(x, y, width, height);
                // context.lineWidth = 1.5;
                // context.strokeStyle = "#2563eb";
                // context.stroke();
                // context.font = "10px Inter";
                // context.fillStyle = "#2563eb";
                // context.fillText(className, x, y - 3);
              });
            }
          } catch (err) {
            console.error("Error during detection:", err);
          }
        };

        const detectionInterval = setInterval(detectFacesAndObjects, 100);
        return () => {
          clearInterval(detectionInterval);
        };
      } catch (err) {}
    };

    loadModelsAndDetect();
  }, [isModelLoaded, isCaptured]);

  const faceCount = detectedObjects.filter(
    (obj) => obj.class === "person"
  ).length;
  const cellPhoneCount = detectedObjects.filter(
    (obj) => obj.class === "cell phone"
  ).length;
  const bookCount = detectedObjects.filter(
    (obj) => obj.class === "book"
  ).length;
  const otherObjectsCount = detectedObjects.filter(
    (obj) => !["person", "cell phone", "book"].includes(obj.class)
  ).length;
  const otherObjectsList = [
    ...new Set(
      detectedObjects
        .filter((obj) => !["person", "cell phone", "book"].includes(obj.class))
        .map((obj) => obj.class)
    ),
  ].join(", ");

  const stopVideoStream = () => {
    if (videoRef.current?.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  useEffect(() => {
    return () => {
      console.log("un mounting");
      stopVideoStream();
      setDetectedObjects([]);
      setDeviceDetected(false);
      setNoFaceDuration(0);
      setDeviceDuration(0);
    };
  }, []);

  return (
    <div className="relative w-fit">
      {!isCaptured ? (
        <>
          {isModelLoaded ? (
            <video
              ref={videoRef}
              width={type === "capture" ? "500" : "110"}
              disablePictureInPicture
              height={type === "capture" ? "400" : "50"}
              autoPlay
              muted
              playsInline
              className="object-cover rounded-sm bg-gray-100"
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-48 h-48 rounded-lg">
              <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-violet-500"></div>
              {type === "capture" && (
                <p className="text-black text-xs">Loading Camera Models..</p>
              )}
            </div>
          )}
          {isModelLoaded && (
            <canvas
              ref={canvasRef}
              width={type === "capture" ? "500" : "200"}
              height={type === "capture" ? "400" : "150"}
              className="absolute top-0 left-0 rounded-lg hidden"
            />
          )}
          {/* <div className=" bg-black/50 text-white p-2 text-xs space-y-1">
          <div className="flex justify-between">
            <span>👤 Faces:</span>
            <span className="font-bold">{faceCount}</span>
          </div>
          <div className="flex justify-between">
            <span>📱 Phones:</span>
            <span className="font-bold">{cellPhoneCount}</span>
          </div>
          <div className="flex justify-between">
            <span>📚 Books:</span>
            <span className="font-bold">{bookCount}</span>
          </div>
          <div className="flex justify-between">
            <span>🎯 Others:</span>
            <span className="font-bold">{otherObjectsCount}</span>
          </div>
          {otherObjectsCount > 0 && (
            <div className="text-xs italic">
              Other objects: {otherObjectsList}
            </div>
          )}
        </div> */}
          {type === "capture" && (
            <button
              onClick={handleCapture}
              disabled={faceCount !== 1}
              className="mt-2 w-full bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Capture Face
            </button>
          )}
        </>
      ) : (
        <>
          <div className="flex flex-col items-center  p-4 rounded-lg ">
            <img
              src={capturedImage}
              alt="Captured face"
              className="rounded-lg border-2  mb-4"
              width="200"
              height="150"
            />
            <p className="text-lg font-semibold text-gray-700 flex items-center space-x-2">
              🎉 <span>Hurry! You are ready to go... 🚀</span>
            </p>
          </div>
        </>
      )}
      {/* {errorMessage && (
      <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
    )} */}
    </div>
  );
};

export default VideoPlayer;
