import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { compilerendpoints } from "../../../services/Apis";
import { SERVER_URL } from "../../../api/env";

const { SUBMITASSESSMENT_CODE } = compilerendpoints;
// Define the API slice
export const compilerApi = createApi({
  reducerPath: "compilerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    submitCode: builder.mutation({
      query: (credentials) => ({
        url: SUBMITASSESSMENT_CODE,
        method: "POST",
        body: credentials, // Assuming credentials are query parameters
      }),
      invalidatesTags: ["fetchContestProblems"],
    }),
  }),
});

export const { useSubmitCodeMutation } = compilerApi;
