import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../../api/env";
import { contestEndpoints } from "../../../services/Apis";
import createGlobalApi from "../createGlobalApi";

const {
  GET_ALLGLOBALDSA_CONTEST,
  CREATE_CONTEST,
  GET_STUDENTALL_CONTESTS,
  GET_CONTEST_PROBLEMS,
  REGISTER_CONTEST,
  GET_CONTEST_LEADERBOARD,
  GET_LEADERBOARD_DETAILS,
  EDIT_CONTEST,
  DOWNLOAD_CONTEST_REPORTS,
  GET_CONTESTS,
  GET_STUDENTMY_CONTESTS,
} = contestEndpoints;
export const contestApi = createGlobalApi({
  reducerPath: "contestApi",
  tagTypes: ["contest"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createContest: builder.mutation({
      query: (newDSA) => ({
        url: CREATE_CONTEST,
        method: "POST",
        body: newDSA,
      }),
    }),
    registerContest: builder.mutation({
      query: (data) => ({
        url: REGISTER_CONTEST,
        method: "POST",
        body: data,
      }),
    }),
    getAllGlobalDSAContest: builder.query({
      query: (data) => ({
        url: GET_ALLGLOBALDSA_CONTEST,
        method: "POST",
        body: data,
      }),
      keepUnusedDataFor: 300,
      refetchOnMountOrArgChange: false,
      refetchOnFocus: false,
    }),
    fetchStudentAllContests: builder.query({
      query: ({ page }) => `${GET_STUDENTALL_CONTESTS}?page=${page}`,
      providesTags: ["contest"],
    }),
    fetchStudentMyContests: builder.query({
      query: () => GET_STUDENTMY_CONTESTS,
      providesTags: ["contest"],
    }),
    fetchContestProblems: builder.query({
      query: (data) => ({
        url: `${GET_CONTEST_PROBLEMS}/${data}`,
        method: "GET",
        params: data,
      }),
      providesTags: ["fetchContestProblems"],
    }),
    getLeaderboard: builder.query({
      query: ({ contestId, page = 1 }) => ({
        url: `${GET_CONTEST_LEADERBOARD}/${contestId}/?page=${page}`,
      }),
      keepUnusedDataFor: 500,
    }),
    getLeaderboardDetails: builder.query({
      query: ({ contestId, page = 1 }) => ({
        url: `${GET_LEADERBOARD_DETAILS}/${contestId}`,
      }),
      keepUnusedDataFor: 500,
    }),
    getContests: builder.query({
      query: () => GET_CONTESTS,
    }),
    editContest: builder.mutation({
      query: (data) => ({
        url: EDIT_CONTEST,
        method: "POST",
        body: data,
      }),
    }),
    downloadContestReports: builder.query({
      query: (data) => ({
        url: `${DOWNLOAD_CONTEST_REPORTS}`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useGetAllGlobalDSAContestQuery,
  useCreateContestMutation,
  useFetchStudentAllContestsQuery,
  useFetchContestProblemsQuery,
  useRegisterContestMutation,
  useGetLeaderboardQuery,
  useGetLeaderboardDetailsQuery,
  useGetContestsQuery,
  useDownloadContestReportsQuery,
  useEditContestMutation,
  useFetchStudentMyContestsQuery,
} = contestApi;
