// services/assessmentsApi.js
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { studentassignmentendpoints } from "../../../services/Apis";
import { SERVER_URL } from "../../../api/env";
import createGlobalApi from "../createGlobalApi";

const {
  GET_STUDENTASSIGNMENTS,
  GET_ASSIGNMENTDETAILS,
  CREATE_ASSIGNMENT_ATTEMPT,
  UPDATE_ASSIGNMENT_ANSWERS,
  GET_ASSIGNMENT_ANSWERS,
  UPDATE_ASSIGNMENT_STATUS,
} = studentassignmentendpoints;
export const studentAssignmentsApi = createGlobalApi({
  reducerPath: "studentAssignmentsApi",
  tagTypes: ["CreateAttemptAssignment"],

  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    getStudentAssignments: builder.query({
      query: (credentials) => ({
        url: GET_STUDENTASSIGNMENTS,
        method: "POST",
        body: credentials,
      }),
    }),
    getAssignmentDetails: builder.query({
      query: (credentials) => ({
        url: GET_ASSIGNMENTDETAILS,
        method: "POST",
        body: credentials,
      }),
    }),
    createAssignmentAttempt: builder.mutation({
      query: (credentials) => ({
        url: CREATE_ASSIGNMENT_ATTEMPT,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["CreateAttemptAssignment"],
    }),
    updateAssignmentAttemptAnswers: builder.mutation({
      query: (credentials) => ({
        url: UPDATE_ASSIGNMENT_ANSWERS,
        method: "PUT",
        body: credentials,
      }),
    }),
    updateAssignmentStatus: builder.mutation({
      query: (credentials) => ({
        url: UPDATE_ASSIGNMENT_STATUS,
        method: "PUT",
        body: credentials,
      }),
    }),
    getAssignmentAttemptAnswers: builder.query({
      query: (credentials) => ({
        url: GET_ASSIGNMENT_ANSWERS,
        method: "POST",
        body: credentials,
      }),
      providesTags: ["CreateAttemptAssignment"],
    }),
  }),
});

export const {
  useGetStudentAssignmentsQuery,
  useGetAssignmentDetailsQuery,
  useCreateAssignmentAttemptMutation,
  useUpdateAssignmentAttemptAnswersMutation,
  useGetAssignmentAttemptAnswersQuery,
  useUpdateAssignmentStatusMutation,
} = studentAssignmentsApi;
