import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminpoints } from "../../services/Apis";
import { SERVER_URL } from "../../api/env";
import createGlobalApi from "./createGlobalApi";

const {
  GET_ALLVENDORS_API,
  CREATE_VENDOR_API,
  EDIT_VENDOR_API,
  VENDOR_IMAGE,
  ACTIVE_VENDOR,
} = adminpoints;

export const vendorsApi = createGlobalApi({
  reducerPath: "vendorsApi",
  tagTypes: ["Vendors"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    fetchAllVendors: builder.query({
      query: () => GET_ALLVENDORS_API,
      providesTags: ["Vendors"],
    }),
    createVendor: builder.mutation({
      query: (data) => ({
        url: CREATE_VENDOR_API,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Vendors"],
    }),
    handleVendorImage: builder.mutation({
      query: (data) => ({
        url: VENDOR_IMAGE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Vendors"],
    }),
    editVendor: builder.mutation({
      query: (data) => ({
        url: EDIT_VENDOR_API,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Vendors", "AdminInstitutions", "AllInstitutions"],
    }),
    activeVendor: builder.mutation({
      query: (id) => ({
        url: `${ACTIVE_VENDOR}${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Vendors"],
    }),
  }),
});

export const {
  useFetchAllVendorsQuery,
  useCreateVendorMutation,
  useEditVendorMutation,
  useHandleVendorImageMutation,
  useActiveVendorMutation,
} = vendorsApi;
