import { useState } from "react";

const MyButton = ({
  isLoading2 = false,
  onClick,
  children,
  className = "",
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    try {
      await onClick(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {(isLoading || isLoading2) && (
        <div
          className="fixed inset-0  pointer-events-none  cursor-wait"
          style={{ pointerEvents: "all" }} // Block pointer events for the overlay
        />
      )}

      <button
        onClick={handleClick}
        disabled={isLoading || isLoading2}
        className={`${className} inline-flex items-center justify-center relative  ${
          isLoading || isLoading2 ? "opacity-70 cursor-not-allowed" : ""
        }`}
        {...props}
      >
        <div className="flex items-center justify-center gap-2">
          {isLoading || isLoading2 ? (
            <>
              <svg
                className="animate-spin h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                />
              </svg>
            </>
          ) : (
            children
          )}
        </div>
      </button>
    </>
  );
};

export default MyButton;
