import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loading from "../assests/violet/pre.svg";
const ProtectedRoute2 = ({
  component: Component,
  component2,
  allowedRoles,
  redirectPath1,
  redirectPath2,
}) => {
  const { role, isAuthenticated, loading } = useSelector((state) => {
    return state.userslice;
  });
  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-violet-950">
        <img src={Loading} className="" />
      </div>
    );
  }

  if (isAuthenticated === true && allowedRoles.includes(role)) {
    return <Navigate to={redirectPath1} />;
  }

  // if (isAuthenticated === false) {
  //   return <Navigate to={redirectPath2} />;
  // }

  // if (!allowedRoles.includes(role)) {
  //   return <Navigate to={redirectPath2} />;
  // }
  console.log(<Component />);

  // Render the component if authentication and role are valid
  return <Component />;
};

export default ProtectedRoute2;
