import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loading from '../assests/violet/pre.svg';
const ProtectedRoute = ({
  component: Component,
  allowedRoles,
  redirectPath,
}) => {
  const { role, isAuthenticated, loading } = useSelector((state) => {
    // console.log(state);
    return state.userslice;
  });
  if (loading) {
    return <div className="w-full h-screen flex items-center justify-center bg-violet-950">
    <img src={Loading} className="" />
  </div> // Or a spinner/loading component
  }

  if (isAuthenticated === false) {
    // Redirect to login if not authenticated
    return <Navigate to={redirectPath} />;
  }

  if (!allowedRoles.includes(role)) {
    // Redirect to forbidden page if the role is not allowed
    return <Navigate to={redirectPath} />;
  }
  console.log(<Component />);
  // Render the component if authentication and role are valid
  return <Component />;
};

export default ProtectedRoute;
