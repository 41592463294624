import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { assessmentcategoryendpoints } from "../../../services/Apis";
import { SERVER_URL } from "../../../api/env";
import createGlobalApi from "../createGlobalApi";

const {
  GET_ALLASSESSMENTCATEGORIES,
  GET_ASSESSMENTCATEGORIES,
  CREATE_ASSESSMENTCATEGORY,
  EDIT_ASSESSMENTCATEGORY,
  DELETE_ASSESSMENTCATEGORY,
  GET_ASSESSMENTACCESSCATEGORIES,
} = assessmentcategoryendpoints;
// Define the API slice
export const assessmentCategoryApi = createGlobalApi({
  reducerPath: "assessmentCategoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  tagTypes: ["assessmentCategoryApi"], // Define tag types for caching and invalidation
  endpoints: (builder) => ({
    fetchIndividualCategories: builder.query({
      query: (data) => GET_ASSESSMENTCATEGORIES,
      providesTags: ["assessmentCategoryApi"], // Tags to associate with this endpoint
    }),
    fetchIndividualAccessCategories: builder.query({
      query: () => GET_ASSESSMENTACCESSCATEGORIES,
      providesTags: ["assessmentCategoryApi"], // Tags to associate with this endpoint
    }),
    fetchAllCategories: builder.query({
      query: (credentials) => ({
        url: GET_ALLASSESSMENTCATEGORIES,
        method: "POST",
        params: credentials, // Assuming credentials are query parameters
      }),
      providesTags: ["assessmentCategoryApi"], // Tags to associate with this endpoint
    }),
    createAssessmentCategory: builder.mutation({
      query: (credentials) => ({
        url: CREATE_ASSESSMENTCATEGORY,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["assessmentCategoryApi"], // Invalidate the 'Batch' tag to refetch data
    }),
    editAssessmentCategory: builder.mutation({
      query: (credentials) => ({
        url: EDIT_ASSESSMENTCATEGORY,
        method: "PUT",
        body: credentials,
      }),
      invalidatesTags: ["assessmentCategoryApi"], // Invalidate the 'Batch' tag to refetch data
    }),
  }),
});

export const {
  useFetchIndividualCategoriesQuery,
  useFetchAllCategoriesQuery,
  useCreateAssessmentCategoryMutation,
  useEditAssessmentCategoryMutation,
  useFetchIndividualAccessCategoriesQuery,
} = assessmentCategoryApi;
