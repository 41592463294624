import { ArrowLeft, ChevronDown, Info, User } from "lucide-react";
import {
  useGetLeaderboardDetailsQuery,
  useGetLeaderboardQuery,
} from "../../../redux/rtkquery/contest/Contest";
import { useParams, useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Pagination from "./Pagination";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";

export default function ContestRankingPremium() {
  const [participants, setParticipants] = useState([]);
  const [problems, setProblems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [participant, setParticipant] = useState();
  const param = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page") || "1", 10);

  const { data, isFetching: isFetching1 } = useGetLeaderboardQuery({
    contestId: param.contestid,
    page: page,
  });

  const { data: leaderboarddetails, isFetching: isFetching2 } =
    useGetLeaderboardDetailsQuery({
      contestId: param.contestid,
    });
  console.log("leaderboarddetails ", leaderboarddetails);
  useEffect(() => {
    if (data) {
      setParticipants(data?.participants);
    }
  }, [data]);

  useEffect(() => {
    if (leaderboarddetails) {
      setProblems(leaderboarddetails?.problems);
      setTotalCount(leaderboarddetails?.totalCount);
      setTotalPages(Math.ceil(leaderboarddetails?.totalPages));
      setItemsPerPage(leaderboarddetails?.pageSize);
      setParticipant(leaderboarddetails?.participant);
    }
  }, [leaderboarddetails, itemsPerPage]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const handlePageChange = (newPage) => {
    navigate(`?page=${newPage}`);
  };
  const role = useSelector((state) => state.userslice.role);
  const isLoading = isFetching1 || isFetching2;

  const renderSkeletonRow = () => (
    <tr className="border-t border-gray-200 hover:bg-gray-50 transition-colors">
      <td className="py-4 px-4">
        <Skeleton width={40} />
      </td>
      <td className="py-4 px-4">
        <div className="flex items-center gap-3">
          <Skeleton circle width={40} height={40} />
          <Skeleton width={100} />
        </div>
      </td>
      <td className="py-4 px-4">
        <Skeleton width={50} />
      </td>
      <td className="py-4 px-4">
        <Skeleton width={70} />
      </td>
      {problems?.map(() => (
        <td key={Math.random()} className="py-4 px-4">
          <Skeleton width={50} />
        </td>
      ))}
    </tr>
  );
  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-gray-50 text-gray-800 p-4 sm:p-6 font-sans">
      {role === "student" && (
        <button
          onClick={() => navigate("/contests")}
          className="mb-4 sm:mb-6 flex items-center py-2 px-4 border border-gray-300 rounded-md hover:bg-gray-200 transition-colors text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          <ArrowLeft className="mr-2 h-4 w-4 sm:h-5 sm:w-5" />
          Back to Contest
        </button>
      )}

      <div className="max-w-[95%] mx-auto">
        <header className="mb-6 sm:mb-8">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 sm:mb-4 text-gray-900">
            Ranking of{" "}
            <span className="text-violet-600">{leaderboarddetails?.title}</span>
          </h1>
          <div className="flex items-center text-gray-600 space-x-4">
            <div className="flex items-center">
              <User className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
              <span className="text-sm sm:text-base">
                {isFetching2 ? (
                  <Skeleton width={80} />
                ) : (
                  `${totalCount} Participants`
                )}
              </span>
            </div>
          </div>
        </header>

        <div className="bg-white shadow-xl rounded-lg overflow-hidden border border-gray-200">
          <div className="overflow-x-auto">
            <table
              className="w-full min-w-[800px]"
              style={{ tableLayout: "fixed" }}
            >
              <thead>
                <tr className="bg-gray-100 text-left text-gray-600 text-xs sm:text-sm uppercase">
                  <th className="py-2 sm:py-3 px-2 sm:px-4 font-semibold w-12 sm:w-16">
                    Rank
                  </th>
                  <th className="py-2 sm:py-3 px-2 sm:px-4 font-semibold w-48 sm:w-64">
                    Name
                  </th>
                  <th className="py-2 sm:py-3 px-2 sm:px-4 font-semibold w-20 sm:w-24">
                    Score
                  </th>
                  <th className="py-2 sm:py-3 px-2 sm:px-4 font-semibold w-28 sm:w-36">
                    <div className="flex items-center">
                      Finish Time
                      <ChevronDown className="ml-1 h-3 w-3 sm:h-4 sm:w-4" />
                    </div>
                  </th>
                  {problems?.map((problem, index) => (
                    <th
                      key={problem.globalcodingid}
                      className="py-2 sm:py-3 px-2 sm:px-4 font-semibold"
                    >
                      Q{index + 1} ({problem.marks})
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isLoading
                  ? renderSkeletonRow()
                  : role === "student" &&
                    participant !== null && (
                      <tr
                        key={0}
                        className="border-t border-gray-200 bg-yellow-100 hover:bg-yellow-200 transition-colors"
                      >
                        <td className="py-4 px-4">
                          <span className="font-semibold px-2 py-1 rounded-full text-sm text-black">
                            {participant?.rank}
                          </span>
                        </td>
                        <td className="py-4 px-4">
                          <div className="flex items-center gap-3">
                            <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center text-gray-600 font-bold overflow-hidden">
                              {participant?.students?.imageurl ? (
                                <img
                                  src={participant?.students.imageurl}
                                  alt="User"
                                  className="w-full h-full object-cover"
                                  onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src =
                                      "/placeholder.svg?height=40&width=40";
                                  }}
                                />
                              ) : (
                                <User size={24} />
                              )}
                            </div>
                            <div className="font-medium">
                              {participant?.students?.firstname +
                                " " +
                                participant?.students?.lastname}
                            </div>
                          </div>
                        </td>
                        <td className="py-4 px-4 font-medium">
                          {participant?.score}
                        </td>
                        <td className="py-4 px-4 text-gray-600">
                          {participant?.finishtime
                            ? new Date(participant?.finishtime * 1000)
                                .toISOString()
                                .substr(11, 8)
                            : "0:00:00"}
                        </td>
                        {problems?.map((problem) => (
                          <td
                            key={problem.globalcodingid}
                            className="py-4 px-4 text-gray-600"
                          >
                            {participant?.answers[problem.globalcodingid]
                              ?.timeSpentSeconds
                              ? new Date(
                                  participant.answers[problem.globalcodingid]
                                    .timeSpentSeconds * 1000
                                )
                                  .toISOString()
                                  .substr(11, 8)
                              : "-"}
                          </td>
                        ))}
                      </tr>
                    )}

                {isLoading
                  ? Array(itemsPerPage)
                      .fill(0)
                      .map(() => renderSkeletonRow())
                  : participants.map((participant, index) => (
                      <tr
                        key={index + 1}
                        className="border-t border-gray-200 hover:bg-gray-50 transition-colors"
                      >
                        <td className="py-4 px-4">
                          <span className="font-semibold px-2 py-1 rounded-full text-sm text-black">
                            {participant?.rank}
                          </span>
                        </td>
                        <td className="py-4 px-4">
                          <div className="flex items-center gap-3">
                            <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center text-gray-600 font-bold overflow-hidden">
                              {participant?.students?.imageurl ? (
                                <img
                                  src={participant.students.imageurl}
                                  alt="User"
                                  className="w-full h-full object-cover"
                                  onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src =
                                      "/placeholder.svg?height=40&width=40";
                                  }}
                                />
                              ) : (
                                <User size={24} />
                              )}
                            </div>
                            <div className="font-medium">
                              {participant?.students?.firstname +
                                " " +
                                participant?.students?.lastname}
                            </div>
                          </div>
                        </td>
                        <td className="py-4 px-4 font-medium">
                          {participant.score}
                        </td>
                        <td className="py-4 px-4 text-gray-600">
                          {participant.finishtime
                            ? new Date(participant.finishtime * 1000)
                                .toISOString()
                                .substr(11, 8)
                            : "0:00:00"}
                        </td>
                        {problems?.map((problem) => (
                          <td
                            key={problem.globalcodingid}
                            className="py-4 px-4 text-gray-600"
                          >
                            {participant.answers[problem.globalcodingid]
                              ?.timeSpentSeconds
                              ? new Date(
                                  participant.answers[problem.globalcodingid]
                                    .timeSpentSeconds * 1000
                                )
                                  .toISOString()
                                  .substr(11, 8)
                              : "-"}
                          </td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-4 sm:mt-6 flex flex-col sm:flex-row justify-between items-center text-xs sm:text-sm text-gray-600">
          <div className="flex items-center mb-2 sm:mb-0">
            <Info className="h-3 w-3 sm:h-4 sm:w-4 mr-2" />
            <span>
              {isLoading ? (
                <Skeleton width={200} />
              ) : (
                `Showing ${(currentPage - 1) * itemsPerPage + 1}-
                ${Math.min(
                  currentPage * itemsPerPage,
                  totalCount
                )} of ${totalCount} participants`
              )}
            </span>
          </div>
          {!isLoading && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>

        <div className="absolute top-4 right-4">
          <span className="px-3 py-1 sm:px-4 sm:py-2 rounded-full bg-red-100 text-red-800 font-medium text-xs sm:text-sm">
            {isLoading ? <Skeleton width={60} /> : "Ended"}
          </span>
        </div>
      </div>
    </div>
  );
}
