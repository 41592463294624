import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  title: "Assessment ",
  id: "",
  sections: [],
  currentSectionIndex: 0,
  currentQuestionIndex: 0,
  answers: {},
  sectionAnswerTypes: [],
  totalTimer: 0,
  sectionTimers: [],
  submitted: false,
  prevAnswer: [],
};

const examSlice = createSlice({
  name: "exam",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload.title;
      state.id = action.payload.id;
      state.sectionAnswerTypes =
        JSON.parse(
          localStorage.getItem(`sectionAnswerTypes${action.payload.id}`)
        ) || [];
    },
    setSections: (state, action) => {
      // Only update sections if they are empty
      console.log("setSections", state.answers);
      const data = action.payload;
      state.sections = data;
      state.sectionAnswerTypes = data.map((section) => ({
        answerCounts: {
          attempted: 0,
          notVisited: section.questions.length,
          notAnswered: 0,
          markedForReview: 0,
          markedForReviewAnswered: 0,
        },
        questions: section.questions.map((question) => ({
          answerType: question.answerType || 2,
        })),
      }));

      const fromlocalstorage = JSON.parse(
        localStorage.getItem(`sectionAnswerTypes${state.id}`)
      );

      if (!fromlocalstorage || fromlocalstorage.length === 0) {
        state.sectionAnswerTypes.forEach((section, sectionIndex) => {
          section.questions.forEach((question, questionIndex) => {
            const answer = state.answers[sectionIndex]?.[questionIndex];

            if (Array.isArray(answer)) {
              if (answer.length >= 1) {
                question.answerType = 1; // Attempted
              } else if (answer.length === 0) {
                question.answerType = 3; // Not answered
              }
            } else if (typeof answer === "object") {
              if (answer.status === "solved") {
                question.answerType = 1; // Answered
              } else {
                question.answerType = 3; // Not answered
              }
            }
            console.log("questions type: " + question.answerType);
          });

          const answerCounts = {
            attempted: 0,
            notVisited: 0,
            notAnswered: 0,
            markedForReview: 0,
            markedForReviewAnswered: 0,
          };
          section.questions.forEach((question) => {
            const { answerType } = question;

            if (answerType === 1) {
              answerCounts.attempted += 1;
            } else if (answerType === 2) {
              answerCounts.notVisited += 1;
            } else if (answerType === 3) {
              answerCounts.notAnswered += 1;
            } else if (answerType === 4) {
              answerCounts.markedForReview += 1;
            } else if (answerType === 5) {
              answerCounts.markedForReviewAnswered += 1;
            }
          });

          section.answerCounts = answerCounts;
        });
        localStorage.setItem(
          `sectionAnswerTypes${state.id}`,
          JSON.stringify(state.sectionAnswerTypes)
        );
      } else {
        state.sectionAnswerTypes = fromlocalstorage;
      }
    },

    setTotaltimer: (state, action) => {
      state.totalTimer = action.payload * 60;
    },
    setSectionTimers: (state, action) => {
      state.sectionTimers = action.payload?.map((duration) => duration * 60);
    },
    setCurrentSection: (state, action) => {
      state.currentSectionIndex = action.payload;
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
    saveAnswer: (state, action) => {
      const { sectionIndex, questionIndex, answer } = action.payload;
      if (!state.answers[sectionIndex]) {
        state.answers[sectionIndex] = {};
      }

      state.answers[sectionIndex] = {
        ...state.answers[sectionIndex],
        [questionIndex]: answer,
      };
    },
    updateTotalTimer: (state) => {
      state.totalTimer = Math.max(state.totalTimer - 1, 0); // Ensure it doesn't go below 0
    },
    updateSectionTimer: (state, action) => {
      const { sectionIndex } = action.payload;
      state.sectionTimers[sectionIndex] = Math.max(
        state.sectionTimers[sectionIndex] - 1,
        0
      ); // Ensure it doesn't go below 0
    },
    setAnswers: (state, action) => {
      console.log("set answer ", state.sections);

      state.answers = action.payload;

      const fromlocalstorage = JSON.parse(
        localStorage.getItem(`sectionAnswerTypes${state.id}`)
      );

      if (!fromlocalstorage || fromlocalstorage.length === 0) {
        state.sectionAnswerTypes.forEach((section, sectionIndex) => {
          section.questions.forEach((question, questionIndex) => {
            const answer = state.answers[sectionIndex]?.[questionIndex];

            if (Array.isArray(answer)) {
              if (answer.length >= 1) {
                question.answerType = 1; // Attempted
              } else if (answer.length === 0) {
                question.answerType = 3; // Not answered
              }
            } else if (typeof answer === "object") {
              if (answer.status === "solved") {
                question.answerType = 1; // Answered
              } else {
                question.answerType = 3; // Not answered
              }
            }
            console.log("questions type: " + question.answerType);
          });

          const answerCounts = {
            attempted: 0,
            notVisited: 0,
            notAnswered: 0,
            markedForReview: 0,
            markedForReviewAnswered: 0,
          };
          section.questions.forEach((question) => {
            const { answerType } = question;

            if (answerType === 1) {
              answerCounts.attempted += 1;
            } else if (answerType === 2) {
              answerCounts.notVisited += 1;
            } else if (answerType === 3) {
              answerCounts.notAnswered += 1;
            } else if (answerType === 4) {
              answerCounts.markedForReview += 1;
            } else if (answerType === 5) {
              answerCounts.markedForReviewAnswered += 1;
            }
          });

          section.answerCounts = answerCounts;
        });
        localStorage.setItem(
          `sectionAnswerTypes${state.id}`,
          JSON.stringify(state.sectionAnswerTypes)
        );
      } else {
        state.sectionAnswerTypes = fromlocalstorage;
      }
    },
    updateAnswerType: (state, action) => {
      console.log("update answer type", action.payload);
      const { currentSectionIndex, currentQuestionIndex, answerType } =
        action.payload;

      // Update the answerType for the specific question
      if (
        state.sectionAnswerTypes[currentSectionIndex] &&
        state.sectionAnswerTypes[currentSectionIndex].questions[
          currentQuestionIndex
        ]
      ) {
        state.sectionAnswerTypes[currentSectionIndex].questions[
          currentQuestionIndex
        ].answerType = answerType;
      }

      // Recalculate the answerCounts for the section
      const section = state.sectionAnswerTypes[currentSectionIndex];
      const answerCounts = {
        attempted: 0,
        notVisited: 0,
        notAnswered: 0,
        markedForReview: 0,
        markedForReviewAnswered: 0,
      };

      section.questions.forEach((question) => {
        const { answerType } = question;
        if (answerType === 1) {
          answerCounts.attempted += 1;
        } else if (answerType === 2) {
          answerCounts.notVisited += 1;
        } else if (answerType === 3) {
          answerCounts.notAnswered += 1;
        } else if (answerType === 4) {
          answerCounts.markedForReview += 1;
        } else if (answerType === 5) {
          answerCounts.markedForReviewAnswered += 1;
        }
      });

      // Update the answerCounts in the section
      section.answerCounts = answerCounts;
      // localStorage.getItem(`sectionAnswerTypes${state.id}`)

      localStorage.setItem(
        `sectionAnswerTypes${state.id}`,
        JSON.stringify(state.sectionAnswerTypes)
      );
    },
    decrementTimer: (state) => {
      if (state.totalTimer > 0) {
        state.totalTimer -= 1;
        if (state.sectionTimers[state.currentSectionIndex] > 0) {
          state.sectionTimers[state.currentSectionIndex] -= 1;
        }
      }
    },
    setPrevAnswer: (state, action) => {
      state.prevAnswer = action.payload;
    },
    submitExam: (state) => {
      state.submitted = true;
    },
  },
});

export const {
  setSections,
  setCurrentSection,
  setCurrentQuestion,
  saveAnswer,
  updateTotalTimer,
  updateSectionTimer,
  submitExam,
  setTotaltimer,
  setSectionTimers,
  setAnswers,
  setTitle,
  updateAnswerType,
  setPrevAnswer,
  decrementTimer,
} = examSlice.actions;

export default examSlice.reducer;
