import React, { useState } from 'react';
import { Editor } from './room/Editor';
import { RoomControls } from './room/RoomControls';
import { Code2 } from 'lucide-react';

function LiveRoom() {
  const [roomId, setRoomId] = useState('');
  const [isJoined, setIsJoined] = useState(false);
  const [isHost, setIsHost] = useState(false);

  const createRoom = () => {
    const newRoomId = Math.random().toString(36).substring(2, 8);
    setRoomId(newRoomId);
    setIsJoined(true);
    setIsHost(true);
  };

  const joinRoom = (e) => {
    e.preventDefault();
    if (roomId.trim()) {
      setIsJoined(true);
      setIsHost(false);
    }
  };

  if (!isJoined) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full">
          <div className="flex items-center justify-center gap-2 mb-6">
            <Code2 className="w-8 h-8 text-blue-500" />
            <h1 className="text-2xl font-bold text-gray-900">Live Code Viewer</h1>
          </div>
          
          <div className="space-y-4">
            <button
              onClick={createRoom}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Create New Room
            </button>
            
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">or</span>
              </div>
            </div>

            <form onSubmit={joinRoom} className="space-y-4">
              <input
                type="text"
                value={roomId}
                onChange={(e) => setRoomId(e.target.value)}
                placeholder="Enter Room ID"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              <button
                type="submit"
                className="w-full bg-gray-800 text-white py-2 px-4 rounded-lg hover:bg-gray-900 transition-colors"
              >
                Join Room
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-2">
            <Code2 className="w-8 h-8 text-blue-500" />
            <h1 className="text-2xl font-bold text-gray-900">Live Code Viewer</h1>
          </div>
          <span className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm">
            {isHost ? 'Host' : 'Viewer'}
          </span>
        </div>
        
        <RoomControls roomId={roomId} />
        <Editor roomId={roomId} isHost={isHost} />
      </div>
    </div>
  );
}

export default LiveRoom;
