import { AlertCircle } from "lucide-react";

export default function ContestRules() {
  return (
    <div className="max-w-4xl mx-auto bg-white rounded-lg overflow-hidden">
      <div className="p-6">
        <h2 className="text-xl font-semibold flex items-center gap-2 mb-4">
          <AlertCircle className="h-5 w-5" />
          Important Note
        </h2>
        <div className="space-y-6">
          <div className="space-y-4">
            <p className="text-sm">
              To ensure a fair and engaging contest experience, we have
              carefully crafted the rules for **Skelo** contests.
            </p>
            <ol className="list-decimal pl-5 text-sm space-y-2">
              <li>
                A penalty of 5 minutes will be applied for each incorrect
                submission.
              </li>
              <li>
                Test cases may be partially hidden during the contest to prevent
                unfair advantages.
              </li>
              <li>
                The final contest rankings will be published within 5 working
                days after the contest concludes.
              </li>
              <li>
                Participants must remain on the contest page throughout the
                duration of the contest. Frequent tab-switching will be tracked
                and may result in warnings or disqualification.
              </li>
              <li>
                Bonus points may be awarded for early and innovative solutions.
              </li>
            </ol>
          </div>

          <div className="space-y-4">
            <h3 className="font-medium">
              The following actions are considered violations:
            </h3>
            <ul className="list-disc pl-5 text-sm space-y-2">
              <li>Submitting solutions from multiple accounts.</li>
              <li>
                Submitting identical or highly similar code from multiple
                participants.
              </li>
              <li>Disrupting other participants or the contest environment.</li>
              <li>Sharing solutions publicly before the contest ends.</li>
              <li>
                Using any external tools, AI-generated code, or assistance for
                problem-solving.
              </li>
              <li>
                Accessing prohibited resources such as forums, discussion
                groups, or unauthorized materials.
              </li>
              <li>
                Engaging in any collaboration or communication with other
                participants during the contest.
              </li>
            </ul>
          </div>

          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
            <h4 className="font-semibold mb-2">ZERO TOLERANCE POLICY</h4>
            <p className="mb-4">
              Skelo strictly enforces fairness in contests. Violations,
              including plagiarism and cheating, will result in the following
              penalties:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                <span className="font-medium">First violation:</span> Contest
                score reset to zero and a 1-month contest ban.
              </li>
              <li>
                <span className="font-medium">Second violation:</span> Permanent
                account suspension without appeal.
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <h3 className="font-medium">Additional Incentives and Rewards:</h3>
            <ul className="list-disc pl-5 text-sm">
              <li>
                Bonus points will be awarded to the top 5 fastest problem
                solvers.
              </li>
              <li>
                Participants who submit the most efficient solutions will be
                highlighted on the leaderboard.
              </li>
              <li>
                Valid violation reports that help maintain contest fairness will
                be rewarded.
              </li>
              <li>
                Engaging with in-contest challenges or mini-tasks may earn
                additional rewards.
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <p className="text-sm">
              By participating, you agree to adhere to these rules and uphold
              the spirit of healthy competition. Let’s make the **Skelo**
              contest fair, fun, and exciting for everyone!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
