import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../api/env";

import { QBAccessendpoints } from "../../services/Apis";
import createGlobalApi from "./createGlobalApi";

const {
  GIVE_TOATAL_INSTITUTION,
  GIVE_BATCH_YEAR,
  GIVE_BATCH,
  REMOVE_INSTITUTION,
  REMOVE_BATCH_YEAR,
  REMOVE_BATCH,
} = QBAccessendpoints;
export const QBAccessApi = createGlobalApi({
  reducerPath: "QBAccessSlice",
  tagTypes: ["QBAccess"],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    fetchAccess: builder.query({
      query: (subjectId) => ({
        url: `/qb/subject/getAllAccessBySubjectId`,
        method: "POST",
        body: { subjectId },
      }),
      providesTags: ["QBAccess"],
    }),
    giveAccessInstitution: builder.mutation({
      query: ({ subjectId, institutionId, isVendorInstitution }) => ({
        url: `${GIVE_TOATAL_INSTITUTION}`,
        method: "POST",
        body: { subjectId, institutionId, isVendorInstitution },
      }),
      invalidatesTags: ["QBAccess"],
    }),
    giveAccessBatchYear: builder.mutation({
      query: ({ subjectId, batchyearId }) => ({
        url: `${GIVE_BATCH_YEAR}`,
        method: "POST",
        body: { subjectId, batchyearId },
      }),
      invalidatesTags: ["QBAccess"],
    }),
    giveAccessBatch: builder.mutation({
      query: ({ subjectId, batchId }) => ({
        url: `${GIVE_BATCH}`,
        method: "POST",
        body: { subjectId, batchId },
      }),
      invalidatesTags: ["QBAccess"],
    }),
    removeAccessInstitution: builder.mutation({
      query: ({ subjectId, institutionId, isVendorInstitution }) => ({
        url: `${REMOVE_INSTITUTION}`,
        method: "POST",
        body: { subjectId, institutionId, isVendorInstitution },
      }),
      invalidatesTags: ["QBAccess"],
    }),
    removeAccessBatchYear: builder.mutation({
      query: ({ subjectId, batchyearId }) => ({
        url: `${REMOVE_BATCH_YEAR}`,
        method: "POST",
        body: { subjectId, batchyearId },
      }),
      invalidatesTags: ["QBAccess"],
    }),
    removeAccessBatch: builder.mutation({
      query: ({ subjectId, batchId }) => ({
        url: `${REMOVE_BATCH}`,
        method: "POST",
        body: { subjectId, batchId },
      }),
      invalidatesTags: ["QBAccess"],
    }),
  }),
});

export const {
  useFetchAccessQuery,
  useGiveAccessInstitutionMutation,
  useGiveAccessBatchYearMutation,
  useGiveAccessBatchMutation,
  useRemoveAccessInstitutionMutation,
  useRemoveAccessBatchYearMutation,
  useRemoveAccessBatchMutation,
} = QBAccessApi;
