import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  adminpoints,
  vendortypeendpoints,
  adminvendortypeendpoints,
} from "../../services/Apis";
import { SERVER_URL } from "../../api/env";
import createGlobalApi from "./createGlobalApi";

const {
  GET_ALLVENDORSINSTITUTIONS_API,
  GET_ALLADMININSTITUTIONS_API,
  GET_ALLINSTITUTIONS_API,
} = adminpoints;
const { GET_VENDORINSTITUTIONS_API } = vendortypeendpoints;
const { CREATE_INSTITUTION_API, EDIT_INSTITUTION_API, ACTIVE_INSTITUTION } =
  adminvendortypeendpoints;

export const institutionApi = createGlobalApi({
  reducerPath: "apiService",
  tagTypes: [
    "AdminInstitutions",
    "AllInstitutions",
    "VendorInstitutions",
    "AllVendorInstitutions",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }), // Adjust baseUrl as necessary
  endpoints: (builder) => ({
    fetchAdminInstitutions: builder.query({
      query: () => GET_ALLADMININSTITUTIONS_API,
      providesTags: ["AdminInstitutions"],
    }),
    fetchAllInstitutions: builder.query({
      query: () => GET_ALLINSTITUTIONS_API,
      providesTags: ["AllInstitutions"],
    }),
    fetchAllVendorInstitutions: builder.query({
      query: () => GET_ALLVENDORSINSTITUTIONS_API,
      providesTags: ["AllVendorInstitutions"],
    }),
    fetchVendorInstitutions: builder.query({
      query: () => GET_VENDORINSTITUTIONS_API,
      providesTags: ["VendorInstitutions"],
    }),
    createInstitution: builder.mutation({
      query: (data) => ({
        url: CREATE_INSTITUTION_API,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "AllInstitutions",
        "AdminInstitutions",
        "AllVendorInstitutions",
        "VendorInstitutions",
      ],
    }),
    editInstitution: builder.mutation({
      query: (data) => ({
        url: EDIT_INSTITUTION_API,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [
        "AdminInstitutions",
        "Vendors",
        "AllInstitutions",
        "AllVendorInstitutions",
        "VendorInstitutions",
      ], // Invalidate relevant tags
    }),
    activeInstitution: builder.mutation({
      query: (id) => ({
        url: `${ACTIVE_INSTITUTION}${id}`,
        method: "POST",
      }),
      invalidatesTags: ["VendorInstitutions"],
    }),
  }),
});

export const {
  useFetchAdminInstitutionsQuery,
  useFetchAllInstitutionsQuery,
  useFetchAllVendorInstitutionsQuery,
  useFetchVendorInstitutionsQuery,
  useCreateInstitutionMutation,
  useEditInstitutionMutation,
  useActiveInstitutionMutation,
} = institutionApi;
