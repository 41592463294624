import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import {
  useCreateContestMutation,
  useGetAllGlobalDSAContestQuery,
} from "../../redux/rtkquery/contest/Contest";
import { useGetAllTagsQuery } from "../../redux/rtkquery/GlobalDSA/globaldsa";
import Select from "react-select";
import MyButton from "../../components/common/MyButton";
import ReactEditor from "react-text-editor-kit";

const CreateContest = ({ onClose }) => {
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    totalNumberOfQuestions: "",
    totalMarks: "",
    startTime: "",
    endTime: "",
  });
  console.log("formData ", formData);
  const [selectedDifficulties, setSelectedDifficulties] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fetch, setFetch] = useState(false);
  const [questions, setQuestions] = useState([]);
  const { data, isFetching: isLoadingQuestions } =
    useGetAllGlobalDSAContestQuery(
      {
        selectedDifficulties,
        selectedTags,
      },
      {
        skip: !fetch,
      }
    );
  useEffect(() => {
    if (data) {
      setQuestions(data?.questions);
    }
  }, [data]);
  const [topicOptions, setTopicOptions] = useState([]);

  const { data: alltags } = useGetAllTagsQuery();
  useEffect(() => {
    if (alltags) {
      let tags = alltags?.map((tag) => ({ label: tag, value: tag }));
      setTopicOptions(tags);
    }
  }, [alltags]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const handleSelectQuestion = (question) => {
    const existingIndex = selectedQuestions.findIndex(
      (q) => q.id === question.id
    );
    if (existingIndex !== -1) {
      // Deselect the question
      setSelectedQuestions(
        selectedQuestions.filter((q) => q.id !== question.id)
      );
    } else {
      // Select the question with default score
      setSelectedQuestions([...selectedQuestions, { ...question, score: 0 }]);
    }
  };

  const handleScoreChange = (questionId, value) => {
    setSelectedQuestions((prev) =>
      prev.map((q) =>
        q.id === questionId ? { ...q, score: parseInt(value, 10) || 0 } : q
      )
    );
  };

  const handleDeselectQuestion = (question) => {
    setSelectedQuestions((prevSelected) =>
      prevSelected.filter((q) => q.id !== question.id)
    );
  };

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    totalNumberOfQuestions: "",
    totalMarks: "",
    startTime: "",
    endTime: "",
  });
  const validatePage1 = () => {
    const {
      title,
      description,
      totalNumberOfQuestions,
      totalMarks,
      startTime,
      endTime,
    } = formData;

    const newErrors = {};

    if (!title) newErrors.title = "title is required.";
    if (!description) newErrors.description = "Description is required.";
    if (!totalNumberOfQuestions)
      newErrors.totalNumberOfQuestions =
        "Total Number of Questions is required.";
    if (!totalMarks) newErrors.totalMarks = "Total Marks is required.";
    if (!startTime) newErrors.startTime = "startTime is required.";
    if (!endTime) newErrors.endTime = "endTime is required.";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const handleNextPage = () => {
    if (validatePage1()) {
      setPage(2);
    }
  };
  const [createContest] = useCreateContestMutation();
  const handleSubmit = async () => {
    try {
      const response = await createContest({
        title: formData.title,
        description: formData.description,
        totalnoofquestions: formData.totalNumberOfQuestions,
        totalmarks: formData.totalMarks,
        starttime: formData.startTime,
        endtime: formData.endTime,
        selectedQuestions,
      }).unwrap();
      if (response.success) {
        toast.success("Contest Created Successfully");
        setFormData({
          title: "",
          description: "",
          totalNumberOfQuestions: "",
          totalMarks: "",
          startTime: "",
          endTime: "",
        });
        setPage(1);
        setQuestions([]);
        setSelectedDifficulties([]);
        setSearchTerm(null);
        setSelectedQuestions([]);
        setSelectedTags([]);
      }
    } catch (e) {
      console.log(e);
    }
  };
  let difficultyOptions = [
    {
      label: "Easy",
      value: "easy",
    },
    {
      label: "Medium",
      value: "medium",
    },
    {
      label: "Hard",
      value: "hard",
    },
  ];

  const handleDifficultyChange = (selectedOption) => {
    setSelectedDifficulties(selectedOption);
    setFetch(false);
  };
  const fetchQuestions = () => {
    setFetch(true);
  };
  // Handler for topic selection change
  const handleTagChange = (selectedOption) => {
    setSelectedTags(selectedOption);
    setFetch(false);
  };
  const showAlert = () => {
    if (
      selectedQuestions?.length !== Number(formData?.totalNumberOfQuestions)
    ) {
      return toast.error(
        "Selected questions and Required number of Questions should be equal"
      );
    }
    let totalmarks = selectedQuestions.reduce(
      (total, question) => total + (question.score || 0),
      0
    );
    if (totalmarks !== Number(formData?.totalMarks)) {
      return toast.error(
        "Selected Marks and Required Marks of Questions should be equal"
      );
    }
    Swal.fire({
      title: "<strong>Confirm Changes</strong>",
      html: "<p>Are you sure you want to save the assignment?</p>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        '<span class="custom-confirm-btn">Save Assignment</span>',
      cancelButtonText: '<span class="custom-cancel-btn">Cancel</span>',
      customClass: {
        container: "custom-container",
        title: "custom-title",
        content: "custom-content",
        confirmButton: "custom-confirm-btn",
        cancelButton: "custom-cancel-btn",
      },
      buttonsStyling: false, // Disable default button styling
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit();
        // Swal.fire("Saved!", "Your changes have been saved.", "success");
      } else if (result.isDismissed) {
        Swal.fire("Cancelled", "Your changes were not saved.", "info");
      }
    });
  };
  const [searchTerm, setSearchTerm] = useState("");
  const filteredQuestions = questions?.filter((question) =>
    question?.title?.toLowerCase().includes(searchTerm?.toLowerCase())
  );
  return (
    <div className="flex relative w-[70%] bg-white overflow-y-auto   h-[90%]   flex-col justify-start items-center ">
      <button
        onClick={() => onClose(false)}
        className="absolute text-4xl -top-1 right-0 mt-2 mr-4  text-gray-600"
      >
        &times;
      </button>
      <div className="w-full overflow-y-auto max-w-6xl p-12">
        {page === 1 && (
          <>
            <div className="mb-6 text-center">
              <h1 className="text-3xl font-bold text-violet-700">
                Create Contest
              </h1>
              <p className="text-gray-600 text-lg mt-2">
                Fill in the details to create a contest
              </p>
            </div>

            <div className="mb-4 overflow-y-auto mt-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="shadow-lg appearance-none border rounded-sm m-3 w-[92%] py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-violet-500"
              />
              {errors.title && (
                <p className="text-red-500 text-xs italic">{errors.title}</p>
              )}
            </div>

            <div className="mb-4 overflow-y-auto">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Description
              </label>
              <ReactEditor
                editorState={formData.description} // Change `value` to `editorState` or as per the editor's API
                onChange={(newEditorState) =>
                  setFormData((prev) => ({
                    ...prev,
                    description: newEditorState, // or however you should update the state based on the editor
                  }))
                }
                className="p-4 h-48 border-none focus:outline-none focus:ring focus:ring-purple-400"
              />

              {/* <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                className="shadow-lg appearance-none rounded-sm m-3 w-[92%] border py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-violet-500"
              /> */}
              {errors.description && (
                <p className="text-red-500 text-xs italic">
                  {errors.description}
                </p>
              )}
            </div>

            <div className="mb-4 overflow-y-auto">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Total Number of Questions
              </label>
              <input
                type="number"
                name="totalNumberOfQuestions"
                value={formData.totalNumberOfQuestions}
                onChange={handleInputChange}
                className="shadow-lg appearance-none border rounded-sm m-3 w-[92%] py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-violet-500"
              />
              {errors.totalNumberOfQuestions && (
                <p className="text-red-500 text-xs italic">
                  {errors.totalNumberOfQuestions}
                </p>
              )}
            </div>

            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Total Marks
              </label>
              <input
                type="number"
                name="totalMarks"
                value={formData.totalMarks}
                onChange={handleInputChange}
                className="shadow-lg appearance-none border rounded-sm m-3 w-[92%] py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-violet-500"
              />
              {errors.totalMarks && (
                <p className="text-red-500 text-xs italic">
                  {errors.totalMarks}
                </p>
              )}
            </div>

            <div className="mb-4 overflow-y-auto">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Start Time
              </label>
              <input
                type="datetime-local"
                name="startTime"
                value={formData.startTime}
                onChange={handleInputChange}
                className="shadow-lg appearance-none border rounded-sm m-3 w-[92%] py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-violet-500"
              />
              {errors.startTime && (
                <p className="text-red-500 text-xs italic">
                  {errors.startTime}
                </p>
              )}
            </div>

            <div className="mb-4 overflow-y-auto">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                End Time
              </label>
              <input
                type="datetime-local"
                name="endTime"
                value={formData.endTime}
                onChange={handleInputChange}
                className="shadow-lg appearance-none border rounded-sm m-3 w-[92%] py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-violet-500"
              />
              {errors.endTime && (
                <p className="text-red-500 text-xs italic">{errors.endTime}</p>
              )}
            </div>

            <div className="flex items-center justify-between">
              <button
                type="button"
                onClick={handleNextPage}
                className="bg-violet-500 hover:bg-violet-600 text-white font-bold py-2 px-4 rounded-sm focus:outline-none focus:ring-4 focus:ring-violet-300"
              >
                Next
              </button>
            </div>
          </>
        )}

        {page === 2 && (
          <div>
            <div className=" flex justify-between">
              <h2 className="text-3xl w-full font-semibold">
                Select Questions
              </h2>

              <button
                onClick={showAlert}
                className="bg-violet-600    text-white py-2 px-5 rounded-lg "
              >
                Save Contest
              </button>
            </div>
            <div className="flex justify-between items-center mt-1 border-[1px] shadow-lg p-3 rounded-md">
              <div className="flex items-center">
                <span className="text-lg font-semibold text-gray-700 mr-2">
                  Required Questions:
                </span>
                <span className="text-lg text-violet-600 font-bold">
                  {formData?.totalNumberOfQuestions}
                </span>
              </div>
              <div className="flex items-center">
                <span className="text-lg font-semibold text-gray-700 mr-2">
                  Selected Questions:
                </span>
                <span className="text-lg text-green-600 font-bold">
                  {selectedQuestions?.length}
                </span>
              </div>
              <div className="flex items-center">
                <span className="text-lg font-semibold text-gray-700 mr-2">
                  Required Marks:
                </span>
                <span className="text-lg text-green-600 font-bold">
                  {formData?.totalMarks}
                </span>
              </div>
              <div className="flex items-center">
                <span className="text-lg font-semibold text-gray-700 mr-2">
                  Selected Marks:
                </span>
                <span className="text-lg text-green-600 font-bold">
                  {selectedQuestions.reduce(
                    (total, question) => total + (question.score || 0),
                    0
                  )}
                </span>
              </div>
            </div>

            {/* Questions List */}
            <div className="mt-3">
              <h3 className="text-2xl font-semibold mb-4">Questions</h3>
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">
                  Select Topic Tags
                </h3>
                <Select
                  isMulti
                  name="tags"
                  options={topicOptions}
                  className="basic-multi-select "
                  classNamePrefix="select"
                  value={selectedTags}
                  onChange={handleTagChange}
                  placeholder="Select topics"
                />
              </div>

              <div>
                <div className="mb-4">
                  {/* Search Field */}
                  {/* Difficulty Dropdown */}
                  <Select
                    isMulti
                    name="options"
                    options={difficultyOptions}
                    className="basic-multi-select "
                    classNamePrefix="select"
                    value={selectedDifficulties}
                    onChange={handleDifficultyChange}
                    placeholder="Select Difficulty"
                  />
                  <MyButton
                    isLoading2={isLoadingQuestions}
                    onClick={fetchQuestions}
                    className="bg-violet-600 mt-6 text-white p-2 rounded-lg mb-4"
                  >
                    Fetch Questions
                  </MyButton>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search questions"
                    className="border border-gray-300 rounded-md p-2 w-full mb-2"
                  />
                  <h3 className="text-lg font-semibold mb-2">
                    Available Questions
                  </h3>
                  <div className="p-4 rounded-lg border border-gray-300 shadow-md bg-white">
                    <div className="overflow-y-auto max-h-[30rem] space-y-3">
                      {filteredQuestions?.length > 0 ? (
                        filteredQuestions.map((question) => {
                          const isSelected = selectedQuestions.some(
                            (q) => q.id === question.id
                          );
                          const questionScore = selectedQuestions.find(
                            (q) => q.id === question.id
                          )?.score;

                          return (
                            <div
                              key={question.id}
                              className="border border-gray-300 rounded-lg p-3 transition-transform transform hover:-translate-y-1 hover:shadow-md hover:border-violet-300"
                            >
                              <div className="flex items-start justify-between">
                                {/* Question Details */}
                                <div className="flex-grow space-y-2">
                                  <div className="flex items-center gap-2">
                                    <h3 className="text-base font-semibold text-gray-900 group-hover:text-violet-600 truncate">
                                      {question.title}
                                    </h3>
                                    <span
                                      className={`text-xs font-medium px-2 py-1 rounded-full ${
                                        question.difficulty === "easy"
                                          ? "bg-green-100 text-green-800"
                                          : question.difficulty === "medium"
                                          ? "bg-yellow-100 text-yellow-800"
                                          : "bg-red-100 text-red-800"
                                      }`}
                                    >
                                      {question.difficulty}
                                    </span>
                                  </div>
                                  <div className="flex flex-wrap gap-1">
                                    {question.companies?.map(
                                      (company, index) => (
                                        <span
                                          key={index}
                                          className="text-xs px-2 py-1 bg-blue-50 text-blue-700 rounded-full border border-blue-200 hover:bg-blue-100"
                                        >
                                          {company}
                                        </span>
                                      )
                                    )}
                                  </div>
                                </div>

                                {/* Marks & Button */}
                                <div className="flex items-end gap-2">
                                  {isSelected && (
                                    <div className="flex items-center gap-2">
                                      <span className="text-sm font-medium text-gray-700">
                                        Score:
                                      </span>
                                      <input
                                        type="number"
                                        value={questionScore || 0}
                                        className="w-16 border rounded-md p-1 text-sm focus:ring-violet-500"
                                        placeholder="Marks"
                                        min="0"
                                        onChange={(e) =>
                                          handleScoreChange(
                                            question.id,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                  <button
                                    onClick={() =>
                                      handleSelectQuestion(question)
                                    }
                                    className={`py-1 px-3 text-sm font-semibold rounded-lg transition-colors ${
                                      isSelected
                                        ? "bg-red-500 hover:bg-red-600"
                                        : "bg-green-500 hover:bg-green-600"
                                    } text-white`}
                                  >
                                    {isSelected ? "Deselect" : "Select"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="text-center py-6 text-gray-500">
                          <p className="text-lg font-medium">
                            No questions found
                          </p>
                          <p className="text-sm mt-1">
                            Adjust your search or filter criteria
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Selected Questions */}
            <div>
              <h3 className="text-2xl font-semibold mb-4">
                Selected Questions
              </h3>
              <ul>
                <ul className="space-y-3 mb-3">
                  {selectedQuestions.map((question) => (
                    <li
                      key={question.id}
                      className="flex justify-between items-center p-3 border border-gray-300 rounded-lg shadow-sm bg-white hover:shadow-md hover:border-violet-300 transition-all"
                    >
                      {/* Question Details */}
                      <div className="flex-grow space-y-2">
                        <div className="flex items-center gap-2">
                          <h3 className="text-base font-semibold text-gray-900 group-hover:text-violet-600 truncate">
                            {question.title}
                          </h3>
                          <span
                            className={`text-xs font-medium px-2 py-1 rounded-full ${
                              question.difficulty === "easy"
                                ? "bg-green-100 text-green-800"
                                : question.difficulty === "medium"
                                ? "bg-yellow-100 text-yellow-800"
                                : "bg-red-100 text-red-800"
                            }`}
                          >
                            {question.difficulty}
                          </span>
                        </div>
                        <div className="flex flex-wrap gap-1">
                          {question.companies?.map((company, index) => (
                            <span
                              key={index}
                              className="text-xs px-2 py-1 bg-blue-50 text-blue-700 rounded-full border border-blue-200 hover:bg-blue-100"
                            >
                              {company}
                            </span>
                          ))}
                        </div>
                      </div>

                      {/* Marks Input */}
                      <div className="flex items-center justify-end gap-2">
                        <span className="text-sm font-medium text-gray-700">
                          Score:
                        </span>
                        <input
                          type="number"
                          value={question.score}
                          className="w-16 border rounded-md p-1 text-sm focus:ring-2 focus:ring-violet-500 text-center"
                          placeholder="+ Marks"
                          min="0"
                          onChange={(e) =>
                            handleScoreChange(question.id, e.target.value)
                          }
                        />
                        <button
                          onClick={() => handleDeselectQuestion(question)}
                          className="bg-red-500 hover:bg-red-600 text-white text-sm py-1 px-4 rounded-lg font-medium transition-all focus:ring-2 focus:ring-red-400"
                        >
                          Deselect
                        </button>
                      </div>

                      {/* Deselect Button */}
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
          </div>
        )}

        {page === 2 && (
          <button
            onClick={() => setPage(1)}
            className="bg-gray-600 text-white py-2 px-3 rounded-lg"
          >
            Back
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateContest;
