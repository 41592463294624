"use client";

import { useState, useEffect } from "react";
import { useFetchStudentMyContestsQuery } from "../../../../redux/rtkquery/contest/Contest";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { setContestActiveTab } from "../../../../redux/slices/ui/Manageui";
import Contests from "./Contests";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export function formatCountdown(timeLeft) {
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
}

export default function WeeklyContests() {
  const activeTab = useSelector(
    (state) => state.manageUISlice.contestActiveTab
  );
  const dispatch = useDispatch();

  return (
    <div className="min-h-screen w-full bg-gray-100">
      <div
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="container mx-auto p-6"
      >
        <div className="bg-gradient-to-r from-gray-600 to-gray-400 p-6 rounded-t-xl">
          {/* <div className="flex justify-between items-center mb-8 text-white tracking-tight  rounded-md"> */}
          <h1 className="text-4xl font-bold text-center text-white mb-4">
            Contests
          </h1>
          <p className="text-center text-white  max-w-2xl mx-auto">
            Stay competitive! Track your progress and compete in weekly contests
            to sharpen your abilities and climb the leaderboard
          </p>
        </div>{" "}
        <div className="w-full gap-6">
          <div className="lg:col-span-2">
            <div className="bg-white w-full rounded-lg shadow-lg p-6 mb-6">
              <div className="flex space-x-2 bg-white p-1 rounded-lg">
                {["Contests", "My Contests"].map((tab, index) => (
                  <button
                    key={index}
                    onClick={() => dispatch(setContestActiveTab(index))}
                    className={`px-4 py-2 rounded-md font-semibold transition-all duration-200 ease-in-out ${
                      activeTab === index
                        ? "bg-gray-200 text-black shadow-sm"
                        : "text-muted-foreground hover:bg-secondary hover:text-secondary-foreground"
                    }`}
                  >
                    {tab}
                  </button>
                ))}
              </div>

              {activeTab === 0 && (
                <div className="">
                  <Contests />
                </div>
              )}
              {activeTab === 1 && <MyContests />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MyContests() {
  const [myContests, setMyContests] = useState([]);
  const { data: contests, isLoading } = useFetchStudentMyContestsQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (contests) {
      setMyContests(contests?.contests);
    }
  }, [contests]);

  return (
    <div className="w-full mx-auto bg-white p-4 rounded-lg overflow-x-auto">
      <table className="w-full border-collapse min-w-[800px]">
        <thead>
          <tr className="border-b">
            <th className="text-left p-4 text-gray-600 text-sm sm:text-base">
              Contest
            </th>
            <th className="text-left p-4 text-gray-600 text-sm sm:text-base">
              Score
            </th>
            <th className="text-left p-4 text-gray-600 text-sm sm:text-base">
              Finish Time
            </th>
            <th className="text-left p-4 text-gray-600 text-sm sm:text-base">
              Solved
            </th>
            <th className="text-left p-4 text-gray-600 text-sm sm:text-base">
              Ranking
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? // Render skeletons when data is loading
              Array.from({ length: 5 }).map((_, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="p-4">
                    <Skeleton width={150} height={20} />
                  </td>
                  <td className="p-4">
                    <Skeleton width={50} height={20} />
                  </td>
                  <td className="p-4">
                    <Skeleton width={100} height={20} />
                  </td>
                  <td className="p-4">
                    <Skeleton width={70} height={20} />
                  </td>
                  <td className="p-4">
                    <Skeleton width={50} height={20} />
                  </td>
                </tr>
              ))
            : myContests.map((contest, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="p-4">
                    <div
                      onClick={() => {
                        navigate(`/contests/${contest.contestid}`);
                      }}
                    >
                      <div className="hover:text-violet-500 cursor-pointer hover:underline font-medium text-sm sm:text-base">
                        {contest.title}
                      </div>
                    </div>
                  </td>
                  <td className="p-4 text-sm sm:text-base">
                    <div className="flex items-center gap-1">
                      <span>{contest.score}</span>
                    </div>
                  </td>
                  <td className="p-4 text-sm sm:text-base">
                    {contest?.finishtime
                      ? new Date(contest?.finishtime * 1000)
                          .toISOString()
                          .substr(11, 8)
                      : "0:00:00"}
                  </td>
                  <td className="p-4 text-sm sm:text-base">
                    {contest.solvedCount}/{contest.totalProblems}
                  </td>
                  <td className="p-4 text-sm sm:text-base">{contest.rank}</td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
}
