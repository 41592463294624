import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  adminvendorinstitutionendpoints,
  adminvendorendpoints,
  studentendpoints,
} from "../../services/Apis";
import { SERVER_URL } from "../../api/env";
import createGlobalApi from "./createGlobalApi";

const { GET_STUDENTS_API } = adminvendorinstitutionendpoints;
const {
  CREATE_STUDENT_API,
  EDIT_STUDENT_API,
  EXTEND_STUDENTS_ACCESS,
  REMOVE_STUDENT_ACCESS,
  GET_STUDENTS_ACCESS,
} = adminvendorendpoints;
const { EDIT_STUDENTBYHIMSELFT_API } = studentendpoints;

export const studentsApi = createGlobalApi({
  reducerPath: "studentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  prepareHeaders: (headers, { getState }) => {
    const token = getState().userslice.token;

    console.log("Retrieved token:", token);
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },

  endpoints: (builder) => ({
    fetchStudents: builder.query({
      query: (credentials) => ({
        url: GET_STUDENTS_API,
        method: "POST",
        body: credentials,
      }),
      providesTags: ["Students"],
    }),
    extendStudentsAccess: builder.mutation({
      query: (values) => ({
        url: EXTEND_STUDENTS_ACCESS,
        method: "POST",
        body: values,
      }),
      providesTags: ["Students"],
    }),
    removeStudentsAccess: builder.mutation({
      query: (values) => ({
        url: REMOVE_STUDENT_ACCESS,
        method: "POST",
        body: values,
      }),
      providesTags: ["Students"],
    }),
    getAccessStudents: builder.query({
      query: (credentials) => ({
        url: GET_STUDENTS_ACCESS,
        method: "POST",
        body: credentials,
      }),
    }),
    createStudent: builder.mutation({
      query: (values) => ({
        url: CREATE_STUDENT_API,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Students"],
    }),
    updateStudent: builder.mutation({
      query: (values) => ({
        url: EDIT_STUDENT_API,
        method: "PUT",
        body: values,
      }),
      invalidatesTags: ["Students"],
    }),
    updateStudentByHimself: builder.mutation({
      query: (formData) => {
        // const formData = new FormData();
        console.log("values", formData);
        // Append other values

        // Object.keys(values).forEach((key) => {
        //   formData.append(key, values[key]);
        // });

        // Append the image
        // if (image) {
        //   formData.append("file", image);
        // }

        return {
          url: EDIT_STUDENTBYHIMSELFT_API,
          method: "PUT",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useFetchStudentsQuery,
  useCreateStudentMutation,
  useUpdateStudentMutation,
  useLazyFetchStudentsQuery,
  useExtendStudentsAccessMutation,
  useRemoveStudentsAccessMutation,
  useGetAccessStudentsQuery,
  useUpdateStudentByHimselfMutation,
} = studentsApi;
