// components/CreateSubcategoryForm.jsx
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import MyButton from "../../components/common/MyButton";
import {
  useCreateAssignmentSubCategoryMutation,
  useEditAssignmentSubCategoryMutation,
  useFetchIndividualAssignmentCategoriesQuery,
} from "../../redux/rtkquery/assignment/AssignmentCategory";
const CreateAssignmentSubcategory = ({ initialData, onClose, isEdit }) => {
  const [createSubCategory] = useCreateAssignmentSubCategoryMutation();
  const [editSubCategory] = useEditAssignmentSubCategoryMutation();
  const { data: categories, isFetching } =
    useFetchIndividualAssignmentCategoriesQuery(
      {
        type: "create",
      },
      {
        suspense: true,
      }
    );

  console.log("intitial ", initialData);

  const [subcategoryName, setSubcategoryName] = useState(
    isEdit ? initialData.name : ""
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // useEffect(() => {
  //   dispatch(FetchCategories());
  // }, [onClose]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let response;
      if (isEdit) {
        response = await editSubCategory({
          id: initialData.id,
          name: subcategoryName,
          assignmentcategoryid: categoryId,
        }).unwrap();
      } else {
        response = await createSubCategory({
          name: subcategoryName,
          assignmentcategoryid: categoryId,
        }).unwrap();
      }
      console.log("Res", response);

      if (response.success) {
        toast.success(
          `Subcategory ${isEdit ? "Updated" : "Created"} successfully`
        );
        onClose(false);
      } else {
        toast("🤐 " + response.message);
      }
    } catch (error) {
      toast.error("Some error occurred. Please try again");

      console.error("Error creating subcategory:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const [categoryId, setCategoryId] = useState(
    isEdit ? initialData.assignmentcategoryid : ""
  );

  return (
    <form className="space-y-4 relative w-full p-4 max-w-md mx-auto bg-white rounded shadow-md">
      <h1 className="font-bold text-2xl text-center">
        {isEdit ? "Update" : "Created"} Sub Category
      </h1>
      <div>
        <label
          htmlFor="category"
          className="block text-gray-700 font-semibold mb-2"
        >
          Category:
        </label>
        <select
          id="category"
          value={categoryId}
          onChange={(e) => setCategoryId(e.target.value)}
          required
          className="w-full p-2 border border-gray-300 rounded"
        >
          {isFetching ? (
            <option value="">Loading...</option> // Show "Loading..." during fetch
          ) : (
            <option value="" disabled>
              Select a category
            </option>
          )}
          {!isFetching &&
            categories?.assignmentcategories?.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
        </select>
      </div>
      <div>
        <label
          htmlFor="subcategory"
          className="block text-gray-700 font-semibold mb-2"
        >
          Subcategory Name:
        </label>
        <input
          type="text"
          id="subcategory"
          value={subcategoryName}
          onChange={(e) => setSubcategoryName(e.target.value)}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <button
        onClick={onClose}
        className="absolute text-4xl -top-1 right-0 mt-2 mr-2  text-gray-600"
      >
        &times;
      </button>
      <div className="flex gap-2 justify-center">
        <MyButton
          onClick={async (e) => {
            await handleFormSubmit(e);
          }}
          className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
        >
          {isEdit ? "Update" : "Create"}
        </MyButton>
      </div>
      {error && <p className="text-red-500">{error}</p>}
    </form>
  );
};

export default CreateAssignmentSubcategory;
