// services/assessmentsApi.js
import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { studentassessmentendpoints } from "../../../services/Apis";
import { SERVER_URL } from "../../../api/env";
import createGlobalApi from "../createGlobalApi";

const {
  GET_STUDENTASSESSMENTS,
  GET_ASSESSMENTDETAILS,
  CREATE_ASSESSMENT_ATTEMPT,
  UPDATE_ASSESSMENT_ANSWERS,
  GET_ASSESSMENT_ANSWERS,
  UPDATE_ASSESSMENT_STATUS,
  GET_ASSESSMENTATTEMPT_SOLUTIONS,
} = studentassessmentendpoints;
export const studentAssessmentsApi = createGlobalApi({
  reducerPath: "studentAssessmentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    getStudentAssessments: builder.query({
      query: () => GET_STUDENTASSESSMENTS,
      providesTags: ["StudentAssessments"],
    }),
    getAssessmentDetails: builder.query({
      query: (credentials) => ({
        url: GET_ASSESSMENTDETAILS,
        method: "POST",
        body: credentials,
      }),
    }),
    createAssessmentAttempt: builder.mutation({
      query: (credentials) => ({
        url: CREATE_ASSESSMENT_ATTEMPT,
        method: "POST",
        body: credentials,
      }),
    }),
    updateAssessmentAttemptAnswers: builder.mutation({
      query: (credentials) => ({
        url: UPDATE_ASSESSMENT_ANSWERS,
        method: "PUT",
        body: credentials,
      }),
    }),
    updateAssessmentStatus: builder.mutation({
      query: (credentials) => ({
        url: UPDATE_ASSESSMENT_STATUS,
        method: "PUT",
        body: credentials,
      }),
      invalidatesTags: ["StudentAssessments"],
    }),
    getAssessmentAttemptAnswers: builder.query({
      query: (credentials) => ({
        url: GET_ASSESSMENT_ANSWERS,
        method: "POST",
        body: credentials,
      }),
    }),
    getAttemptAnswersSolutions: builder.query({
      query: (formData) => ({
        url: GET_ASSESSMENTATTEMPT_SOLUTIONS,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useGetStudentAssessmentsQuery,
  useGetAssessmentDetailsQuery,
  useCreateAssessmentAttemptMutation,
  useUpdateAssessmentAttemptAnswersMutation,
  useGetAssessmentAttemptAnswersQuery,
  useUpdateAssessmentStatusMutation,
  useGetAttemptAnswersSolutionsQuery,
} = studentAssessmentsApi;
