// src/services/batchApi.js
import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  adminvendorinstitutionendpoints,
  adminvendorendpoints,
} from "../../services/Apis";
import { SERVER_URL } from "../../api/env";
import createGlobalApi from "./createGlobalApi";

const { CREATE_BATCH_API, EDIT_BATCH_API } = adminvendorendpoints;
const { GET_BATCHES_API } = adminvendorinstitutionendpoints;
export const batchApi = createGlobalApi({
  reducerPath: "batchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }), // Adjust the base URL as needed
  tagTypes: ["Batch"], // Define tag types for caching and invalidation
  endpoints: (builder) => ({
    fetchBatches: builder.query({
      query: (credentials) => ({
        url: GET_BATCHES_API,
        method: "POST",
        body: credentials,
      }),
      providesTags: ["Batch"], // Tags to associate with this endpoint
    }),
    createBatch: builder.mutation({
      query: (formData) => ({
        url: CREATE_BATCH_API,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Batch"], // Invalidate the 'Batch' tag to refetch data
    }),
    editBatch: builder.mutation({
      query: (formData) => ({
        url: EDIT_BATCH_API,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Batch"], // Invalidate the 'Batch' tag to refetch data
    }),
  }),
});

export const {
  useFetchBatchesQuery,
  useCreateBatchMutation,
  useEditBatchMutation,
} = batchApi;
