import {  fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "../../api/env";
import { dashboardEndpoints } from "../../services/Apis";
import createGlobalApi from "./createGlobalApi";

export const dashboardApi = createGlobalApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    fetchMyDetails: builder.query({
      query: () => dashboardEndpoints.MY_DETAILS,
      // providesTags: ["Coding"],
    }),
    getDashboardStats: builder.query({
      query: () => dashboardEndpoints.getDashboardStats,
      // providesTags: [
      //   "Coding",
      //   "FillInTheBlank",
      //   "dsa",
      //   "MCQ",
      //   "Assessments",
      //   "Assignments",
      //   "AdminInstitutions",
      //   "AllVendorInstitutions",
      //   "VendorInstitutions",
      //   "Vendors",
      //   "Students",
      // ],
    }),
  }),
});

export const { useFetchMyDetailsQuery, useGetDashboardStatsQuery } =
  dashboardApi;
