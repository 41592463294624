import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/Axios";

const initialState = {
  individualcategories: [],
  allcategories: [],
  loading: true,
  error: "",
};
export const FetchIndividualCategories = createAsyncThunk(
  "/assignmentscategories/FetchIndividualCategories",
  async (credentials) => {
    const response = await api.get(
      "/assignmentcategory/getAssignmentCategories",
      credentials
    );
    console.log("categories", response.data.assignmentcategories);
    return response.data.assignmentcategories;
  }
);
export const FetchAllCategories = createAsyncThunk(
  "/assignmentscategories/FetchAllCategories",
  async (credentials) => {
    const response = await api.get(
      "/assignmentcategory/getAllAssignmentCategories",
      credentials
    );
    console.log("categories", response.data.allcategories);
    return response.data.allcategories;
  }
);
const AssignmentCategorySlice = createSlice({
  name: "assignmentcategory",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(FetchIndividualCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchIndividualCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.individualcategories = action.payload;
      })
      .addCase(FetchIndividualCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(FetchAllCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchAllCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.allcategories = action.payload;
      })
      .addCase(FetchAllCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default AssignmentCategorySlice.reducer;
